import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  AppBar,
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import "../CourseCommonStyle.scss";
import { GET, POST } from "../../../config/Api";
import { Constants } from "../../../config/Constants";
import { useDispatch, useSelector } from "react-redux";
import { cloudfrontUrl } from "../../../config/BaseUrl";
import { isValidUrl } from "../../Validators";
import { ToastNotify } from "../../SnackBar/ToastNotify";
import { rerenderBlocksAction } from "../../../redux/action";
import CertificationModal from "./CertificationModal";
import { getDimension } from "../../../utils";
import FullScreenModal from "./FullScreenModal";
import CertificateTemplate from "../../CertificateConfig/CertificatePreview/CertificateTemplate";
import defaultCertBg from "../../../assets/DefaultCertificate.png";
import QuillEditor from "../../QuillEditor/QuillEditor";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const matchId = (arr, id) => {
  let matchItem = arr?.find((item) => item?._id === id);
  if (matchItem) {
    return matchItem._id;
  }
};

const NewCourseModal = ({ modalOpen, modalClose, data }) => {
  let dispatch = useDispatch();

  let UserData = useSelector((state) => state.userLogin.data);

  const toastRef = useRef();
  const [courseData, setcourseData] = useState({
    title: data?.title || "",
    description: data?.tagline || "",
    core: data?.tags?.core || "",
    sel: data?.tags?.sel || "",
    sdg: data?.tags?.sdg || "",
    terms: data?.terms || "",
    termsArr: data?.termsArr || [],
    privacy: data?.privacy || "private",
    image: "",
    certification: data?.certification || false,
    certificateConfig: data?.certificate_config || "",
    signatoryConfig: data?.signatory_config || "",
    isPaid: data?.isPaid || false,
    price: data?.price || "",
    cohortVisibility: data?.cohortVisibility || "both",
    discussionForum: false,
    learnerDashboard: false,
  });
  const [courseDataError, setcourseDataError] = useState({
    title: "",
    terms: "",
    price: "",
    courseLang: "",
  });
  const [uploadImage, setUploadImage] = useState();
  const [preview, setPreview] = useState("");
  const [terms, setTerms] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [openCertification, setOpenCertification] = useState(false);
  const [certificates, setCertificates] = useState([]);
  const [signatories, setSignatories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [courseLang, setCourseLang] = useState({});
  const [languages, setLanguages] = useState([{}]);
  const [certmodalOpen, setcertModalOpen] = useState(false);
  const [certData, setCertData] = useState({});
  const [signData, setSignData] = useState({});

  let navigate = useNavigate();

  useEffect(() => {
    modalOpen &&
      (async () => {
        //get all certificate configurations
        GET(`${Constants.getAllCertificate}`)
          .then((res) => {
            setCertificates(res?.data?.certificates);
            setSignatories(res?.data?.signatories);
            setcourseData({
              ...courseData,
              certificateConfig: matchId(res?.data?.certificates, data?.certificate_config),
              signatoryConfig: matchId(res?.data?.signatories, data?.signatory_config),
            });
          })
          .catch((err) => console.log(err));
      })();
  }, [modalOpen]);

  useEffect(() => {
    if (modalOpen) {
      //get terms
      GET(Constants.course + "/terms").then((res) => {
        if (res.data.success) {
          setTerms(res.data.allTerms);
          if (data?.image) {
            setIsEdit(true);
            isValidUrl(data.image.l)
              ? setUploadImage(data.image.l)
              : setUploadImage(cloudfrontUrl + "/" + data.image.l);
          }
          if (data) {
            let selectedTerms = res.data.allTerms
              .filter((el) => data.termsArr.includes(el._id))
              .map((el) => el?.title);

            setcourseData((prev) => ({
              ...prev,
              title: data?.title || "",
              description: data?.tagline || "",
              core: data?.tags?.core || "",
              sel: data?.tags?.sel || "",
              sdg: data?.tags?.sdg || "",
              // terms: selectedTerms?.title || "",
              termsArr: selectedTerms || [],
              privacy: data?.privacy || "private",
              isPaid: data?.isPaid || false,
              learnerDashboard: data?.learnerDashboard || false,
              discussionForum: data?.discussionForum || false,
              image: "",
              certification: data?.certification || false,
              certificateConfig: data?.certificate_config || "",
              signatoryConfig: data?.signatory_config || "",
              cohortVisibility: data?.cohortVisibility || "both",
            }));
          }
        }
      });

      (async () => {
        try {
          //get course languages
          const langsRes = await GET(`${Constants.course}/languages/courseLanguage`);
          if (langsRes?.data?.success) {
            const lang = langsRes?.data?.languages?.find((el) => el._id === data?.courseLanguage);
            setCourseLang(lang);
            setLanguages(langsRes?.data?.languages);
          }
        } catch (error) {
          console.log(error);
        }
      })();
      //get course pricing
      data?._id &&
        GET(`${Constants.course}/pricing/${data?._id}`)
          .then((res) => {
            setcourseData((prev) => ({
              ...prev,
              price: res?.data?.priceInfo?.price,
            }));
          })
          .catch((err) => console.log(err));
    }
    return () => {
      setcourseData({
        title: "",
        description: "",
        core: "",
        sel: "",
        sdg: "",
        terms: "",
        termsArr: [],
        privacy: "private",
        image: "",
        certificateConfig: "",
        signatoryConfig: "",
        isPaid: false,
        price: "",
        discussionForum: false,
        learnerDashboard: false,
        cohortVisibility: "",
      });
      setcourseDataError("");
      setPreview("");
      setUploadImage(undefined);
    };
  }, [modalOpen]);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setcourseDataError((prevState) => ({
      ...prevState,
      [name]: "",
    }));

    if (name === "terms") {
      setcourseData((prevState) => {
        prevState.termsArr = typeof value === "string" ? value.split(",") : value;
        return prevState;
      });
    } else {
      setcourseData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  }, []);

  useEffect(() => {
    if (!uploadImage) {
      setPreview(undefined);
      return;
    }
    let objectUrl;
    if (isEdit) {
      setPreview(uploadImage);
    } else {
      objectUrl = URL.createObjectURL(uploadImage);
      setPreview(objectUrl);
    }

    return () => URL.revokeObjectURL(objectUrl);
  }, [uploadImage]);

  const onSelectFile = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const { height, width } = await getDimension(e);
      const ratio = width / height;

      if (ratio !== 1) {
        toastRef.current.showToastMessage("Image's height and width should be the same", "error");
        return;
      }
    }
    if (!e.target.files || e.target.files.length === 0) {
      setUploadImage(undefined);
      return;
    }
    setcourseData({ ...courseData, image: e.target.files[0] });
    setIsEdit(false);
    setUploadImage(e.target.files[0]);
  };

  const handleNewCourse = (e) => {
    e.preventDefault();
    if (courseData.title === "") {
      setcourseDataError({ ...courseDataError, title: "Please enter a title" });
    } else if (courseData.isPaid && !courseData.price) {
      setcourseDataError({ ...courseDataError, price: "Course price cannot be empty or 0." });
    } else if (courseData.termsArr.length === 0) {
      setcourseDataError({ ...courseDataError, terms: "Please select terms" });
    } else if (!courseLang?._id) {
      setcourseDataError({ ...courseDataError, courseLang: "Please select course language" });
    } else {
      // let selectedTerms = terms.find((item) => item.title === courseData.terms);
      let selectedTerms = terms
        .filter((el) => courseData.termsArr.includes(el.title))
        .map((el) => el?._id);
      let formData = new FormData();
      formData.append("image", courseData.image);
      formData.append("title", courseData.title);
      formData.append("tagline", courseData.description);
      formData.append("core", courseData.core);
      formData.append("sel", courseData.sel);
      formData.append("sdg", courseData.sdg);
      formData.append("terms", selectedTerms._id);
      formData.append("termsArr", selectedTerms);
      formData.append("privacy", courseData.privacy);
      formData.append("userId", UserData.user.id);
      formData.append("isPaid", courseData.isPaid);
      formData.append("price", courseData.price);
      formData.append("certification", courseData.certification || false);
      formData.append("certificateConfig", courseData.certificateConfig || "");
      formData.append("signatoryConfig", courseData.signatoryConfig || "");
      formData.append("cohortVisibility", courseData.cohortVisibility || "both");
      formData.append("discussionForum", courseData.discussionForum || false);
      formData.append("learnerDashboard", courseData.learnerDashboard || false);
      formData.append("courseLanguage", courseLang?._id || "");
      setLoading(true);
      //edit course
      POST(
        data ? `${Constants.course}/manage/editCourse/${data._id}` : Constants.createCourse,
        formData,
        "multipart/form-data",
      )
        .then((res) => {
          if (res?.data?.success) {
            toastRef.current.showToastMessage(res.data.message, "success");
            dispatch(rerenderBlocksAction(true));
          } else {
            toastRef.current.showToastMessage("Unable to modify course", "error");
          }
          setUploadImage(undefined);
          setLoading(false);
          // setTimeout(() => {
          modalClose();
          // }, 500);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  const fetchData = async () => {
    // Fetch initial data for certificate and signatory if they are already selected
    if (courseData.certificateConfig) {
      const certData = await getConfiguration(courseData.certificateConfig);
      setCertData(certData);
    }
    if (courseData.signatoryConfig) {
      const signData = await getConfiguration(courseData.signatoryConfig);
      setSignData(signData);
    }
  };

  useEffect(() => {
    fetchData();
  }, [courseData.certificateConfig, courseData.signatoryConfig]);

  const getConfiguration = async (configId) => {
    try {
      //get getCertificateConfig by config id
      let response = await POST(`${Constants.getCertificateConfig}/${configId}`);
      return response?.data?.configuration;
    } catch (error) {
      navigate("/404");
    }
  };

  const handleCertificateChange = async (e) => {
    setcourseData({ ...courseData, certificateConfig: e.target.value });
    const data = await getConfiguration(e.target.value);
    setCertData(data);
  };
  const handleSignatoryChange = async (e) => {
    if (e.target.value === "none") {
      setcourseData({ ...courseData, signatoryConfig: "" });
      setSignData({});
    } else {
      setcourseData({ ...courseData, signatoryConfig: e.target.value });
      const data = await getConfiguration(e.target.value);
      setSignData(data);
    }
  };

  return (
    <>
      <Dialog fullScreen open={modalOpen} onClose={modalClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: "sticky", top: 0, bgcolor: "#111333" }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {data ? (
                <>
                  <IconButton color="inherit">
                    <ModeEditOutlineOutlinedIcon />
                  </IconButton>
                  Edit course
                </>
              ) : (
                "Create new course"
              )}
            </Typography>

            <Box
              sx={{
                "&:hover": {
                  bgcolor: "warning.main",
                },
              }}
            >
              <Button
                autoFocus
                color="inherit"
                edge="start"
                variant="outlined"
                onClick={modalClose}
                aria-label="close"
              >
                <CloseIcon />
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
        <Box p={5}>
          <form onSubmit={handleNewCourse}>
            <Grid container spacing={3}>
              <Grid container item xs={12} lg={9} md={8} order={{ xs: 2, lg: 1 }}>
                <Grid item lg={12} xs={12} mb={3}>
                  <TextField
                    variant="outlined"
                    name="title"
                    fullWidth
                    type="text"
                    label="Course Name*"
                    placeholder="Course Name*"
                    value={courseData.title}
                    onChange={handleChange}
                  />
                  {courseDataError.title && (
                    <Typography variant="body2" color={"error"} component={"span"}>
                      {courseDataError.title}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={12} xs={12} mb={3}>
                  <QuillEditor
                    placeholder="Course Description*"
                    value={courseData.description}
                    handleChange={handleChange}
                    height={250}
                  />{" "}
                  <br />
                  {/* 
                  <TinyEditor
                    value={courseData.description}
                    placeholder="Course Description*"
                    handleChange={handleChange}
                    height={250}
                  /> */}
                  {courseDataError.description && (
                    <Typography variant="body2" color={"error"} component={"span"}>
                      {courseDataError.description}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid item lg={3} md={4} xs={12}>
                <Box width={"100%"} height={"100%"} pb={3}>
                  <label
                    htmlFor="imgInp"
                    style={{
                      border: "2px dashed #111333",
                      borderRadius: "8px",
                      display: "flex",
                      padding: "20px",
                      height: "100%",
                      width: "100%",
                      aspectRatio: 1,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <input
                      accept="image/*"
                      type="file"
                      id="imgInp"
                      style={{ display: "none" }}
                      onChange={onSelectFile}
                    />
                    {preview || uploadImage ? (
                      <Box>
                        <img
                          src={preview || uploadImage}
                          style={{ width: "100%", height: "auto", objectFit: "contain" }}
                          alt="course thumbnail"
                        />
                      </Box>
                    ) : (
                      <Box textAlign={"center"}>
                        <p style={{ fontSize: "14px" }}>Choose course cover image </p>
                        <p>(800px X 800px or a square image)</p>
                      </Box>
                    )}
                  </label>
                </Box>
              </Grid>
            </Grid>
            <Divider>Privacy, Cohort Visibility & Audience</Divider>
            <Grid container my={2} spacing={2}>
              <Grid lg={4} sm={4} xs={12} item>
                <FormControl fullWidth>
                  <InputLabel id="fs-privacy-label">Privacy</InputLabel>
                  <Select
                    labelId="fs-privacy-label"
                    id="fs-privacy"
                    name="privacy"
                    label="Privacy"
                    value={courseData.privacy}
                    onChange={handleChange}
                  >
                    <MenuItem value={"private"}>Private</MenuItem>
                    {UserData?.user?.type === "admin" && (
                      <MenuItem value={"public"}>Public</MenuItem>
                    )}
                  </Select>
                </FormControl>
                {courseDataError.privacy && (
                  <Typography variant="body2" color={"error"} component={"span"}>
                    {courseDataError.privacy}
                  </Typography>
                )}
              </Grid>
              {UserData?.user?.type === "admin" && (
                <Grid lg={4} sm={4} xs={12} item>
                  <>
                    <FormControl fullWidth>
                      <InputLabel id="cohort-visibilty-label">Cohort Visibility</InputLabel>
                      <Select
                        labelId="cohort-visibilty-label"
                        id="cohort-visibilty"
                        label="Cohort Visibility"
                        name="cohortVisibility"
                        value={courseData.cohortVisibility}
                        onChange={handleChange}
                      >
                        <MenuItem value="selfDirected">Self Directed</MenuItem>
                        <MenuItem value="facilitated">Facilitated</MenuItem>
                        <MenuItem value="both">Both</MenuItem>
                      </Select>
                    </FormControl>
                    {courseDataError.cohortVisibility && (
                      <Typography variant="body2" color={"error"} component={"span"}>
                        {courseDataError.cohortVisibility}
                      </Typography>
                    )}
                  </>
                </Grid>
              )}
              <Grid item lg={4} sm={4} xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="select-audience-label">Select Audience</InputLabel>
                  <Select
                    labelId="select-audience-label"
                    id="select-audience"
                    label="Select Audience"
                    onChange={handleChange}
                    multiple
                    name="terms"
                    value={courseData.termsArr}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected?.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                  >
                    {terms.length > 0 &&
                      terms.map((item, i) => {
                        return (
                          <MenuItem value={item.title} key={i}>
                            {item.title}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                {courseDataError.terms && (
                  <Typography variant="body2" color={"error"} component={"span"}>
                    {courseDataError.terms}
                  </Typography>
                )}
              </Grid>
              <Grid item lg={4} sm={4} xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  fullWidth
                  options={languages}
                  getOptionLabel={(option) => option.name + " " + option.nativeName}
                  value={courseLang}
                  onChange={(e, newValue) => {
                    setCourseLang(newValue);
                    setcourseDataError((prevState) => ({
                      ...prevState,
                      courseLang: "",
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="course_language"
                      type="text"
                      label="Course Language"
                    />
                  )}
                />
                {courseDataError.courseLang && (
                  <Typography variant="body2" color={"error"} component={"span"}>
                    {courseDataError.courseLang}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Divider textAlign="center">Keywords</Divider>
            <Grid mb={3} mt={1} container spacing={2}>
              <Grid item lg={4} md={4} xs={12}>
                <TextField
                  variant="outlined"
                  name="core"
                  fullWidth
                  type="text"
                  label="Core*"
                  placeholder="Core subjects: Physics, Maths, Civics, ...*"
                  value={courseData.core}
                  onChange={handleChange}
                  errorMsg={courseDataError.core}
                />
              </Grid>
              <Grid item lg={4} md={4} xs={12}>
                <TextField
                  variant="outlined"
                  name="sel"
                  fullWidth
                  type="text"
                  label="SEL components*"
                  placeholder="SEL components: Empathy, Compassion, ..."
                  value={courseData.sel}
                  onChange={handleChange}
                  errorMsg={courseDataError.sel}
                />
              </Grid>
              <Grid item lg={4} md={4} xs={12}>
                <TextField
                  variant="outlined"
                  name="sdg"
                  fullWidth
                  type="text"
                  label="Target SDGs*"
                  placeholder="Target SDGs: Gender Equality, Climate Change, ..."
                  value={courseData.sdg}
                  onChange={handleChange}
                  errorMsg={courseDataError.sdg}
                />
              </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={3} my={2}>
              <Grid item lg={3} xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      label="Show Discussion Forum"
                      value={courseData.discussionForum}
                      checked={courseData.discussionForum}
                      onChange={() =>
                        setcourseData({
                          ...courseData,
                          discussionForum: !courseData.discussionForum,
                        })
                      }
                    />
                  }
                  label="Show Discussion Forum"
                />
              </Grid>
              <Grid item lg={3} xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      label="Enable Learner Dashboard"
                      value={courseData.learnerDashboard}
                      checked={courseData.learnerDashboard}
                      onChange={() =>
                        setcourseData({
                          ...courseData,
                          learnerDashboard: !courseData.learnerDashboard,
                        })
                      }
                    />
                  }
                  label="Enable Learner Dashboard"
                />
              </Grid>
              <Grid item lg={3} xs={12}>
                {UserData?.user?.type === "admin" && (
                  <>
                    <FormControlLabel
                      control={
                        <Switch
                          label="Enable Certification"
                          value={courseData.certification}
                          checked={courseData.certification}
                          onChange={() =>
                            setcourseData((prevState) => ({
                              ...prevState,
                              certification: !prevState.certification,
                              certificateConfig: !prevState.certification
                                ? prevState.certificateConfig
                                : "",
                              signatoryConfig: !prevState.certification
                                ? prevState.signatoryConfig
                                : "",
                            }))
                          }
                        />
                      }
                      label="Enable Certification"
                    />

                    {courseData.certification && (
                      <>
                        <Box my={2}>
                          <FormControl fullWidth>
                            <InputLabel id="certification-label">Primary Configuration</InputLabel>
                            <Select
                              labelId="certification-label"
                              id="certification"
                              name="certificate"
                              label="Primary Configuration"
                              value={courseData.certificateConfig}
                              onChange={handleCertificateChange}
                              renderValue={(selected) => {
                                if (!selected) {
                                  return (
                                    <span className="dropdownLabel">
                                      Select Certificate Configuration
                                    </span>
                                  );
                                } else {
                                  let selectedItem = certificates.find(
                                    (item) => item?._id === selected,
                                  );

                                  return <span>{selectedItem?.configuration_title}</span>;
                                }
                              }}
                            >
                              {certificates?.length > 0 &&
                                certificates?.map((item, i) => {
                                  return (
                                    <MenuItem value={item._id} key={item?._id}>
                                      {item?.configuration_title}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        </Box>
                        <Box my={2}>
                          <FormControl fullWidth>
                            <InputLabel id="signatory-label">Secondary Configuration</InputLabel>
                            <Select
                              labelId="signatory-label"
                              id="signatory"
                              label="Secondary Configuration"
                              name="signatories"
                              value={courseData.signatoryConfig}
                              onChange={handleSignatoryChange}
                              disabled={!courseData.certificateConfig}
                              renderValue={(selected) => {
                                if (!selected) {
                                  return (
                                    <span className="dropdownLabel">
                                      Select Additional Signatory Configuration
                                    </span>
                                  );
                                } else {
                                  let selectedItem = signatories.find(
                                    (item) => item?._id === selected,
                                  );

                                  return <span>{selectedItem?.configuration_title}</span>;
                                }
                              }}
                            >
                              <MenuItem value="none">None</MenuItem>
                              {signatories?.length > 0 &&
                                signatories?.map((item, i) => {
                                  return (
                                    <MenuItem value={item._id} key={item?._id}>
                                      {item?.configuration_title}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        </Box>
                      </>
                    )}
                  </>
                )}
                {/* {(UserData?.user?.type === "admin" || UserData?.user?.type === "moderator") && (
                  <> */}

                {courseData.certificateConfig && (
                  <>
                    <Typography
                      onClick={() => {
                        setcertModalOpen(true);
                      }}
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        textDecoration: "underline",
                      }}
                    >
                      Preview Certificate
                    </Typography>

                    <FullScreenModal
                      open={certmodalOpen}
                      setOpen={() => {
                        setcertModalOpen(false);
                      }}
                      dialogTitle={"Certificate Preview "}
                    >
                      <Grid
                        item
                        className="fullwidth"
                        xs={12}
                        justifyContent={"center"}
                        display="flex"
                        mt={4}
                      >
                        {Object.keys(certData).length ? (
                          <CertificateTemplate certData={certData} signData={signData} />
                        ) : (
                          <img src={defaultCertBg} alt="certBg" width="100%" />
                        )}
                      </Grid>
                    </FullScreenModal>
                  </>
                )}
                {/* </>
                )} */}
              </Grid>
              {/* <Divider orientation="vertical" variant="middle" /> */}
              <Grid item lg={3} xs={12}>
                {UserData?.user?.type === "admin" && (
                  <>
                    <FormControlLabel
                      control={
                        <Switch
                          label="Make this a paid course"
                          value={courseData.isPaid}
                          checked={courseData.isPaid}
                          onChange={() =>
                            setcourseData({
                              ...courseData,
                              isPaid: !courseData.isPaid,
                              price: "",
                            })
                          }
                        />
                      }
                      label="Make this a paid course"
                    />
                    {courseData.isPaid && (
                      <Box position={"relative"}>
                        <TextField
                          variant="outlined"
                          name="price"
                          fullWidth
                          type="text"
                          label="Price in USD*"
                          placeholder="Price in USD"
                          value={courseData.price}
                          sideLabel={"Price in USD"}
                          onChange={(e) => {
                            const isNumber = /^(?:0|\d+(\.\d+)?)?$/.test(e.target.value);
                            if (isNumber) {
                              handleChange(e);
                            }
                          }}
                          errorMsg={courseDataError.price}
                        />
                        {courseDataError.price && (
                          <Typography variant="body2" color={"error"} component={"span"}>
                            {courseDataError.price}
                          </Typography>
                        )}
                      </Box>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </form>

          <Button
            variant="contained"
            color="primary"
            className="save_cohort_button"
            disabled={loading}
            onClick={handleNewCourse}
          >
            Save
          </Button>
        </Box>

        <ToastNotify ref={toastRef} duration={4000} />
      </Dialog>
      <CertificationModal
        modalOpen={openCertification}
        setOpenCertification={setOpenCertification}
      />
    </>
  );
};

export default NewCourseModal;
