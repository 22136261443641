import React, { useRef, useState } from "react";
import FMModal from "../../ModalMain/CourseModals/FMModal";
import { Box, Tooltip, Typography, Zoom } from "@mui/material";
import ButtonMain from "../../ButtonMain";
import { InputBox } from "../../InputBox/InputBox";
import { GET } from "../../../config/Api";
import { useOutletContext } from "react-router-dom";
import PreviewCertificateModal from "./PreviewCertificateModal";
import { Constants } from "../../../config/Constants";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import "./CourseModal.scss";
import { useSelector } from "react-redux";
import { maskEmail } from "../../../utils";

const CheckCertificateNameModal = ({ generateCertModal, setGenerateCertModal }) => {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [certOpen, setCertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const { courseOne, completedModulesData } = useOutletContext();
  let UserData = useSelector((state) => state.userLogin);

  const englishAsciiRegex = /^[A-Za-z\s]*$/;
  const textToCopyRef = useRef(null);

  const checkCertificateName = async () => {
    if (!name.trimStart()) {
      setNameError("Please enter name");
    } else {
      try {
        setLoading(true);
        //comapre name on certificate
        const response = await GET(
          `${Constants.compareNameOnCertificate}?course_id=${courseOne?._id}&cohort_id=${
            completedModulesData.cohort_id
          }&name=${name.trim()}`,
        );
        if (response.data.success) {
          setGenerateCertModal(false);
          setCertOpen(true);
          setLoading(false);
          setShowInfo(false);
        }
      } catch (error) {
        setNameError(error?.response?.data?.message);
        setShowInfo(true);
        setLoading(false);
      }
    }
  };
  const handleCopy = async () => {
    try {
      const textToCopy = textToCopyRef.current.innerText;
      await navigator.clipboard.writeText(textToCopy);

      setIsCopied(true);

      // Reset the copied state after a certain duration
      setTimeout(() => {
        setIsCopied(false);
      }, 500);
    } catch (error) {
      // Handle error, e.g., show an error message
      console.error("Failed to copy text to clipboard", error);
    }
  };
  return (
    <>
      <FMModal
        modalOpen={generateCertModal}
        modalClose={() => {
          setGenerateCertModal(false);
          setNameError("");
          setName("");
          setShowInfo(false);
        }}
        modalHeader={"Re-generate Certificate"}
        className="confirm_modal"
        headerTextColor={"header_text_black"}
        backgroundImage={{
          backgroundImage: "linear-gradient(#a1c4fd, #c2e9fb)",
        }}
      >
        <InputBox
          placeholder={"Name (Use English / Latin Script) *"}
          value={name}
          onChange={(e) => {
            setName(
              englishAsciiRegex.test(e.target.value)
                ? e.target.value
                : e.target.value.replace(/[^A-Za-z\s]/g, ""),
            );
            setNameError("");
          }}
          errorMsg={nameError}
        />
        {completedModulesData?.certificateEmail ? (
          <Box>
            <Typography variant="body2" mt={1}>
              Dear user, we have shared the certificate on the email id containing these characters.
            </Typography>
            <Typography variant="body2" mt={1} fontWeight={600}>
              {maskEmail(completedModulesData?.certificateEmail)}
            </Typography>
            <Typography variant="body2" mt={1}>
              Kindly check this email to download your certificate. The email should have subject
              line “Framerspace: Download Certificate”. The email will also have the exact name that
              you entered for your certificate for the first time. Please also check spam/junk
              folders of your email in case you don’t find the certificate email.
            </Typography>
          </Box>
        ) : (
          <>
            {showInfo && (
              <Box ml={0.7}>
                <Typography variant="body2" mt={1}>
                  If you forgot the spelling of your name that you entered first time to generate
                  the certificate, then kindly contact our{" "}
                  <a style={{ lineHeight: 1.43 }} href="/support" target="_blank">
                    support team
                  </a>{" "}
                  by copying the below User id and the web link of the course. They will help you,
                  this process will take time.
                </Typography>
                <Box mt={2} mb={2} sx={{ display: "flex", alignItems: "center" }}>
                  <Box ref={textToCopyRef}>
                    <Box sx={{ lineHeight: 1.43, fontSize: "0.875rem" }}>
                      User Id: {UserData?.data?.user?.email}
                    </Box>
                    <Box sx={{ lineHeight: 1.43, fontSize: "0.875rem" }}>
                      Course link: {window?.location?.href}
                    </Box>
                  </Box>
                  <Box>
                    <Tooltip
                      TransitionComponent={Zoom}
                      title={`${isCopied ? "Text Copied" : "Copy Text"}`}
                    >
                      <ContentCopyIcon sx={{ cursor: "pointer" }} onClick={handleCopy} />
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
            )}
          </>
        )}
        <Box className="regenerate-button-container">
          <ButtonMain
            className="regenerate-button"
            onClick={checkCertificateName}
            disabled={loading}
          >
            Confirm
          </ButtonMain>
          <ButtonMain
            className="cancel-button"
            onClick={() => {
              setName("");
              setNameError("");
              setGenerateCertModal(false);
              setShowInfo(false);
            }}
          >
            Cancel
          </ButtonMain>
        </Box>
      </FMModal>
      <PreviewCertificateModal
        certOpen={certOpen}
        setCertOpen={setCertOpen}
        name={name}
        setName={setName}
      />
    </>
  );
};

export default CheckCertificateNameModal;
