import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import "../InputBox/InputBox.scss";
import { cloudfrontUrl } from "../../config/BaseUrl";

const UploadImageBox = ({ title, icon, className, onChange, accept, ref, initialImage }) => {
  const [imagesrc, setImageSrc] = useState();

  useEffect(() => {
    setImageSrc(initialImage ? `${cloudfrontUrl}${initialImage}` : null);
  }, [initialImage]);

  return (
    <div className="inputFile">
      {initialImage ? (
        <>
          <img src={imagesrc} alt={title} />
          <div>
            <Button variant="contained" component="label" sx={{ mt: 1, mb: 2 }}>
              Change Image <FileUploadOutlinedIcon style={{ marginLeft: "8px" }} />
              <input type="file" hidden onChange={onChange} />
            </Button>
          </div>
        </>
      ) : (
        <div>
          <label htmlFor={title} className={`custom-img-upload ${className}`}>
            <span>{icon && <FileUploadOutlinedIcon />}</span> <span>{title}</span>
          </label>
          <input
            id={title}
            type="file"
            onChange={onChange}
            accept={accept}
            className="file-upload"
            ref={ref}
          />
        </div>
      )}
    </div>
  );
};

export default UploadImageBox;
