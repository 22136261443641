import React from "react";
import "./ButtonMain.scss";

const ButtonMain = ({
  className,
  children,
  variant = "primary",
  onClick,
  style,
  disabled
}) => {
  return (
    <button
      className={`buttonMain ${disabled ? 'buttonDisabled' : null } ${variant} ${className}`}
      onClick={onClick}
      style={style}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default ButtonMain;
