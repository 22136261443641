import { Box, Divider, FormControl, MenuItem } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { POST } from "../../../../../config/Api";

import { Constants, creditPoints, scoreLists } from "../../../../../config/Constants";
import CustomAccordion from "../../../../Accrodion/Accordion";
import ButtonMain from "../../../../ButtonMain";
import { CheckboxInput, Dropdown, InputBox } from "../../../../InputBox/InputBox";
import CustomizedSnackbars from "../../../../SnackBar/CustomizedSnackbars";
import CustomTextArea from "../../../../TextArea/CustomTextArea";
import { useQueryParams } from "../../../../../utils";
import SourceTextSwitcher from "../../../../SourceTextSwitcher/SourceTextSwitcher";
import { useOutletContext } from "react-router-dom";

const AddUploadFile = ({
  assessmentSettings,
  block,
  type,
  updateOrder,
  setblockModOpen,
  setmodalOpen,
  setCourseBlocks,
  blockModalOpen,
}) => {
  const [inputValue, setInputValue] = useState({
    title: block?.title || "",
    description: block?.text || "",
    score: block?.typeOfBlock || "attempt",
    credit: block?.score || 5,
    checked: false,
    is_required: block?.is_required || false,
  });
  const [assessment, setAssessment] = React.useState("");
  const [disable, setDisable] = useState(false);
  const [errText, seterrText] = useState("");
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [alertType, setalertType] = useState("error");
  const { setCourseOne } = useOutletContext();

  let UserData = useSelector((state) => state.userLogin);

  const { cid, mid } = useQueryParams();

  useEffect(() => {
    if (block) {
      setAssessment(block.assessmentType ? block.assessmentType : "");
    }
  }, [block]);

  const handleSubmit = async () => {
    if (inputValue.title === "") {
      setOpen(true);
      seterrText("Please Enter Title.");
    } else if (inputValue.description === "") {
      setOpen(true);
      seterrText("Please Enter Description");
    } else {
      let body = {
        user_id: UserData?.data?.user?.id,
        course: cid,
        container: mid,
        order: blockModalOpen?.addBelow ? blockModalOpen?.currentBlockOrder + 1 : updateOrder || 1,
        response_type: "file",
        highlightIfIncorrect: inputValue.checked,
        typeOfBlock: inputValue.score,
        score: inputValue.credit,
        title: inputValue.title,
        text: inputValue.description,
        type: type,
        is_required: inputValue.is_required,
        assessmentType: assessment,
      };
      setDisable(true);
      //add upload file block
      await POST(`${Constants.addBlock}/response?_id=${block?._id}`, body)
        .then((response) => {
          setDisable(false);
          setOpen(true);
          setalertType("success");
          seterrText("Block Created Successfully");
          setCourseOne((prev) => ({ ...prev, published: false }));
          if (type === "edit") {
            setCourseBlocks &&
              setCourseBlocks((prev) => {
                const elIndex = prev.findIndex((el) => el._id === block?._id);
                const updatedBlocks = prev.map((el) => {
                  if (el.order >= response?.data?.order) {
                    el.order += 1;
                  }
                  return el;
                });
                updatedBlocks.splice(elIndex, 1, response?.data);
                updatedBlocks.sort((a, b) => a?.order - b?.order);
                return updatedBlocks;
              });
          } else {
            if (blockModalOpen?.addBelow) {
              setCourseBlocks &&
                setCourseBlocks((prev) => {
                  const updatedBlocks = prev.map((el) => {
                    if (el.order >= response?.data?.order) {
                      el.order += 1;
                    }
                    return el;
                  });
                  const elIndex = updatedBlocks.findIndex(
                    (el) => el._id === blockModalOpen?.currentBlockId,
                  );
                  updatedBlocks.splice(elIndex + 1, 0, response?.data);
                  return updatedBlocks;
                });
            } else {
              setCourseBlocks &&
                setCourseBlocks((prev) => {
                  prev.forEach((el) => {
                    el.order = el.order + 1;
                  });
                  return [response?.data, ...prev];
                });
            }
          }
          setmodalOpen && setmodalOpen((prev) => ({ ...prev, modalOpen: false }));
          setblockModOpen(false);
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <div>
      <FormControl
        sx={{ paddingBottom: "10px", width: "100%" }}
        variant="standard"
        disabled={!assessmentSettings?.isAdvanceAssessment}
      >
        <Dropdown
          onChange={(event) => setAssessment(event.target.value)}
          value={assessment}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <span className="dropdownLabel">Select a Domain</span>;
            } else {
              let selectedDomain = assessmentSettings?.assessmentDomain?.find(
                (domain) => domain?._id === selected,
              );
              return <span>{selectedDomain?.title}</span>;
            }
          }}
        >
          {assessmentSettings?.assessmentDomain?.length > 0 &&
            assessmentSettings?.assessmentDomain?.map((item, i) => {
              return (
                <MenuItem value={item?._id} key={i}>
                  {item?.title}
                </MenuItem>
              );
            })}
        </Dropdown>
      </FormControl>
      <SourceTextSwitcher
        checked={checked}
        handleChange={(event) => setChecked(event.target.checked)}
        srcText={block?.srcText}
        srcTitleText={block?.srcTitleText}
      />
      <InputBox
        placeholder="Title"
        onChange={(event) => setInputValue({ ...inputValue, title: event.target.value })}
        value={inputValue.title}
      />{" "}
      {checked && (
        <Box my={2}>
          <strong>Source Text:</strong> {block?.srcTitleText}
        </Box>
      )}
      <br />
      <CustomTextArea
        placeholder="Description"
        onChange={(event) => setInputValue({ ...inputValue, description: event.target.value })}
        value={inputValue.description}
      />
      {checked && (
        <Box my={2}>
          <strong>Source Text:</strong> {block?.srcText}
        </Box>
      )}
      <>
        <Divider sx={{ borderColor: "black", margin: "10px 0px" }} />
        <CustomAccordion
          first={
            <Dropdown
              onChange={(event) => setInputValue({ ...inputValue, score: event.target.value })}
              value={inputValue.score}
            >
              {scoreLists?.map((item, index) => (
                <MenuItem value={item} key={index}>
                  {item}
                </MenuItem>
              ))}
            </Dropdown>
          }
          second={
            <Dropdown
              onChange={(event) => setInputValue({ ...inputValue, credit: event.target.value })}
              value={inputValue.credit}
            >
              {creditPoints?.map((item, index) => (
                <MenuItem value={item} key={index}>
                  {item}
                </MenuItem>
              ))}
            </Dropdown>
          }
          third={
            <CheckboxInput
              label="Highlight if responded incorrectlyinfo"
              onChange={(event) =>
                setInputValue({
                  ...inputValue,
                  checked: event.target.checked,
                })
              }
              value={!inputValue.checked}
            />
          }
          fourth={
            <CheckboxInput
              label="Mark As Required"
              onChange={(event) =>
                setInputValue({
                  ...inputValue,
                  is_required: event.target.checked,
                })
              }
              value={!inputValue.is_required}
            />
          }
        />
      </>
      <Divider sx={{ borderColor: "black", margin: "10px 0px" }} />
      <ButtonMain className="buttonGreen" onClick={handleSubmit} disabled={disable}>
        Save Block
      </ButtonMain>
      <CustomizedSnackbars
        AlertText={errText}
        AlertType={alertType}
        open={open}
        onClose={() => setOpen(false)}
      />
    </div>
  );
};

export default AddUploadFile;
