import React, { useState } from "react";
import { useSelector } from "react-redux";
import BlockActions from "../BlockActions/BlockActions";
import "./Discussion.scss";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "right",
  justifyContent: "right",
  color: theme.palette.common.white,
}));

const DiscussionView = ({
  discussion,
  courseDetails,
  courseDetail,
  courseOne,
  setmodalOpen,
  setblockModOpen,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [hidden, setHidden] = useState(discussion?.is_hidden);
  let { isLoggedIn, user } = useSelector((state) => state.userLogin?.data) || {};
  let isModerator =
    courseDetails?.creator === user?.id ||
    courseDetails?.members?.findIndex((item) => item?.user === user?.id);

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  return (
    <>
      {(isModerator === -1 || isModerator === undefined) && discussion?.is_hidden ? (
        ""
      ) : (
        <div
          className={` discussion-block 
      ${isLoggedIn ? `${discussion?.theme} paperLight` : `${discussion?.theme} defaultStyle`}
    `}
          style={{
            background: `${discussion?.theme_type === "color" ? discussion?.color : ""}`,
          }}
          onMouseOver={handleHover}
          onMouseLeave={handleMouseLeave}
        >
          {isModerator !== -1 && isLoggedIn && isHovering && (
            <BlockActions
              blockId={discussion?._id}
              block={discussion}
              setmodalOpen={setmodalOpen}
              setblockModOpen={setblockModOpen}
              hidden={hidden}
              setHidden={setHidden}
            />
          )}
          {isModerator !== -1 && discussion?.carousel_container && (
            <Image>
              <Tooltip title="This Block Tagged to Carousel Block">
                <LocalOfferRoundedIcon fontSize="large" color="primary" />
              </Tooltip>
            </Image>
          )}
          <p className="block-title">{discussion.title}</p>
          <p className="block-text" dangerouslySetInnerHTML={{ __html: discussion.text }}></p>
        </div>
      )}
    </>
  );
};

export default DiscussionView;
