import { deleteCookie } from "../utils";
import {
  SAVE_COURSE_DETAIL_FAIL,
  SAVE_COURSE_DETAIL_SUCCESS,
  SAVE_MODULE_DETAIL_SUCCESS,
  SAVE_MODULE_DETAIL_FAIL,
  USER_LOGIN_FAIL,
  USER_LOGIN_SUCCESS,
  NEW_USER_CLEANUP,
  USER_LOGOUT,
  RENDER_BLOCKS,
} from "./constants";

export const saveCourseDetailAction = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SAVE_COURSE_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: SAVE_COURSE_DETAIL_FAIL,
      payload: err.message,
    });
  }
};

export const saveModuleDetailAction = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SAVE_MODULE_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: SAVE_MODULE_DETAIL_FAIL,
      payload: err.message,
    });
  }
};

export const userLoginAction = (data) => async (dispatch) => {
  try {
    data.isLoggedIn = true;
    localStorage.setItem("userData", JSON.stringify(data));
    data.user.preferredLanguage &&
      localStorage.setItem("preferredLanguage", JSON.stringify(data.user.preferredLanguage));
    localStorage.setItem("token", data?.accessToken);
    localStorage.setItem("sessionTokenHash", data?.sessionTokenHash);
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });
  } catch (err) {
    console.log({ err });
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: err.message,
    });
  }
};
export const userSignUpAction = (data) => async (dispatch) => {
  try {
    data.isLoggedIn = true;
    localStorage.setItem("userData", JSON.stringify(data));
    localStorage.setItem("token", data?.accessToken);
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: {
        ...data,
        newUser: true,
      },
    });
  } catch (err) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: err.message,
    });
  }
};
export const newUserCleanUp = (data) => async (dispatch) => {
  dispatch({
    type: NEW_USER_CLEANUP,
    payload: {
      ...data,
      newUser: false,
    },
  });
};
export const logoutAction = () => async (dispatch) => {
  localStorage.removeItem("userData");
  localStorage.removeItem("token");
  localStorage.removeItem("preferredLanguage");
  localStorage.removeItem("sessionTokenHash");
  let cookieName = process.env.REACT_APP_SERVER_TYPE === "prod" ? "fsUserData_PROD" : "fsUserData";
  deleteCookie(cookieName);
  dispatch({ type: USER_LOGOUT });
};
export const rerenderBlocksAction = () => async (dispatch) => {
  dispatch({ type: RENDER_BLOCKS });
};
