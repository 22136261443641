import React, { useEffect, useState, useRef } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import ButtonMain from "../../../../ButtonMain";
import { CheckboxInput, CustomInputFile, Dropdown, InputBox } from "../../../../InputBox/InputBox";
import CustomTextArea from "../../../../TextArea/CustomTextArea";
import { Constants, creditPoints } from "../../../../../config/Constants";
import { POST } from "../../../../../config/Api";
import CustomizedSnackbars from "../../../../SnackBar/CustomizedSnackbars";
import { useSelector } from "react-redux";
import { useQueryParams } from "../../../../../utils";
import { cloudfrontUrl } from "../../../../../config/BaseUrl";
import { Divider, MenuItem, Typography } from "@mui/material";
import CustomAccordion from "../../../../Accrodion/Accordion";
import SourceTextSwitcher from "../../../../SourceTextSwitcher/SourceTextSwitcher";
import { useOutletContext } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "##1A2027",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const AddMatch = ({
  courseDetails,
  blockDetails,
  type,
  updateOrder,
  setblockModOpen,
  setCourseBlocks,
  blockModalOpen,
}) => {
  const [match, setMatch] = useState({
    title: blockDetails?.title || "",
    description: blockDetails?.text || "",
    score: blockDetails?.typeOfBlock || "attempt",
    credit: blockDetails?.score || 5,
    checked: false,
    assessment: "",
    is_required: blockDetails?.is_required || false,
  });
  const [optText, setoptText] = useState({
    leftText: "",
    rightText: "",
  });
  const [disable, setdisable] = useState(false);
  const [checked, setChecked] = useState(false);
  const [block, setBlock] = useState({});
  const [blockToBeAdded, setBlockToBeAdded] = useState({});
  const [selectedLeftImage, setSelectedLeftImage] = useState(null);
  const [selectedRightImage, setSelectedRightImage] = useState(null);

  const [leftUrlPath, setLefturlPath] = useState({});
  const [rightUrlPath, setRighturlPath] = useState({});

  const [errText, seterrText] = useState("");
  const [open, setOpen] = useState(false);

  const [leftOption, setleftOption] = useState([]);
  const [rightOption, setrightOption] = useState([]);

  const [leftButtonDisable, setleftButtonDisable] = useState(false);
  const [rightButtonDisable, setrightButtonDisable] = useState(false);

  const { setCourseOne } = useOutletContext();

  const blockidRef = useRef(block?._id || blockDetails?._id); // Use a ref to store block id
  const [disableButn, setDisableButton] = useState(false);

  useEffect(() => {
    if (blockDetails) {
      let left = blockDetails?.options?.filter((item) => item?.is_optionb === false);
      setleftOption(left);
      let right = blockDetails?.options?.filter((item) => item?.is_optionb === true);
      setrightOption(right);
    }
  }, []);

  let UserData = useSelector((state) => state.userLogin);
  const { cid, mid } = useQueryParams();

  const handleNextClick = async () => {
    if (match.title === "") {
      setOpen(true);
      seterrText("Please Enter Question Title.");
    } else if (match.description === "") {
      setOpen(true);
      seterrText("Please Enter Description.");
    } else {
      try {
        setDisableButton(true);
        setdisable(true);
        let body = {
          _id: blockidRef?.current ? blockidRef?.current : "",
          user_id: UserData?.data?.user?.id,
          course: cid,
          container: mid,
          order: blockModalOpen?.addBelow
            ? blockModalOpen?.currentBlockOrder + 1
            : updateOrder || 1,
          title: match.title,
          text: match.description,
          type: type,
          assessment: match.assessment,
          is_required: match.is_required,
          highlightIfIncorrect: match.checked,
          typeOfBlock: match.score,
          score: match.credit,
        };
        //create match block
        const response = await POST(`${Constants.addBlock}/match?_id=${blockidRef.current}`, body);
        const newBlock = response?.data;
        setBlock(newBlock);
        setdisable(false);
        setDisableButton(false);
        // setBlockToBeAdded((prev) => ({ ...prev, ...response?.data }));
        setCourseBlocks((prevBlocks) => {
          const updatedBlocks = prevBlocks.map((prevBlock) =>
            prevBlock._id === (blockDetails?._id || newBlock?._id) ? newBlock : prevBlock,
          );
          return updatedBlocks;
        });
        setCourseOne((prev) => ({ ...prev, published: false }));
        blockidRef.current = newBlock?._id;
        return newBlock;
      } catch (error) {
        setDisableButton(false);
        console.log(error);
      }
    }
  };
  const handleLeftFileChange = async (event) => {
    setSelectedLeftImage(event.target.files[0]);
    const formData = new FormData();
    formData.append("image", event.target.files[0]);
    let header = "multipart/form-data";
    try {
      //upload image
      const response = await POST(`${Constants.addBlock}/uploadImage`, formData, header);
      setLefturlPath(response?.data?.image);
    } catch (error) {
      console.log(error);
    }
  };
  const handleRightFileChange = async (event) => {
    setSelectedRightImage(event.target.files[0]);
    const formData = new FormData();
    formData.append("image", event.target.files[0]);
    let header = "multipart/form-data";
    try {
      //upload image
      const response = await POST(`${Constants.addBlock}/uploadImage`, formData, header);
      setRighturlPath(response?.data?.image);
    } catch (error) {
      console.log(error);
    }
  };
  const handleAddLeftOption = async () => {
    if (optText.leftText === "") {
      setOpen(true);
      seterrText("Please Enter Left Option Text.");
    } else {
      try {
        // Check if block ID is not present, then call handleNextStep
        let currentBlock = blockidRef.current ? block : await handleNextClick();

        // If block ID is still not present after handleNext, do not proceed
        if (!currentBlock) {
          return;
        }

        setleftButtonDisable(true);
        let body = {
          user_id: UserData?.data?.user?.id,
          course: courseDetails?._id,
          image: leftUrlPath,
          text: optText.leftText,
        };
        //add match left option
        const response = await POST(
          `${Constants.updateBlock}/${blockidRef.current}/add_option`,
          body,
        );
        let leftoptiontext = [];
        response?.data?.options?.map((item) => {
          if (item?.is_optionb === false) {
            leftoptiontext.push(item);
          }
          return null; // or undefined
        });
        if (type !== "edit") {
          setBlockToBeAdded((prev) => ({ ...prev, ...response?.data }));
        }
        setleftButtonDisable(false);
        setleftOption(leftoptiontext);

        setoptText({ ...optText, leftText: "" });
        setSelectedLeftImage(null);
        setLefturlPath({});
        setCourseOne((prev) => ({ ...prev, published: false }));
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleAddRightOption = async () => {
    if (optText.rightText === "") {
      setOpen(true);
      seterrText("Please Enter Right Option Text.");
    } else {
      try {
        let currentBlock = blockidRef.current ? block : await handleNextClick();

        // If block ID is still not present after handleNext, do not proceed
        if (!currentBlock) {
          return;
        }

        let body = {
          user_id: UserData?.data?.user?.id,
          course: courseDetails?._id,
          image: rightUrlPath,
          text: optText.rightText,
          is_optionb: true,
        };
        setrightButtonDisable(true);
        //add match right option
        const response = await POST(
          `${Constants.updateBlock}/${blockidRef.current}/add_option`,
          body,
        );
        let rightoptiontext = [];
        response?.data?.options?.map((item) => {
          if (item?.is_optionb === true) {
            rightoptiontext.push(item);
          }
          return null;
        });
        if (type !== "edit") {
          setBlockToBeAdded((prev) => ({ ...prev, ...response?.data }));
        }
        setrightButtonDisable(false);
        setrightOption(rightoptiontext);
        setoptText({ ...optText, rightText: "" });
        setSelectedRightImage(null);
        setRighturlPath({});
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div>
      <SourceTextSwitcher
        checked={checked}
        handleChange={(event) => setChecked(event.target.checked)}
        srcText={blockDetails?.srcText}
        srcTitleText={blockDetails?.srcTitleText}
      />
      <InputBox
        placeholder="Title"
        value={match.title}
        onChange={(e) => setMatch({ ...match, title: e.target.value })}
        disabled={disable}
      />
      {checked && (
        <Box my={2}>
          <strong>Source Text:</strong> {blockDetails?.srcTitleText}
        </Box>
      )}
      <br />
      <CustomTextArea
        placeholder="Description"
        value={match.description}
        onChange={(e) => setMatch({ ...match, description: e.target.value })}
        disabled={disable}
      />{" "}
      {checked && (
        <Box my={2}>
          <strong>Source Text:</strong> {blockDetails?.srcText}
        </Box>
      )}
      <br />
      {/* {next && (
        <ButtonMain className="buttonGreen" onClick={handleNextClick}>
          Next Step
        </ButtonMain>
      )}
      {!next && ( */}
      <>
        <b>Create new option:</b>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} columns={16}>
            <Grid item xs={14} sm={8} md={8}>
              <Item>
                {!Object.keys(leftUrlPath).length ? (
                  <CustomInputFile
                    title={
                      selectedLeftImage
                        ? selectedLeftImage.name?.length > 20 &&
                          selectedLeftImage.name.substring(0, 10)
                        : "Option Image"
                    }
                    icon={false}
                    onChange={handleLeftFileChange}
                  />
                ) : (
                  <img
                    src={cloudfrontUrl + leftUrlPath?.m}
                    alt="leftimg"
                    height="150px"
                    width="150px"
                  />
                )}
                <InputBox
                  placeholder="Option Text"
                  value={optText.leftText}
                  onChange={(e) => setoptText({ ...optText, leftText: e.target.value })}
                />
                <br />

                <Button
                  variant="contained"
                  style={{ float: "inline-end", marginTop: "1rem" }}
                  onClick={handleAddLeftOption}
                  disabled={leftButtonDisable}
                >
                  Add Option
                </Button>
              </Item>
            </Grid>
            <Grid item xs={14} sm={8} md={8}>
              <Item>
                {!Object.keys(rightUrlPath).length ? (
                  <CustomInputFile
                    title={
                      selectedRightImage
                        ? selectedRightImage?.name?.length > 20 &&
                          selectedRightImage?.name.substring(0, 10)
                        : "Option Image"
                    }
                    icon={false}
                    onChange={handleRightFileChange}
                  />
                ) : (
                  <img
                    src={cloudfrontUrl + rightUrlPath?.m}
                    alt="rightimg"
                    height="150px"
                    width="150px"
                  />
                )}
                <InputBox
                  placeholder="Option Text"
                  value={optText.rightText}
                  onChange={(e) => setoptText({ ...optText, rightText: e.target.value })}
                />{" "}
                <br />
                <Button
                  variant="contained"
                  style={{ float: "inline-end", marginTop: "1rem" }}
                  onClick={handleAddRightOption}
                  disabled={rightButtonDisable}
                >
                  Add Option
                </Button>
              </Item>
            </Grid>
          </Grid>
        </Box>{" "}
        <br />
        {/* options */}
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} columns={16}>
            {leftOption.length ? (
              <Grid item xs={14} sm={8} md={8}>
                <Item>
                  {leftOption?.map((item) => (
                    <Box>
                      <Typography variant="h6" key={item?._id}>
                        {item?.text}
                      </Typography>
                      {checked && (
                        <Box my={2}>
                          <strong>Source Text:</strong> {item?.srcText}
                        </Box>
                      )}
                    </Box>
                  ))}
                </Item>
              </Grid>
            ) : (
              ""
            )}
            {rightOption.length ? (
              <Grid item xs={14} sm={8} md={8}>
                <Item>
                  {rightOption?.map((item) => (
                    <Box>
                      <Typography variant="h6" key={item?._id}>
                        {item?.text}
                      </Typography>
                      {checked && (
                        <Box my={2}>
                          <strong>Source Text:</strong> {item?.srcText}
                        </Box>
                      )}
                    </Box>
                  ))}
                </Item>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </Box>{" "}
        <br />
        <Divider sx={{ borderColor: "black", margin: "10px 0px" }} />
        <CustomAccordion
          first={
            <Dropdown
              onChange={(event) => setMatch({ ...match, score: event.target.value })}
              value={match.score}
            >
              {["attempt", "score", "none"]?.map((item, index) => (
                <MenuItem value={item} key={index}>
                  {item}
                </MenuItem>
              ))}
            </Dropdown>
          }
          second={
            <Dropdown
              onChange={(event) => setMatch({ ...match, credit: event.target.value })}
              value={match.credit}
            >
              {creditPoints?.map((item, index) => (
                <MenuItem value={item} key={index}>
                  {item}
                </MenuItem>
              ))}
            </Dropdown>
          }
          third={
            <CheckboxInput
              label="Highlight if responded incorrectlyinfo"
              onChange={(event) => setMatch({ ...match, checked: event.target.checked })}
              value={!match.checked}
            />
          }
          fourth={
            <CheckboxInput
              label="Mark As Required"
              onChange={(event) =>
                setMatch({
                  ...match,
                  is_required: event.target.checked,
                })
              }
              value={!match.is_required}
            />
          }
        />
        <Divider sx={{ borderColor: "black", margin: "10px 0px" }} />
        <br />
        <ButtonMain
          className="buttonGreen"
          onClick={async () => {
            const blockId = await handleNextClick(); // Wait for handleNextStep to complete
            if (!blockId) return;

            if (type === "edit") {
              setCourseBlocks &&
                setCourseBlocks((prevBlocks) => {
                  const updatedBlocks = prevBlocks.map((prevBlock) => {
                    if (prevBlock._id === blockToBeAdded._id) {
                      return blockToBeAdded; // Update existing block
                    }
                    return prevBlock;
                  });
                  return updatedBlocks;
                });
            } else {
              if (blockModalOpen?.addBelow) {
                setCourseBlocks &&
                  setCourseBlocks((prev) => {
                    const updatedBlocks = prev.map((el) => {
                      if (el.order >= blockId?.order) {
                        el.order += 1;
                      }
                      return el;
                    });
                    const elIndex = updatedBlocks.findIndex(
                      (el) => el._id === blockModalOpen?.currentBlockId,
                    );
                    updatedBlocks.splice(elIndex + 1, 0, blockId);
                    return updatedBlocks;
                  });
              } else {
                setCourseBlocks &&
                  setCourseBlocks((prev) => {
                    prev.forEach((el) => {
                      el.order = el.order + 1;
                    });
                    return [blockId, ...prev];
                  });
              }
            }
            setblockModOpen && setblockModOpen(false);
            //  setmodalOpen && setmodalOpen((prev) => ({ ...prev, modalOpen: false }));
          }}
          disabled={disableButn}
        >
          Save Block
        </ButtonMain>
      </>
      <CustomizedSnackbars
        AlertText={errText}
        AlertType={"error"}
        open={open}
        onClose={() => setOpen(false)}
      />
    </div>
  );
};

export default AddMatch;
