import React, { useRef } from "react";
import IconButton from "@mui/material/IconButton";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useOutletContext } from "react-router-dom";
import { Box, Button, Tooltip, Zoom } from "@mui/material";
import { useState } from "react";

import "./CourseModal.scss";
import FMModal from "../../ModalMain/CourseModals/FMModal";

const ShareOnSocialMedia = ({ openSocialMedia, setOpenSocialMedia, name, certificateData }) => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const textToCopyRef = useRef(null);
  const currentUrl = window.location.origin;
  const [isCopied, setIsCopied] = useState(false);

  const { courseOne, completedModulesData } = useOutletContext();

  const url = `Verification Link : ${currentUrl}/verifyCertificate?vcode=${
    completedModulesData?.certificateVerificationCode ||
    certificateData?.certificateVerificationCode
  }&name=${name}`;
  const title = "Framerspace : Empowering Learners";
  const description = `*I'm happy to share that , I've completed "${courseOne?.title}" course on Framerspace.* \n\n 🎉🎉🏆🏆🏆🎉🎉\n`;

  const handleShare = async (media) => {
    switch (media) {
      case "whatsapp":
        const whatsappMessage = `${title}\n${description}\n${url}`;

        if (isMobile) {
          // Open on mobile
          window.location.href = `whatsapp://send?text=${encodeURIComponent(
            whatsappMessage,
          )}&abid=contacts`;
        } else {
          // Open on WhatsApp Web
          window.open(
            `https://web.whatsapp.com/send?text=${encodeURIComponent(
              whatsappMessage,
            )}&abid=contacts`,
            "_blank",
          );
        }
        break;
      case "facebook":
        const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          currentUrl,
        )}`;
        window.open(facebookShareUrl, "_blank");
        break;
      case "linkedin":
        const linkedInShareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
          currentUrl,
        )}`;
        window.open(linkedInShareUrl, "_blank");
        break;
      default:
        break;
    }
  };

  const handleCopy = async () => {
    try {
      const textToCopy = textToCopyRef.current.innerText;
      await navigator.clipboard.writeText(textToCopy);

      setIsCopied(true);

      // Reset the copied state after a certain duration
      setTimeout(() => {
        setIsCopied(false);
      }, 500);
    } catch (error) {
      // Handle error, e.g., show an error message
      console.error("Failed to copy text to clipboard", error);
    }
  };

  return (
    <FMModal
      modalOpen={openSocialMedia}
      modalClose={() => {
        setOpenSocialMedia(false);
      }}
      modalHeader={"Share on Social Media"}
      className="confirm_modal"
      headerTextColor={"header_text_black"}
      backgroundImage={{
        backgroundImage: "linear-gradient(#a1c4fd, #c2e9fb)",
      }}
    >
      <Box className="share-on-social-media">
        <div ref={textToCopyRef}>
          <p className="text">
            I'm happy to share that, I've completed <strong>{courseOne?.title}</strong> course on
            Framerspace.* 🎉🎉🏆🏆🏆🎉🎉
          </p>
          <p className="link">
            Verification Link :{currentUrl}
            /verifyCertificate?vcode=
            {completedModulesData?.certificateVerificationCode ||
              certificateData?.certificateVerificationCode}
            &name=
            {name}
          </p>
        </div>
      </Box>
      <Box variant="body2" className="social-media-content">
        <Tooltip TransitionComponent={Zoom} title={`${isCopied ? "Text Copied" : "Copy Text"}`}>
          <Button className="copy-button" onClick={handleCopy}>
            Copy Text
          </Button>
        </Tooltip>
        <div className="icon-container">
          <div>Copy the above text and post it on your respective social network.</div>
          <div className="social-icons">
            <WhatsAppIcon />
            <FacebookIcon />
            <LinkedInIcon />
          </div>
        </div>
      </Box>
      <br />
      <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
        Click here to share directly on WhatsApp
        <IconButton color="primary" onClick={() => handleShare("whatsapp")}>
          <WhatsAppIcon />
        </IconButton>
      </Box>
    </FMModal>
  );
};

export default ShareOnSocialMedia;
