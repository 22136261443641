import React, { useEffect, useState } from "react";
import "./CourseLayout.scss";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import HeaderContainer from "../../HeaderContainer/HeaderContainer";

import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import { Box, Container, Tooltip } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import MenuIcon from "@mui/icons-material/Menu";
import QuestionAnswerRoundedIcon from "@mui/icons-material/QuestionAnswerRounded";

import "../CourseCommonStyle.scss";
import { useDispatch, useSelector } from "react-redux";
import { GET } from "../../../config/Api";
import { Constants, userRoles } from "../../../config/Constants";
import { flattenNavArr, getCookie, getParents, useQueryParams } from "../../../utils";
import Navigate from "../Navigate/Navigate";
import { saveCourseDetailAction } from "../../../redux/action";
import { TabbedLayoutLoader } from "../../Loader/Loader";
import NewBreadcrumbs from "../../Breadcrumb/NewBreadcrumbs";

const CourseLayout = () => {
  const [courseOne, setCourseOne] = useState({});
  const [courseNav, setCourseNav] = useState(false);
  const [courseContentSlug, setCourseContentSlug] = useState("");
  const [modDetails, setModDetails] = useState([]);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [tabs, setTabs] = useState([
    {
      title: "Learner Dashboard",
      value: "learner-dashboard",
      slug: "learner-dashboard",
      icon: <DonutSmallIcon />,
      disabled: true,
    },
  ]);
  const [completedModulesData, setCompletedModulesData] = useState({
    modules: [],
  });
  const [userDetails, setUserDetails] = useState({ role: userRoles.LEARNER });
  // const { setAuthStates } = useContext(AuthContext);

  const { cid, mid } = useQueryParams();
  const { slug } = useParams();
  let navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  let { isLoggedIn, user } = useSelector((state) => state.userLogin?.data) || {};

  const slugs = ["discussion-forum", "learner-dashboard"];
  const moderatorSlugs = ["createblock", "createblock/createcontainer"];

  // useEffect(() => {
  //   if (!isLoggedIn) {
  //     window.location.replace(
  //       `${process.env.REACT_APP_AUTH_URL_FE}?referrer=${window.location.href}`,
  //     );
  //     // setAuthStates((prev) => ({
  //     //   ...prev,
  //     //   loginOpen: true,
  //     //   modalOpen: true,
  //     // }));
  //   }
  // }, [isLoggedIn]);

  useEffect(() => {
    if (location.state?.userData) {
      setUserDetails(location.state.userData);
      isLoggedIn && getUserActivity(location.state.userData.user_id);
    } else {
      user && getUserActivity(user?.id);
      setUserDetails({
        role: userRoles.LEARNER,
        user_id: user?.id,
        token: "",
      });
    }
  }, [location, user]);

  useEffect(() => {
    let cookieName =
      process.env.REACT_APP_SERVER_TYPE === "prod" ? "fsUserData_PROD" : "fsUserData";
    let userDataCookie = getCookie(cookieName);
    // userDetails?
    (async () => {
      // Get Course Details
      try {
        //get public course details
        const response = await GET(`${Constants.getPublicCourseDetail}/${cid}`, {});
        if (!userDataCookie && !isLoggedIn && response?.data?.data.privacy === "private") {
          window.location.replace(
            `${process.env.REACT_APP_AUTH_URL_FE}?referrer=${window.location.href}`,
          );
          // setAuthStates((prev) => ({
          //   ...prev,
          //   loginOpen: true,
          //   modalOpen: true,
          // }));
        } else {
          var userRole = location?.state?.userData;
          var courseData = response?.data?.data;
          setCourseOne(response?.data?.data);
          const updatedTabs = [...tabs];

          if (courseData.learnerDashboard) {
            updatedTabs[0] = {
              ...updatedTabs[0],
              disabled: false,
            };
          }

          if (courseData.discussionForum) {
            updatedTabs.unshift({
              title: "Discussion Forum",
              value: "discussion",
              slug: "discussion-forum",
              icon: <QuestionAnswerRoundedIcon />,
              disabled: userRole?.role === userRoles.REVIEWER ? true : false,
            });
          }

          setTabs(updatedTabs);
          dispatch(saveCourseDetailAction(response.data.data));
        }
      } catch (err) {
        navigate("/404");
      }

      if (isLoggedIn) {
        // Get Module Details
        const { data } = await GET(`${Constants.getCourseContainerBlocks}/${cid}`);
        if (data?.success) {
          setModDetails(data?.blocks);
        }
      }
    })();
  }, []);

  const getUserActivity = async (user_id) => {
    // getting user course activities
    try {
      const response = await GET(`${Constants.course}/activity/${cid}/${user_id}`);
      setCompletedModulesData(response?.data?.userCourseActivity);
    } catch (error) {
      navigate(`/course/${slug}?cid=${cid}`);
    }
  };

  useEffect(() => {
    if (mid && modDetails.length > 0) {
      const breadcrumbData = getParents(flattenNavArr(modDetails), mid);
      setBreadcrumbs(breadcrumbData);
    } else {
      setBreadcrumbs([]);
    }
  }, [mid, modDetails]);

  return (
    <>
      <HeaderContainer
        className="header_container"
        isReviewer={userDetails.role === userRoles.REVIEWER ? true : false}
      >
        <Container maxWidth="xl" sx={{ width: "97%", py: 3 }}>
          {courseOne?._id ? (
            <>
              <div className="title-container">
                <h2>{courseOne?.title}</h2>
              </div>
              {isLoggedIn &&
                completedModulesData?.status === "enrolled" &&
                !moderatorSlugs.includes(location.pathname.split("/")[2]) && (
                  <div className="custom-tabs">
                    <div className="tab-buttons">
                      <>
                        <Tooltip title="Course Navigation">
                          {!courseNav ? (
                            <button
                              onClick={() => setCourseNav(true)}
                              disabled={slugs.includes(location.pathname.split("/")[3])}
                            >
                              <MenuIcon className="menu-icon" />
                            </button>
                          ) : (
                            <button
                              disabled={slugs.includes(location.pathname.split("/")[3])}
                              className={`${
                                courseNav && !slugs.includes(location.pathname.split("/")[3])
                                  ? "active"
                                  : ""
                              }`}
                              // className={`${courseNav && activeTab === "course-content" && "active"}`}
                              onClick={() => setCourseNav(false)}
                            >
                              <ClearIcon />
                            </button>
                          )}
                        </Tooltip>
                      </>
                      <Tooltip title={"Course Content"}>
                        <button
                          className={
                            !slugs.includes(location.pathname.split("/")[3]) ? "active" : ""
                          }
                          onClick={() => {
                            courseContentSlug
                              ? navigate(courseContentSlug, {
                                  state: {
                                    userData: userDetails,
                                  },
                                })
                              : navigate(`/course/${slug}?cid=${cid}`, {
                                  state: {
                                    userData: userDetails,
                                  },
                                });
                          }}
                        >
                          <LocalLibraryIcon />
                          <span className="tab-title">Course Content</span>
                        </button>
                      </Tooltip>
                      {tabs.map((tab, index) => (
                        <Tooltip title={tab.title} key={tab.value}>
                          <button
                            key={index}
                            disabled={tab.disabled}
                            className={tab.slug === location.pathname.split("/")[3] ? "active" : ""}
                            onClick={() => {
                              !slugs.includes(location.pathname.split("/")[3]) &&
                                setCourseContentSlug(
                                  window.location.pathname + window.location.search,
                                );
                              navigate(
                                `/course/${slug}${tab.slug && `/${tab.slug}`}?cid=${cid}${
                                  completedModulesData?.cohort_id
                                    ? "&coid=" + completedModulesData?.cohort_id
                                    : ""
                                }`,
                                {
                                  state: {
                                    userData: userDetails,
                                  },
                                },
                              );
                            }}
                          >
                            {tab.icon}
                            <span className="tab-title">{tab.title}</span>
                          </button>
                        </Tooltip>
                      ))}
                    </div>
                  </div>
                )}

              <Box display={"flex"} width={"100%"}>
                {!slugs.includes(location.pathname.split("/")[3]) && (
                  <Navigate
                    open={courseNav}
                    setOpen={setCourseNav}
                    modDetails={modDetails}
                    userDetails={userDetails}
                  />
                )}
                <Box width={"100%"}>
                  {!slugs.includes(location.pathname.split("/")[3]) && (
                    <NewBreadcrumbs breadcrumbs={breadcrumbs} userDetails={userDetails} />
                  )}
                  <Outlet
                    context={{
                      courseOne,
                      setCourseOne,
                      completedModulesData,
                      modDetails,
                      setCompletedModulesData,
                      userDetails,
                    }}
                  />
                </Box>
              </Box>
            </>
          ) : (
            <>
              <TabbedLayoutLoader />
            </>
          )}
        </Container>
      </HeaderContainer>
    </>
  );
};

export default CourseLayout;
