import {
  Checkbox,
  Chip,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  IconButton,
  Tooltip,
  Zoom,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import ButtonMain from "../../../../ButtonMain";
import { Link } from "@mui/material";
import { CheckboxInput, Dropdown, InputBox } from "../../../../InputBox/InputBox";
import { Constants, creditPoints, scoreLists } from "../../../../../config/Constants";
import CustomAccordion from "../../../../Accrodion/Accordion";
import { POST } from "../../../../../config/Api";
import { useSelector } from "react-redux";
import CustomizedSnackbars from "../../../../SnackBar/CustomizedSnackbars";
import { Box } from "@mui/system";
import { useQueryParams } from "../../../../../utils";
import "./AddMCQ.scss";
import swal from "sweetalert";
import SourceTextSwitcher from "../../../../SourceTextSwitcher/SourceTextSwitcher";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useOutletContext } from "react-router-dom";
import { ToastNotify } from "../../../../SnackBar/ToastNotify";
import UploadImageBox from "../../../../Image/UploadImageBox";
import QuillEditor from "../../../../QuillEditor/QuillEditor";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { CloseRounded } from "@mui/icons-material";
import { cloudfrontUrl } from "../../../../../config/BaseUrl";
import SaveIcon from "@mui/icons-material/Save";

const AddMCQ = ({
  assessmentSettings,
  blockDetails,
  type,
  updateOrder,
  setmodalOpen,
  setblockModOpen,
  setCourseBlocks,
  blockModalOpen,
}) => {
  const [assessment, setAssessment] = useState("");
  const [blockToBeAdded, setBlockToBeAdded] = useState({});

  const [inputValue, setInputValue] = useState({
    title: blockDetails?.title || "",
    is_multiple: blockDetails?.is_multiple || false,
    correctAnswers: "",
    score: blockDetails?.typeOfBlock || "attempt",
    credit: blockDetails?.score || 5,
    checked: false,
    optionText: "",
    is_required: blockDetails?.is_required || false,
    assessmentType: blockDetails?.assessmentType || "",
    mcq_style: blockDetails?.mcq_style || "Default Style",
  });
  const { setCourseOne } = useOutletContext();

  const [block, setBlock] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [urlPath, seturlPath] = useState({});
  const [disable, setDisable] = useState(false);
  const [disableButn, setDisableButton] = useState(false);
  const [errText, seterrText] = useState("");
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [alertType, setalertType] = useState("error");
  const [options, setoptions] = useState(blockDetails || {});
  const toastRef = useRef();
  const [isImage, setIsimage] = useState(false);
  const handleChange = (event) => {
    setIsimage(event.target.checked);
  };

  const [newoptions, setNewOptions] = useState(
    blockDetails?.mcqs || [{ text: "", image: null, is_correct: false }],
  );
  const blockidRef = useRef(block?._id || blockDetails?._id); // Use a ref to store block id

  const [correctOption, setCorrectOption] = useState(() => {
    const initialCorrectOptions = blockDetails?.mcqs?.reduce((acc, item) => {
      if (item?.is_correct === true) {
        acc.push(item?._id);
      }
      return acc;
    }, []);
    return initialCorrectOptions || [];
  });

  let UserData = useSelector((state) => state.userLogin);
  const { cid, mid } = useQueryParams();

  useEffect(() => {
    if (blockDetails) {
      setInputValue((prevState) => ({
        ...prevState,
        assessmentType: blockDetails.assessmentType || "",
      }));
    }
  }, [blockDetails]);

  const handleCheckboxChange = async (event) => {
    setInputValue((prevState) => ({ ...prevState, is_multiple: event.target.checked }));
    await handleNext(event.target.checked);
  };

  const handleFileChange = async (event, index) => {
    setIsimage(true);
    setSelectedFile(event.target.files[0]);
    const formData = new FormData();
    formData.append("image", event.target.files[0]);
    let header = "multipart/form-data";
    try {
      setDisable(true);
      //upload image
      const response = await POST(`${Constants.addBlock}/uploadImage`, formData, header);
      seturlPath(response?.data?.image);
      if (index !== undefined) {
        setNewOptions((prevOptions) => {
          const updatedOptions = [...prevOptions];
          updatedOptions[index].image = response?.data?.image;
          return updatedOptions;
        });
      }
      //setBlockToBeAdded((prev) => ({ ...prev, ...response?.data }));
      setCourseOne((prev) => ({ ...prev, published: false }));
      setDisable(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleNext = async (ismultiple) => {
    if (inputValue.title === "") {
      setOpen(true);
      seterrText("Please Enter Question Title.");
      return false;
    } else {
      setDisableButton(true);
      let body = {
        _id: blockidRef?.current ? blockidRef?.current : "",
        user_id: UserData?.data?.user?.id,
        course: cid,
        container: mid,
        order: blockModalOpen?.addBelow ? blockModalOpen?.currentBlockOrder + 1 : updateOrder || 1,
        highlightIfIncorrect: inputValue.checked,
        typeOfBlock: inputValue.score,
        score: inputValue.credit,
        title: inputValue.title,
        text: inputValue.description,
        is_multiple: !!ismultiple,
        type: type,
        is_required: inputValue.is_required,
        assessmentType: assessment,
        mcq_style: inputValue.mcq_style,
      };

      setDisable(true);
      try {
        const response = await POST(
          `${Constants.addBlock}/mcq?_id=${blockidRef.current}`, // Use existing block ID if available
          body,
        );
        const newBlock = response?.data;
        setoptions(response?.data);
        setNewOptions(response?.data?.mcqs || []);
        const correctOpt = response?.data?.mcqs
          ?.filter((option) => option.is_correct)
          .map((option) => option._id);

        setCorrectOption(correctOpt);

        setBlock(newBlock);
        setCourseBlocks((prevBlocks) => {
          const updatedBlocks = prevBlocks.map((prevBlock) =>
            prevBlock._id === (blockDetails?._id || response?.data?._id)
              ? response?.data
              : prevBlock,
          );
          return updatedBlocks;
        });

        blockidRef.current = newBlock?._id;
        return newBlock;
      } catch (error) {
        console.log(error);
        return false;
      } finally {
        setDisable(false); // Move the setDisable call outside of the try-catch block
        setDisableButton(false);
      }
    }
  };

  const handleAddOption = async () => {
    if (inputValue.optionText === "") {
      setOpen(true);
      seterrText("Please Enter Option Text.");
      return;
    }

    // Check if image toggle is on but no image is uploaded
    if (isImage && !selectedFile) {
      toastRef.current.showToastMessage(
        "Please upload an image for the option or turn off the image toggle.",
        "error",
      );
      return;
    }

    let currentBlock = blockidRef.current ? block : await handleNext();

    // If block ID is still not present after handleNext, do not proceed
    if (!currentBlock) {
      return;
    }

    let body = {
      user_id: UserData?.data?.user?.id,
      course: cid,
      bound: 118,
      image: isImage ? urlPath : {},
      order: 1,
      text: inputValue.optionText,
    };
    setDisable(true);

    try {
      // Add mcq option
      const response = await POST(
        `${Constants.updateBlock}/${blockidRef.current}/add_option`,
        body,
      );
      setoptions(response.data);
      if (type !== "edit") {
        setBlockToBeAdded((prev) => ({ ...prev, ...response?.data }));
      }
      setCourseOne((prev) => ({ ...prev, published: false }));
      setNewOptions(response?.data?.mcqs || []);
      setInputValue((prev) => ({ ...prev, optionText: "" }));
      setSelectedFile(null);
      seturlPath(null);
      setIsimage(false);
      setDisable(false);
      setOpen(true);
      //setNewOptions([...newoptions, { text: "", image: null, isCorrect: false }]);

      setalertType("success");
      seterrText("Option Added Successfully");
    } catch (error) {
      console.log(error);
      setDisable(false);
    }
  };

  const handleCorrectMcqOption = async (optionId, correctness, index) => {
    let body = {
      user_id: UserData?.data?.user?.id,
      course: cid,
      is_correct: !correctness,
      option: optionId,
    };

    setDisable(true);
    //add correct mcq option
    await POST(`${Constants.updateBlock}/${blockidRef.current}/correct_mcq_option`, body)
      .then((response) => {
        setoptions(response.data);

        if (index !== undefined) {
          const updatedOptions = [...newoptions];
          updatedOptions.forEach((option, idx) => {
            if (response?.data?.is_multiple) {
              option.is_correct = response.data.mcqs[idx].is_correct;
            } else {
              if (idx === index) {
                option.is_correct = response.data.mcqs[index].is_correct;
              } else {
                option.is_correct = false;
              }
            }
          });

          setNewOptions(updatedOptions);
        }
        const correctOpt = response?.data?.mcqs
          ?.map((option) => (option.is_correct === true ? option?._id : null))
          .filter(Boolean);

        if (response?.data?.is_multiple) {
          setCorrectOption(correctOpt);
        } else {
          setCorrectOption(() => []);
          setCorrectOption(() => correctOpt);
        }
        setDisable(false);
        setOpen(true);
        setalertType("success");
        seterrText("Option set as correct answer");
        //setBlockToBeAdded((prev) => ({ ...prev, ...response?.data }));
        setCourseOne((prev) => ({ ...prev, published: false }));
      })
      .catch((error) => console.log(error));
  };

  const handleRemoveMcqOption = async (optionId, index) => {
    if (optionId) {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this item!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          let payload = {
            user_id: UserData?.data?.user?.id,
            course: cid,
            is_correct: true,
            option: optionId,
          };
          setDisable(true);
          //remove mcq option
          let response = await POST(
            `${Constants.updateBlock}/${blockidRef.current}/remove_option`,
            payload,
          );
          setoptions(response.data);
          setNewOptions(response?.data?.mcqs || []);
          setDisable(false);
          //setBlockToBeAdded((prev) => ({ ...prev, ...response?.data }));
          setCourseOne((prev) => ({ ...prev, published: false }));
          swal("Option Removed Successfully", {
            icon: "success",
          });
        } else {
          swal("The item was not deleted.", {
            icon: "error",
          });
        }
      });
    } else {
      const updatedOptions = newoptions.filter((_, i) => i !== index);
      setNewOptions(updatedOptions);
    }
  };

  const addOption = () => {
    setNewOptions([...newoptions, { text: inputValue.optionText, image: null, isCorrect: false }]);
    setInputValue({ ...inputValue, optionText: "" });
  };

  return (
    <div className="mcqBlock">
      <SourceTextSwitcher
        checked={checked}
        handleChange={(event) => setChecked(event.target.checked)}
        srcText={blockDetails?.srcText}
        srcTitleText={blockDetails?.srcTitleText}
      />
      <FormControl
        disabled={!assessmentSettings?.isAdvanceAssessment}
        sx={{ paddingBottom: "10px", width: "100%" }}
        variant="standard"
      >
        <Dropdown
          onChange={(event) => setAssessment(event.target.value)}
          value={assessment}
          renderValue={(selected) => {
            if (selected?.length === 0) {
              return <span className="dropdownLabel">Select a Domain</span>;
            } else {
              let selectedDomain = assessmentSettings?.assessmentDomain?.find(
                (domain) => domain?._id === selected,
              );
              return <span>{selectedDomain?.title}</span>;
            }
          }}
        >
          {assessmentSettings?.assessmentDomain?.length > 0 &&
            assessmentSettings?.assessmentDomain?.map((item, i) => {
              return (
                <MenuItem value={item?._id} key={i}>
                  {item?.title}
                </MenuItem>
              );
            })}
        </Dropdown>
      </FormControl>
      <QuillEditor
        value={inputValue.title}
        placeholder="Enter Question"
        handleChange={(event) => setInputValue({ ...inputValue, title: event.target.value })}
      />{" "}
      <br />
      {checked && (
        <Box my={2}>
          <strong>Source Text:</strong> {blockDetails?.srcTitleText}
        </Box>
      )}
      <br />
      {/* {next && (
        <ButtonMain onClick={handleNext} className="buttonGreen" disabled={disable}>
          Next Step
        </ButtonMain>
      )}
      {!next && ( */}
      <FormControl fullWidth sx={{ mt: 2, mb: 4, minWidth: 120 }} className="mcqStyle">
        <label id="demo-simple-select-helper-label">MCQ Styles</label>
        <Dropdown
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          label="MCQ Style"
          value={inputValue?.mcq_style}
          onChange={(event) => setInputValue({ ...inputValue, mcq_style: event.target.value })}
        >
          <MenuItem value="Default Style">Default Style</MenuItem>
          <MenuItem value="Popular Style">Popular Style</MenuItem>
        </Dropdown>
      </FormControl>
      <Grid sx={{ mb: 2 }}>
        <Checkbox
          checked={inputValue?.is_multiple}
          inputProps={{ "aria-label": "controlled" }}
          color="primary"
          onClick={handleCheckboxChange}
        />
        <label className="checkboxLabel">Has more than one correct answer?</label>
      </Grid>
      <>
        {inputValue?.mcq_style === "Popular Style" ? (
          <FormControl fullWidth sx={{ mt: 2, mb: 4, minWidth: 220 }}>
            {newoptions.map((option, index) => (
              <Box key={index} sx={{ mb: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  <InputBox
                    variant="outlined"
                    label="Option Text"
                    value={option?.text}
                    disabled={option?._id}
                    sx={{ flex: 1, mr: 2 }}
                    onChange={(e) => {
                      const updatedOptions = [...newoptions];
                      updatedOptions[index].text = e.target.value;
                      setNewOptions(updatedOptions);
                      setInputValue({ ...inputValue, optionText: e.target.value });
                    }}
                  />

                  {option?.image && (
                    <img
                      src={`${cloudfrontUrl}${option?.image?.m}`} // Adjusted for CloudFront URL
                      alt="img"
                      height="70px"
                      width="100px"
                      style={{ marginLeft: "10px" }}
                    />
                  )}

                  <input
                    accept="image/*"
                    id={`image-upload-${index}`}
                    type="file"
                    onChange={(e) => handleFileChange(e, index)}
                    hidden
                    disabled={option?._id}
                  />
                  <Tooltip TransitionComponent={Zoom} title="Upload Image">
                    <label htmlFor={`image-upload-${index}`}>
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        sx={{ mr: 2, ml: 2 }}
                        disabled={option?._id}
                      >
                        <PhotoCamera />
                      </IconButton>
                    </label>
                  </Tooltip>

                  <Tooltip TransitionComponent={Zoom} title="Save Option">
                    <label>
                      <IconButton
                        color="primary"
                        size="large"
                        onClick={handleAddOption}
                        aria-label="Add Option"
                        disabled={option?._id}
                      >
                        <SaveIcon />
                      </IconButton>
                    </label>
                  </Tooltip>

                  <Tooltip TransitionComponent={Zoom} title="Remove Option">
                    <label>
                      <IconButton
                        color="primary"
                        aria-label="Remove"
                        size="large"
                        component="span"
                        onClick={() => handleRemoveMcqOption(option?._id, index)}
                        disabled={disable}
                      >
                        <CloseRounded />
                      </IconButton>
                    </label>
                  </Tooltip>
                  <Tooltip TransitionComponent={Zoom} title="Click to mark as correct answer">
                    <Checkbox
                      checked={option?.is_correct}
                      onClick={() => handleCorrectMcqOption(option?._id, option?.is_correct, index)}
                      name="isCorrect"
                      disabled={!option?._id}
                    />
                  </Tooltip>
                </Box>
              </Box>
            ))}
            <Link sx={{ cursor: "pointer" }} onClick={addOption}>
              {" "}
              Add Option{" "}
            </Link>
          </FormControl>
        ) : (
          <>
            <InputBox
              placeholder="Option Text"
              value={inputValue.optionText}
              onChange={(e) => setInputValue({ ...inputValue, optionText: e.target.value })}
            />{" "}
            <br />
            <FormControlLabel
              control={
                <Switch
                  checked={isImage}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Add Image"
            />
            {isImage && (
              <UploadImageBox
                title={"Option Image"}
                icon={false}
                initialImage={urlPath?.m}
                accept="image/*"
                onChange={handleFileChange}
              />
            )}
            <br />
            <ButtonMain className="buttonGreen" onClick={handleAddOption} disabled={disable}>
              Add Option
            </ButtonMain>{" "}
            <br /> <br />
            {/* <CheckboxInput
          label="Has more than one correct answer?"
          onChange={handleCheckboxChange}
          value={inputValue?.is_multiple}
        /> */}
            <div style={{ marginTop: "1rem" }}>
              <b>Options:</b>
              {options?.mcqs?.map((item) => (
                <Box
                  key={item?._id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    margin: "10px 0px",
                  }}
                >
                  <Box>
                    <Box sx={{ fontWeight: 600 }}>{item?.text}</Box>
                    {checked && (
                      <Box my={2}>
                        <strong>Source Text:</strong> {item?.srcText}
                      </Box>
                    )}
                  </Box>
                  <Box sx={{ fontWeight: 600 }}>
                    <Chip
                      label="Correct Option"
                      variant="outlined"
                      onClick={() => handleCorrectMcqOption(item?._id, item?.is_correct)}
                      disabled={disable}
                      className={` ${correctOption?.includes(item?._id) ? "blue" : ""}`}
                    />
                  </Box>
                  <Box sx={{ fontWeight: 600 }}>
                    <Chip
                      label="Remove"
                      variant="outlined"
                      disabled={disable}
                      onClick={() => handleRemoveMcqOption(item?._id)}
                    />
                  </Box>
                  <Box>
                    {item?.image && (
                      <img
                        src={"https://d1u3z33x3g234l.cloudfront.net" + item?.image?.m}
                        alt="img"
                        height="70px"
                        width="100px"
                      />
                    )}
                  </Box>
                </Box>
              ))}
            </div>
            <br />
          </>
        )}
        <Divider sx={{ borderColor: "black", margin: "10px 0px" }} />
        <CustomAccordion
          first={
            <Dropdown
              onChange={(event) => setInputValue({ ...inputValue, score: event.target.value })}
              value={inputValue.score}
            >
              {scoreLists?.map((item, index) => (
                <MenuItem value={item} key={index}>
                  {item}
                </MenuItem>
              ))}
            </Dropdown>
          }
          second={
            <Dropdown
              onChange={(event) => setInputValue({ ...inputValue, credit: event.target.value })}
              value={inputValue.credit}
            >
              {creditPoints?.map((item, index) => (
                <MenuItem value={item} key={index}>
                  {item}
                </MenuItem>
              ))}
            </Dropdown>
          }
          third={
            <CheckboxInput
              label="Highlight if responded incorrectlyinfo"
              onChange={(event) => setInputValue({ ...inputValue, checked: event.target.checked })}
              value={!inputValue.checked}
            />
          }
          fourth={
            <CheckboxInput
              label="Mark As Required"
              onChange={(event) =>
                setInputValue({
                  ...inputValue,
                  is_required: event.target.checked,
                })
              }
              value={!inputValue.is_required}
            />
          }
        />
        <Divider sx={{ borderColor: "black", margin: "10px 0px" }} />

        <ButtonMain
          className="buttonGreen"
          disabled={disableButn}
          onClick={async () => {
            const blockId = await handleNext(inputValue?.is_multiple); // Wait for handleNextStep to complete
            if (!blockId) return;

            if (type === "edit") {
              setCourseBlocks &&
                setCourseBlocks((prevBlocks) => {
                  const updatedBlocks = prevBlocks.map((prevBlock) => {
                    if (prevBlock._id === blockToBeAdded._id) {
                      return blockToBeAdded;
                    }
                    return prevBlock;
                  });
                  return updatedBlocks;
                });
            } else {
              if (blockModalOpen?.addBelow) {
                setCourseBlocks &&
                  setCourseBlocks((prev) => {
                    const updatedBlocks = prev.map((el) => {
                      if (el.order >= blockId?.order) {
                        el.order += 1;
                      }
                      return el;
                    });
                    const elIndex = updatedBlocks.findIndex(
                      (el) => el._id === blockModalOpen?.currentBlockId,
                    );
                    updatedBlocks.splice(elIndex + 1, 0, blockId);
                    return updatedBlocks;
                  });
              } else {
                setCourseBlocks &&
                  setCourseBlocks((prev) => {
                    prev.forEach((el) => {
                      el.order = el.order + 1;
                    });
                    return [blockId, ...prev];
                  });
              }
            }
            setblockModOpen && setblockModOpen(false);
            setmodalOpen && setmodalOpen((prev) => ({ ...prev, modalOpen: false }));
          }}
        >
          Save Block
        </ButtonMain>
      </>
      {/* )} */}
      <CustomizedSnackbars
        AlertText={errText}
        AlertType={alertType}
        open={open}
        onClose={() => setOpen(false)}
      />
      <ToastNotify ref={toastRef} duration={3000} />
    </div>
  );
};

export default AddMCQ;
