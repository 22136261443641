import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { GET, POST } from "../../config/Api";
import { Constants } from "../../config/Constants";

import "./ManagedCourses.scss";

import { ManagedCard } from "../Cards";
import NewCourseModal from "../courses/CourseModals/NewCourseModal";
import ManagedTabs from "../Tabs";
import AddMembersModal from "../AddMembersModal";
import { CourseDetailsSkeleton } from "../Loader/Loader";
import AssessmentDashboardModal from "../AssessmentDashboardModal";
import ButtonMain from "../ButtonMain";
import ConfirmDialog from "../ModalMain/ConfirmDialog";
import { ToastNotify } from "../SnackBar/ToastNotify";
import { HeaderText, ParagraphText } from "../Typography";
import { Container, Grid } from "@mui/material";
import { logoutAction } from "../../redux/action";
import NothingHere from "../NothingHere/NothingHere";

const ManagaedCourseLayout = () => {
  let UserData = useSelector((state) => state.userLogin.data);
  let rerenderBlocks = useSelector((state) => state.rerenderBlocks);
  let isLoggedIn = UserData?.isLoggedIn;

  const toastRef = useRef();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [draftData, setDraftData] = useState("");
  const [draftCount, setDraftCount] = useState(0);
  const [tabValue, setTabValue] = React.useState("moderator");
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState("");
  const [membersData, setMembersData] = useState({});
  const [dataEdited, setDataEdited] = useState(false);
  const [courseId, setCourseId] = useState("");
  const [showMembersModal, setShowMembersModal] = useState(false);
  const [showAssessmentModal, setShowAssessmentModal] = useState(false);
  const [reRenderList, setReRenderList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [archiveType, setArchiveType] = useState("");

  const handleTabChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(logoutAction());
      navigate("/");
    } else {
      setDataEdited(false);
      setLoading(true);
      //get moderator,facilitator,archived courses
      GET(
        `${Constants.courseManage}` +
          `${
            tabValue === "archived"
              ? "/archived/"
              : tabValue === "facilitator"
              ? "/facilitator/"
              : "/moderator/"
          }` +
          UserData?.user?.id,
      )
        .then((res) => {
          setLoading(false);
          setDraftCount(res.data.count);
          if (tabValue === "facilitator") {
            setDraftData(
              res?.data?.courses.map((item) => {
                const courseDetails = item.courseDetails[0];
                return {
                  _id: item._id,
                  courseId: item.courseId,
                  slug: courseDetails.slug,
                  title: courseDetails.title,
                };
              }),
            );
          } else {
            setDraftData(res.data.courses);
          }
        })
        .catch((err) => console.log(err));
    }
    return () => {
      // dispatch(rerenderBlocksAction(rerenderBlocks))
    };
  }, [dataEdited, tabValue, reRenderList, UserData, rerenderBlocks]);

  let tabs = [
    {
      value: "moderator",
      label: "I'm a Moderator",
    },
    {
      value: "facilitator",
      label: "I'm a Facilitator",
    },
    {
      value: "archived",
      label: "Archived",
    },
  ];
  const onConfirmArchive = () => {
    setLoading(true);
    let payload = {
      user_id: UserData?.user?.id,
    };
    //API to make archive course
    POST(`${Constants.courseManage}/${archiveType}/${courseId}`, payload)
      .then((res) => {
        setDialogOpen(false);
        setReRenderList(!reRenderList);
        setLoading(false);
        if (res?.data?.suceess) {
          toastRef.current.showToastMessage(res?.data?.message, "success");
        } else {
          toastRef.current.showToastMessage(res?.data?.message, "error");
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Container maxWidth="xl" sx={{ width: "97%", py: 3 }}>
      <Grid container spacing={2}>
        <Grid item sm={8} lg={8} alignItems="center">
          <HeaderText>Managed Courses</HeaderText>
          <ParagraphText>Working on a new course? Find it here.</ParagraphText>
        </Grid>
        <Grid item sm={4} lg={4} display="flex" alignItems={"flex-end"} justifyContent="end">
          {/* <ButtonMain className="newCourseBtn" onClick={() => setFSCommsModal(true)}>
            Create FSComms For Visiters
          </ButtonMain> */}
          {/* <ButtonMain className="newCourseBtn" onClick={() => setModalOpen(true)}>
            Create New Course
          </ButtonMain> */}
          <ButtonMain
            className="newCourseBtn"
            onClick={() => navigate("/review")}
            variant="primary-blue"
          >
            Review Learner Progress
          </ButtonMain>
        </Grid>
      </Grid>
      <ManagedTabs tabs={tabs} handleTabChange={handleTabChange} tabsValue={tabValue} />
      <div className="managedMain">
        {loading ? (
          <CourseDetailsSkeleton />
        ) : draftCount >= 1 ? (
          draftData?.map((data, i) => (
            <div className="noEnrolled" key={i}>
              <ManagedCard
                data={data}
                handleEdit={(data) => {
                  setEditModal(true);
                  setEditData(data);
                }}
                // handleCopy={() => {
                //   // setCopyModal(true);
                //   setCourseId(data._id);
                // }}
                handleMembers={(course_id) => {
                  setShowMembersModal(true);
                  setMembersData((prevState) => ({
                    ...prevState,
                    course_id: course_id,
                  }));
                }}
                handleAssessmentDashboard={() => {
                  setShowAssessmentModal(true);
                  setCourseId(data._id);
                }}
                setCourseId={setCourseId}
                handleArchiveUnarchive={(type, id) => {
                  setDialogOpen(true);
                  setArchiveType(type);
                  setCourseId(id);
                }}
                tabValue={tabValue}
              />
            </div>
          ))
        ) : (
          <NothingHere />
        )}
      </div>
      {editData && (
        <NewCourseModal
          modalOpen={editModal}
          modalClose={() => setEditModal(false)}
          setDataEdited={setDataEdited}
          data={editData}
        />
      )}

      {/* Add Members Modal Begins Here */}
      <AddMembersModal
        courseMembersProps={membersData}
        setReRenderList={setReRenderList}
        setMembersData={setMembersData}
        reRenderList={reRenderList}
        showMembersModal={showMembersModal}
        setShowMembersModal={setShowMembersModal}
      />
      {showAssessmentModal && (
        <AssessmentDashboardModal
          showAssessmentModal={showAssessmentModal}
          setShowAssessmentModal={setShowAssessmentModal}
          course_id={courseId}
        />
      )}
      {modalOpen && (
        <NewCourseModal
          modalOpen={modalOpen}
          modalClose={() => setModalOpen(false)}
          setDataEdited={setDataEdited}
        />
      )}
      <ConfirmDialog
        dialogOpen={dialogOpen}
        dialogClose={() => setDialogOpen(false)}
        dialogMessage={`Are you sure you want to ${archiveType} this course?`}
        onConfirm={onConfirmArchive}
        loading={loading}
      />
      <ToastNotify ref={toastRef} duration={2000} />
    </Container>
  );
};

export default ManagaedCourseLayout;
