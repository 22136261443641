import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Backdrop, Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";

import "./CourseCommonStyle.scss";

import { GET, POST } from "../../config/Api";
import { Constants, userRoles } from "../../config/Constants";
import { useQueryParams } from "../../utils";

import ButtonMain from "../ButtonMain";
import FMModal from "../ModalMain/CourseModals/FMModal";
import CustomizedSnackbars from "../SnackBar/CustomizedSnackbars";
import { useOutletContext } from "react-router-dom";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import CourseContainerViewV2 from "./ContainerView/CourseContainerViewV2";

const CourseContainer = () => {
  const courseDetails = useSelector((state) => state.savedCourseDetail);
  let assessmentData = courseDetails?.data?.assessmentDashboard?.moduleBlocksHighlight;
  const { cid, mid } = useQueryParams();

  // const [courseBlocks, setCourseBlocks] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [assessmentDashboard, setAssessmentDashboardData] = useState({});
  const [containerBlocksData, setContainerBlocksData] = useState([]);
  // const [completedModulesData, setCompletedModulesData] = useState();
  const [alertText, setalertText] = useState("");
  const [open, setOpen] = useState(false);
  const [alertType, setalertType] = useState("error");
  const [showButton, setshowButton] = useState(false);
  const [isHighlight, setisHighlight] = useState([]);
  const [confLoading, setConfLoading] = useState(false);
  const [confModal, setConfModal] = useState(false);
  let { isLoggedIn, user } = useSelector((state) => state.userLogin?.data) || {};
  const { completedModulesData, setCompletedModulesData, userDetails } = useOutletContext();
  // const { authStates, setAuthStates } = useContext(AuthContext);

  // useEffect(() => {
  //   if (!isLoggedIn) {
  //     window.location.replace(
  //       `${process.env.REACT_APP_AUTH_URL_FE}?referrer=${window.location.href}`,
  //     );
  //     // setAuthStates((prev) => ({
  //     //   ...prev,
  //     //   loginOpen: true,
  //     //   modalOpen: true,
  //     //   message: LoginMessage,
  //     //   forceUpdate: !authStates.forceUpdate,
  //     // }));
  //   }
  // }, [isLoggedIn, mid]);

  useEffect(() => {
    if (isLoggedIn) {
      (async () => {
        try {
          //save user last activity
          await POST(`${Constants.userActivity}?activity=moduleLoad`, {
            user,
          });
        } catch (error) {
          console.error("Error updating learner activity:", error.message);
        }
      })();
    }
  }, [isLoggedIn]);

  const handleMarkAsDone = async () => {
    const allBlocks = containerBlocksData
      .filter(
        (item) =>
          (item.typeOfBlock === "score" ||
            item.typeOfBlock === "attempt" ||
            item.type === "container") &&
          !item?.is_hidden,
      )
      .map((block) => {
        return {
          _id: block._id,
          score: block.score,
          assessmentType: block.assessmentType,
          type: block.type,
          typeOfBlock: block.typeOfBlock,
          is_required: block.is_required,
        };
      });

    const assessment = {
      isAdvanceAssessment: courseDetails?.data?.assessmentDashboard?.isAdvanceAssessment,
      checkModuleCorrectness: courseDetails?.data?.assessmentDashboard?.checkModuleCorrectness,
    };

    const formData = {
      course_id: cid,
      allBlocks: allBlocks,
      assessment: assessment,
    };

    setLoading(true);
    //Mark as completed API
    const res = await POST(`${Constants.markCompleted}/${mid}`, formData);
    if (res?.data?.success) {
      setLoading(false);
      modalClose(true);
      setshowButton(true);
      setCompletedModulesData((prev) => ({ ...prev, modules: res?.data?.doc?.modules }));
      //save user last activity
      await POST(`${Constants.userActivity}?activity=markAsDoneClicked`, {
        user: user,
      });
      // setCompletedModulesData(res?.data?.course);
      // window.location.reload();
    } else {
      if (res?.data?.incorrectBlocks?.length > 0 && assessmentData) {
        setisHighlight(res.data.incorrectBlocks);
      }
      setLoading(false);
      modalClose();
      setalertText(res?.data?.message);
      setalertType("error");
      setOpen(true);
    }
  };

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       let body = {};
  //       const response = await GET(`${Constants.getPublicCourseBlock}/${cid}`, body);
  //       setCourseBlocks(response?.data?.data);
  //     } catch (err) {
  //       navigate("/404");
  //     }
  //   })();
  // }, [cid, mid]);

  useEffect(() => {
    let findLockedModule = completedModulesData?.modules?.find(
      (item) => item?.module_id === mid && item?.status === "locked",
    );
    if (findLockedModule) {
      setshowButton(true);
    } else {
      setshowButton(false);
    }
  }, [completedModulesData, mid]);

  // useEffect(() => {
  //   isLoggedIn &&
  //     (async () => {
  //       try {
  //         const response = await GET(`${Constants.course}/activity/${cid}`);
  //         setCompletedModulesData(response?.data?.userCourseActivity);
  //       } catch (error) {
  //         navigate("/404");
  //       }
  //     })();
  // }, [navigate]);

  const modalClose = () => {
    setOpenModal(false);
  };

  const handleUnlockModule = async () => {
    try {
      setConfLoading(true);
      //Api to Unlock module
      await GET(`${Constants.course}/unlock-module/${cid}/${mid}`);
      setConfLoading(false);
      //save user last activity
      await POST(`${Constants.userActivity}?activity=unlockModule`, { user: user });
      // setshowButton(false);
      window.location.reload();
      setConfModal(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <CustomizedSnackbars
        AlertText={alertText}
        AlertType={alertType}
        open={open}
        onClose={() => setOpen(false)}
      />
      <CourseContainerViewV2
        courseDetails={courseDetails}
        // setAssessmentDashboardData={setAssessmentDashboardData}
        setContainerBlocksData={setContainerBlocksData}
        completedModulesData={completedModulesData}
        disableBlock={showButton}
        isHighlight={isHighlight}
        setisHighlight={setisHighlight}
      />
      {
        isLoggedIn &&
          // completedModulesData &&
          // completedModulesData?.modules?.findIndex(
          //   (el) => mid === el?.module_id
          // ) === -1 ? (

          (showButton ? (
            <div id="lock-container">
              <Box className="lock-icon-container">
                <LockIcon className="lock-icon" />
                <LockOpenIcon className="unlock-icon" />
              </Box>
              <Box>
                <Typography whiteSpace={"wrap"}>
                  This module has been attempted and locked. You can unlock it by clicking the
                  button below.
                </Typography>
              </Box>
              <Box mt={2}>
                <Button
                  variant="contained"
                  onClick={() => {
                    setConfModal(true);
                  }}
                  fullWidth
                  disabled={userDetails.role === userRoles.REVIEWER ? true : false}
                >
                  Unlock Module
                </Button>
              </Box>
            </div>
          ) : (
            // <ButtonMain className={"markAsDone"} onClick={() => {}}>
            //   Module Attempted
            // </ButtonMain>
            <ButtonMain
              className={"markAsDone"}
              onClick={() => setOpenModal(true)}
              disabled={userDetails.role === userRoles.REVIEWER ? true : false}
            >
              Mark as Done
            </ButtonMain>
          ))
        // : (
        //     <ButtonMain className={"module_attempted"}>
        //       Module Attempted
        //     </ButtonMain>
        //   )
        // ) : (
        //   ""
        // )
      }
      <FMModal
        modalOpen={openModal}
        modalClose={modalClose}
        modalHeader={"Note"}
        className="confirm_modal"
        headerTextColor={"header_text_black"}
        backgroundImage={{
          backgroundImage: "linear-gradient(#a1c4fd, #c2e9fb)",
        }}
      >
        {loading && (
          <Backdrop sx={{ color: "#fff", zIndex: 50 }} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Typography variant="h6">
          Your answers in this module will be locked once you press “Confirm”.
        </Typography>
        <Grid container spacing={2}>
          <Grid item>
            <ButtonMain
              className={"confirm_button relative"}
              onClick={handleMarkAsDone}
              disabled={loading}
            >
              Confirm
            </ButtonMain>
          </Grid>
          <Grid item>
            <ButtonMain className={"cancel_button"} onClick={modalClose}>
              Cancel
            </ButtonMain>
          </Grid>
        </Grid>
      </FMModal>
      {/* Unlock Module Modal */}
      <ConfirmModal
        headerText={"Note"}
        confirmText={"Your answers in this module will be unlocked once you press “Confirm”."}
        handleConfirm={handleUnlockModule}
        showConfirmButton={true}
        handleClose={() => {
          setConfModal(false);
        }}
        confLoading={confLoading}
        confModal={confModal}
      />
      {/* <FMModal
          modalOpen={confModal}
          modalClose={() => {
            setConfModal(false);
          }}
          modalHeader={"Note"}
          className="confirm_modal"
          headerTextColor={"header_text_black"}
          backgroundImage={{
            backgroundImage: "linear-gradient(#a1c4fd, #c2e9fb)",
          }}
        >
          {confModal && (
            <Backdrop sx={{ color: "#fff", zIndex: 50 }} open={confLoading}>
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          <Typography variant="h6">
            Your answers in this module will be unlocked once you press “Confirm”.
          </Typography>
          <Grid container spacing={2}>
            <Grid item>
              <ButtonMain
                className={"confirm_button relative"}
                onClick={handleUnlockModule}
                disabled={confLoading}
              >
                Confirm
              </ButtonMain>
            </Grid>
            <Grid item>
              <ButtonMain
                className={"cancel_button"}
                onClick={() => {
                  setConfModal(false);
                }}
              >
                Cancel
              </ButtonMain>
            </Grid>
          </Grid>
        </FMModal> */}
    </>
  );
};

export default CourseContainer;
