import { Paper } from "@mui/material";
import React from "react";
import { ParagraphText } from "../../components/Typography";
import ButtonMain from "../../components/ButtonMain";

const ReviewCourseCard = ({ courseTitle, onClick }) => {
  return (
    <Paper className="reviewCard">
      <ParagraphText className={"reviewCardheader"}>{courseTitle}</ParagraphText>
      <ButtonMain variant="tangerine" onClick={onClick}>
        Review Course
      </ButtonMain>
    </Paper>
  );
};

export default ReviewCourseCard;
