import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./quill.scss";

const QuillEditor = ({ value, handleChange, placeholder, disabled }) => {
  return (
    <div>
      <ReactQuill
        value={value}
        onChange={(content, delta, source, editor) => {
          handleChange({
            target: { name: "description", value: content },
          });
        }}
        className="quill-editor"
        placeholder={placeholder}
        readOnly={disabled}
        modules={{
          toolbar: [
            [{ header: "1" }, { header: "2" }, { font: [] }],
            [{ list: "ordered" }, { list: "bullet" }],
            ["bold", "italic", "underline", "strikethrough"],
            ["image", "video"],
            ["align", "direction"],
            ["color", "background"],
            ["blockquote", "code-block"],
            ["link", "image", "video", "table"],
            ["clean"],
          ],
        }}
      />
    </div>
  );
};

export default QuillEditor;
