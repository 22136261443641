import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { cloudfrontUrl } from "../../../../config/BaseUrl";
import BlockActions from "../BlockActions/BlockActions";
import "./ImageView.scss";
import ArtView from "../ArtView/ArtView";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "right",
  justifyContent: "right",
  color: theme.palette.common.white,
}));
const ImageView = ({
  image,
  theme,
  courseDetails,
  block,
  courseDetail,
  courseOne,
  setmodalOpen,
  setblockModOpen,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [hidden, setHidden] = useState(block?.is_hidden);

  let { isLoggedIn, user } = useSelector((state) => state.userLogin?.data) || {};
  let isModerator =
    courseDetails?.creator === user?.id ||
    courseDetails?.members?.findIndex((item) => item?.user === user?.id);

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  return (
    <>
      {(isModerator === -1 || isModerator === undefined) && block?.is_hidden ? (
        ""
      ) : (
        <div className="image-block">
          <div
            className={` image-view 
         
      `}
            // style={{
            //   background: `${theme?.theme_type === "color" ? theme?.color : ""}`,
            // }}
            onMouseOver={handleHover}
            onMouseLeave={handleMouseLeave}
          >
            {isModerator !== -1 && isLoggedIn && isHovering && (
              <BlockActions
                blockId={image?._id}
                block={block}
                setmodalOpen={setmodalOpen}
                setblockModOpen={setblockModOpen}
                hidden={hidden}
                setHidden={setHidden}
              />
            )}
            {isModerator !== -1 && block?.carousel_container && (
              <Image>
                <Tooltip title="This Block Tagged to Carousel Block">
                  <LocalOfferRoundedIcon fontSize="large" color="primary" />
                </Tooltip>
              </Image>
            )}
            <ArtView art={(block || image)?.art} />
            <img
              src={image?.l?.startsWith(cloudfrontUrl) ? image?.l : cloudfrontUrl + image?.l}
              alt="images"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ImageView;
