import { Chip } from "@mui/material";
import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import BlockActions from "../BlockActions/BlockActions";
import "./ToggleView.scss";
import ArtView from "../ArtView/ArtView";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "right",
  justifyContent: "right",
  color: theme.palette.common.white,
}));
const ToggleList = ({
  toggle_list,
  blockId,
  theme,
  isRemove,
  setdata,
  courseDetails,
  courseDetail,
  block,
  type,
  setmodalOpen,
  setblockModOpen,
}) => {
  let { isLoggedIn, user } = useSelector((state) => state.userLogin?.data) || {};

  const [show, setShow] = useState([]);
  const [isHovering, setIsHovering] = useState(false);
  const [hidden, setHidden] = useState(block?.is_hidden);
  const toggle = (a, i) => {
    const arr = a.includes(i) ? a.filter((item) => !item) : [i];
    setShow(arr);
  };
  const handleRemoveItem = (i) => {
    setdata(toggle_list.filter((item) => i !== item));
  };

  let isModerator =
    courseDetails?.creator === user?.id ||
    courseDetails?.members?.findIndex((item) => item?.user === user?.id);

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  return (
    <>
      {(isModerator === -1 || isModerator === undefined) && block?.is_hidden ? (
        ""
      ) : (
        <>
          {toggle_list.length !== 0 && (
            <div
              id="toggle-block"
              onMouseOver={handleHover}
              onMouseLeave={handleMouseLeave}
              className={` 
       
      ${block?.theme ? block?.theme : "postBgColor"}
    `}
              style={{
                background: `${block?.theme_type === "color" ? block?.color : ""}`,
              }}
            >
              {block?.art?.m && <ArtView art={block?.art} />}
              {isModerator !== -1 && isLoggedIn && isHovering && (
                <BlockActions
                  blockId={blockId}
                  block={block}
                  setmodalOpen={setmodalOpen}
                  setblockModOpen={setblockModOpen}
                  hidden={hidden}
                  setHidden={setHidden}
                  style={{ display: `${type ? "none" : ""}` }}
                />
              )}

              <div className="one-item one-toggle-item">
                {isModerator !== -1 && block?.carousel_container && (
                  <Image>
                    <Tooltip title="This Block Tagged to Carousel Block">
                      <LocalOfferRoundedIcon fontSize="large" color="primary" />
                    </Tooltip>
                  </Image>
                )}

                {toggle_list?.map((i) => (
                  <Fragment key={i?._id}>
                    <div className={`item-title ${isRemove && "isRemove"}`}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => toggle(show, i?._id || i)}
                      >
                        {show.includes(i?._id || i) ? (
                          <KeyboardArrowDownIcon sx={{ cursor: "pointer" }} />
                        ) : (
                          <KeyboardArrowRightIcon sx={{ cursor: "pointer" }} />
                        )}{" "}
                        &nbsp;&nbsp;
                        <h4>{i.title}</h4>
                      </div>
                      {isRemove && <Chip label="Remove" onClick={() => handleRemoveItem(i)} />}
                    </div>
                    {show.includes(i?._id || i) && (
                      <div className="item-text" dangerouslySetInnerHTML={{ __html: i.text }}></div>
                    )}
                  </Fragment>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
export default ToggleList;
