import React from "react";
import { useState } from "react";
import { cloudfrontUrl } from "../../../../config/BaseUrl";
import BlockActions from "../BlockActions/BlockActions";
import "./DividerView.scss";
import FMModal from "../../../ModalMain/CourseModals/FMModal";
import Breathing from "./Breathing";
import Water from "./Water";
import Wave from "./Wave";
import { useSelector } from "react-redux";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "right",
  justifyContent: "right",
  color: theme.palette.common.white,
}));

const DividerView = ({
  divider,
  theme,
  courseDetails,
  courseDetail,
  courseOne,
  setmodalOpen,
  setblockModOpen,
  block,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [hidden, setHidden] = useState(block?.is_hidden);
  const [open, setOpen] = useState(false);
  let { isLoggedIn, user } = useSelector((state) => state.userLogin?.data) || {};
  let isModerator =
    courseDetails?.creator === user?.id ||
    courseDetails?.members?.findIndex((item) => item?.user === user?.id);

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  return (
    <>
      {(isModerator === -1 || isModerator === undefined) && block?.is_hidden ? (
        ""
      ) : (
        <div className="divider-block" onMouseOver={handleHover} onMouseLeave={handleMouseLeave}>
          {isModerator !== -1 && isLoggedIn && isHovering && (
            <BlockActions
              blockId={divider?._id}
              block={divider}
              setmodalOpen={setmodalOpen}
              setblockModOpen={setblockModOpen}
              hidden={hidden}
              setHidden={setHidden}
            />
          )}
          {isModerator !== -1 && block?.carousel_container && (
            <Image>
              <Tooltip title="This Block Tagged to Carousel Block">
                <LocalOfferRoundedIcon fontSize="large" color="primary" />
              </Tooltip>
            </Image>
          )}
          {divider.text === "" ? (
            <div className="divider-area">
              <hr />
              {divider.type === "text" && (
                <div className="divider-content">
                  <p className="divider-text">{divider.text}</p>
                </div>
              )}
            </div>
          ) : (
            <div className="divider-area">
              <div className="divider-content">
                {(divider.text !== "" || divider.text !== null || divider.text !== undefined) && (
                  <>
                    <p className="divider-text2">
                      <span className={`divider-content-text ${theme}`}>
                        <div> {divider.text}</div>
                        <div>
                          {divider?.divider?.type === "animation" && (
                            <p
                              className="divider-btn"
                              onClick={() => {
                                setOpen(true);
                              }}
                            >
                              Play animation
                            </p>
                          )}
                          {divider?.divider?.type === "music" && (
                            <div className="audio-block">
                              <audio
                                src={`${cloudfrontUrl}/music/${divider?.divider?.name?.toLowerCase()}.mp3`}
                                preload="auto"
                                controls
                              ></audio>
                            </div>
                          )}
                        </div>
                      </span>
                    </p>
                  </>
                )}
                <p className="divider-line"></p>
              </div>
            </div>
          )}

          <FMModal
            modalOpen={open}
            modalClose={() => {
              setOpen(false);
            }}
            modalHeader="Play Animation"
            className="animation-modal"
            headerTextColor={"header_text_black"}
            backgroundImage={{
              backgroundImage: "linear-gradient(90deg, #E3EEFF, #F3E7E9)",
            }}
          >
            {divider?.divider?.name === "Wave" && <Wave />}
            {divider?.divider?.name === "Breathing" && <Breathing />}
            {divider?.divider?.name === "Water" && <Water />}
          </FMModal>
        </div>
      )}
    </>
  );
};

export default DividerView;
