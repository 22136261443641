import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import { GET } from "../../../config/Api";
import { Constants } from "../../../config/Constants";
import { ParagraphText } from "../../Typography";
import "./CourseContainerView.scss";
import CarosalBlockView from "../courseViews/CarosalBlockView/CarosalBlockView";
import { AudioView } from "../courseViews/AudioView/AudioView";
import ButtonView from "../courseViews/ButtonView/ButtonView";
import ComicView from "../courseViews/ComicView/ComicView";
// import CommentView from "../courseViews/CommentView/CommentView";
import ContainerView from "../courseViews/ContainerView/ContainerView";
import DiscussionView from "../courseViews/DiscussionView/DiscussionView";
import DividerView from "../courseViews/DividerView/DividerView";
import EmbedView from "../courseViews/EmbedView/EmbedView";
import GifView from "../courseViews/GifView/GifView";
import ImageView from "../courseViews/ImageView/ImageView";
import LinkView from "../courseViews/LinkView/LinkView";
import MCQView from "../courseViews/MCQView/MCQView";
import TextViews from "../courseViews/TextView/TextView";
import ToggleList from "../courseViews/ToggleListView/ToggleList";
import { VideoView } from "../courseViews/VideoView/VideoView";
import { FileView } from "../courseViews/FileView/FileView";
import FillView from "../courseViews/FillView/FillView";
import MatchView from "../courseViews/MatchView/MatchView";
import ResponseView from "../courseViews/ResponseView/ResponseView";
import GridView from "../courseViews/GridView/GridView";
import ListView from "../courseViews/ListView/ListView";
import { Box, Button, Grid } from "@mui/material";
import { CourseDetailsSkeleton } from "../../Loader/Loader";
import KeywordsView from "../../Keywords/KeywordsView";
import { getS3ApiJsonKey, useQueryParams } from "../../../utils";
import NothingHere from "../../NothingHere/NothingHere";
import CreateBlockModal from "../CreateBlockModal/CreateBlockModal";
import { saveModuleDetailAction } from "../../../redux/action";
import { GET_BREADCRUMB, POP_ONE_BREADCRUMB, PUSH_BREADCRUMB } from "../../../redux/constants";
import NativeEmbedView from "../courseViews/NativeEmbedView/NativeEmbedView";
import ScormView from "../courseViews/ScormView/ScormView";

const CourseContainerViewV2 = ({
  courseDetails,
  setContainerBlocksData,
  completedModulesData,
  disableBlock,
  isHighlight,
  setisHighlight,
}) => {
  const [containerDetails, setContainerDetails] = useState([]);
  const [blockUserActivities, setBlockUserActivities] = useState([]);
  const [moderator, setModerator] = useState(false);

  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // const [isHighlight, setisHighlight] = useState([]);

  const breadcrumbData = useSelector((state) => state.breadcrumb);
  const { courseOne, userDetails } = useOutletContext();
  const moduleDetails = useSelector((state) => state.savedModuleDetail);
  let UserData = useSelector((state) => state.userLogin);
  let isLoggedIn = UserData?.data?.accessToken;
  const { cid, mid } = useQueryParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [highlightedContainers, setHighlightedContainers] = useState([]);

  useEffect(() => {
    if (mid) {
      (async () => {
        try {
          setLoading(true);
          // start
          const url = `${getS3ApiJsonKey}/course/${cid}/mod-${mid}.json`;

          const response = await fetch(url);

          if (response.status === 200) {
            const data = await response.json();
            setContainerDetails(data);
            setContainerBlocksData(data);
          } else {
            //get course container details
            const response = await GET(`${Constants.getPublicCourseContainer}/${mid}/${cid}`);
            setContainerDetails(JSON.parse(response?.data?.data));
            setContainerBlocksData(JSON.parse(response?.data?.data));
          }

          // ends
          setLoading(false);
        } catch (error) {
          navigate("/404");
        }
      })();
      (async () => {
        try {
          //get container details
          const response = await GET(`${Constants.getPublicCourseBlock}/singleBlock/${mid}`);

          let moduleDetails = {
            containerTitle: response?.data?.data?.title,
            containerText: response?.data?.data?.text,
            id: response?.data?.data?._id,
            slug: response?.data?.data?.slug,
          };
          dispatch(saveModuleDetailAction(moduleDetails));
        } catch (error) {
          navigate("/404");
        }
      })();
    }
    courseOne?._id && isLoggedIn && checkCourseModerator();
  }, [mid]);

  useEffect(() => {
    dispatch({ type: GET_BREADCRUMB });
    if (
      moduleDetails &&
      Object.keys(moduleDetails).length > 0 &&
      !breadcrumbData.some((item) => item.title === moduleDetails?.data?.containerTitle)
    ) {
      const newBreadCrumb = {
        title: moduleDetails?.data?.containerTitle,
        navigate: `/course/${courseDetails?.data?.slug}/${
          moduleDetails?.data?.slug
        }?cid=${cid}&mid=${moduleDetails?.data?.id || mid}`,
      };
      dispatch({ type: PUSH_BREADCRUMB, payload: newBreadCrumb });
    }
  }, [moduleDetails]);

  useEffect(() => {
    window.onpopstate = () => {
      dispatch({ type: POP_ONE_BREADCRUMB });
    };
  }, []);

  useEffect(() => {
    isLoggedIn &&
      (async () => {
        try {
          //get block user activity
          const response = await GET(
            `${Constants.getPublicCourseBlock}/blockUserActivity/${cid}/${userDetails.user_id}`,
          );
          setBlockUserActivities(response?.data);
        } catch (error) {
          // navigate("/404");
          console.log(error);
        }
      })();
  }, [cid, mid, containerDetails]);

  useEffect(() => {
    if (
      completedModulesData?.evaluationChecked === 1 &&
      courseDetails?.data?.assessmentDashboard?.blocksHighlight
    ) {
      blockUserActivities?.data?.map(
        (item) =>
          item?.isHighlight &&
          setisHighlight &&
          setisHighlight((prevState) => [...prevState, item.block_id]),
      );
    }
  }, [completedModulesData, mid, courseDetails]);

  const checkCourseModerator = async () => {
    try {
      //check moderator
      const response = await GET(`${Constants.course}/checkModerator/${cid}`);
      if (response?.data?.success) {
        setModerator(true);
      }
    } catch (error) {
      // navigate("/404");
    }
  };

  const highlightParentContainers = (container) => {
    const parentContainerIds = container?.parentContainers || [];
    const highlighted = [];
    console.log(container, highlighted);

    parentContainerIds.forEach((parentId) => {
      const foundModule = completedModulesData.modules.find(
        (module) => module.module_id === parentId,
      );
      if ((foundModule && foundModule.status !== "locked") || !foundModule) {
        highlighted.push(parentId);
      }
    });

    setHighlightedContainers(highlighted);
  };
  const filteredBlocks = containerDetails.filter((item) => !item.carousel_container);

  return (
    <div className="course-container">
      <div className="breadcrumb-module-container">
        <h2>{moduleDetails?.data?.containerTitle || containerDetails?.[0]?.container?.title}</h2>
      </div>
      <ParagraphText>
        <div
          dangerouslySetInnerHTML={{
            __html:
              moduleDetails?.data?.containerText ||
              containerDetails.find((el) => el.type === "container")?.text,
          }}
        ></div>
      </ParagraphText>

      <KeywordsView keywords={courseDetails?.data || courseOne} />
      {moderator && (
        <Box mb={2} display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
          <Button
            variant="contained"
            sx={{ mx: 2 }}
            onClick={(e) => {
              e.stopPropagation();
              window.open(`/course/createblock/createcontainer?cid=${cid}&mid=${mid}`, "_blank");
            }}
          >
            Switch to Editor View
          </Button>
        </Box>
      )}
      {/* {disableBlock && (
        
      )} */}
      {loading ? (
        <CourseDetailsSkeleton />
      ) : containerDetails.length > 0 ? (
        <>
          <Grid container spacing={3} columns={100}>
            {filteredBlocks?.map((item, index) => (
              <React.Fragment key={item._id}>
                {item?.type === "text" && (
                  <Grid item className="fullwidth" spacing={3} md={item?.size?.width}>
                    <TextViews
                      block={item}
                      theme={item?.theme}
                      art={item?.art}
                      courseDetails={courseDetails.data || courseOne}
                    />
                    {/* {item?.comments?.length !== 0 && (
                      <CommentView comments={item?.comments} />
                    )} */}
                  </Grid>
                )}
                {item?.type === "button" && (
                  <Grid item className="fullwidth" md={item?.size?.width}>
                    <ButtonView
                      key={item}
                      button={item}
                      courseDetails={courseDetails.data || courseOne}
                      block={item}
                    />
                    {/* {item?.comments?.length !== 0 && (
                      <CommentView comments={item?.comments} />
                    )} */}
                  </Grid>
                )}
                {item?.type === "divider" && (
                  <Grid item className="fullwidth" md={item?.size?.width}>
                    <DividerView
                      key={item}
                      divider={item}
                      theme={isLoggedIn ? "light" : "dark"}
                      courseDetails={courseDetails.data || courseOne}
                      block={item}
                    />
                    {/* {item?.comments?.length !== 0 && (
                      <CommentView comments={item?.comments} />
                    )} */}
                  </Grid>
                )}
                {item?.type === "toggle_list" && item?.items?.length > 0 ? (
                  <Grid item spacing={3} className="fullwidth" md={item?.size?.width}>
                    <ToggleList
                      toggle_list={item?.items}
                      theme={item.theme}
                      block={item}
                      courseDetails={courseDetails.data || courseOne}
                    />
                  </Grid>
                ) : (
                  ""
                )}
                {item?.type === "image" && (
                  <Grid item className="fullwidth" md={item?.size?.width}>
                    <ImageView
                      image={item?.image}
                      key={item?.image}
                      theme={item.theme}
                      block={item}
                      courseDetails={courseDetails.data || courseOne}
                    />
                    {/* {item?.comments?.length !== 0 && (
                      <CommentView comments={item?.comments} />
                    )} */}
                  </Grid>
                )}
                {item?.type === "audio" && (
                  <Grid item className="fullwidth" spacing={3} md={item?.size?.width}>
                    <AudioView
                      audio={item?.provider}
                      key={item?.provider}
                      block={item}
                      courseDetails={courseDetails.data || courseOne}
                    />
                  </Grid>
                )}
                {item?.type === "video" && (
                  <Grid item className="fullwidth" md={item?.size?.width}>
                    <VideoView
                      video={item}
                      courseDetails={courseDetails.data || courseOne}
                      block={item}
                    />
                    {/* {item?.comments?.length !== 0 && (
                      <CommentView comments={item?.comments} />
                    )} */}
                  </Grid>
                )}
                {item?.type === "file" && (
                  <Grid item className="fullwidth" md={item?.size?.width}>
                    <FileView
                      file={item?.file}
                      data={item}
                      courseDetails={courseDetails.data || courseOne}
                      block={item}
                    />
                    {/* {item?.comments?.length !== 0 && (
                      <CommentView comments={item?.comments} />
                    )} */}
                  </Grid>
                )}
                {item?.type === "link" && (
                  <Grid item className="fullwidth" spacing={3} md={item?.size?.width}>
                    <LinkView
                      link={item}
                      courseDetails={courseDetails.data || courseOne}
                      block={item}
                    />
                    {/* {item?.comments?.length !== 0 && (
                      <CommentView comments={item?.comments} />
                    )} */}
                  </Grid>
                )}
                {item?.type === "gif" && (
                  <Grid item className="fullwidth" spacing={3} md={item?.size?.width}>
                    <GifView
                      gif={item?.gif}
                      block={item}
                      courseDetails={courseDetails.data || courseOne}
                    />
                  </Grid>
                )}
                {item?.type === "mcq" && (
                  <Grid item className="fullwidth" md={item?.size?.width}>
                    <MCQView
                      mcq={item}
                      mcqs={item.mcqs}
                      blockUserActivities={blockUserActivities}
                      disableBlock={disableBlock}
                      courseDetails={courseDetails.data || courseOne}
                      block={item}
                      isHighlight={isHighlight.some((block) => block === item._id)}
                      setisHighlight={setisHighlight}
                    />
                    {/* {item?.comments?.length !== 0 && (
                      <CommentView comments={item?.comments} />
                    )} */}
                  </Grid>
                )}
                {item?.type === "scorm" && (
                  <Grid item className="fullwidth" md={item?.size?.width}>
                    <ScormView
                      scorm={item}
                      blockUserActivities={blockUserActivities}
                      courseDetails={courseDetails.data || courseOne}
                      block={item}
                    />
                  </Grid>
                )}
                {item?.type === "fill" && (
                  <Grid item className="fullwidth" md={item?.size?.width}>
                    <FillView
                      fill={item}
                      blockUserActivities={blockUserActivities}
                      courseDetails={courseDetails.data || courseOne}
                      block={item}
                      disableBlock={disableBlock}
                      isHighlight={isHighlight.some((block) => block === item._id)}
                    />
                    {/* {item?.comments?.length !== 0 && (
                      <CommentView comments={item?.comments} />
                    )} */}
                  </Grid>
                )}
                {item?.type === "match" && (
                  <Grid item className="fullwidth" md={item?.size?.width}>
                    <MatchView
                      match={item}
                      blockUserActivities={blockUserActivities}
                      courseDetails={courseDetails.data || courseOne}
                      block={item}
                      disableBlock={disableBlock}
                    />
                    {/* {item?.comments?.length !== 0 && (
                      <CommentView comments={item?.comments} />
                    )} */}
                  </Grid>
                )}
                {item?.type === "grid" && (
                  <Grid item className="fullwidth" md={item?.size?.width}>
                    <GridView
                      grid={item}
                      courseDetails={courseDetails.data || courseOne}
                      block={item}
                    />
                    {/* {item?.comments?.length !== 0 && (
                      <CommentView comments={item?.comments} />
                    )} */}
                  </Grid>
                )}
                {item?.type === "response" && (
                  <Grid item className="fullwidth" md={item?.size?.width}>
                    <ResponseView
                      response={item}
                      art={item?.art}
                      blockUserActivities={blockUserActivities}
                      courseDetails={courseDetails.data || courseOne}
                      block={item}
                      disableBlock={disableBlock}
                      isHighlight={isHighlight.some((block) => block === item._id)}
                      setisHighlight={setisHighlight}
                    />
                    {/* {item?.comments?.length !== 0 && (
                      <CommentView comments={item?.comments} />
                    )} */}
                  </Grid>
                )}
                {item?.type === "list" && (
                  <Grid item className="fullwidth" md={item?.size?.width}>
                    <ListView
                      list={item}
                      courseDetails={courseDetails.data || courseOne}
                      block={item}
                    />
                    {/* {item?.comments?.length !== 0 && (
                      <CommentView comments={item?.comments} />
                    )} */}
                  </Grid>
                )}
                {item?.type === "embed" && (
                  <Grid item className="fullwidth" md={item?.size?.width}>
                    <EmbedView embed={item} courseDetails={courseDetails.data || courseOne} />
                    {/* {item?.comments?.length !== 0 && (
                      <CommentView comments={item?.comments} />
                    )} */}
                  </Grid>
                )}
                {item?.type === "native-embed" && (
                  <Grid item className="fullwidth" md={item?.size?.width}>
                    <NativeEmbedView
                      block={item}
                      courseDetails={courseDetails.data || courseOne}
                      setblockModOpen={setBlockModalOpen}
                    />
                  </Grid>
                )}
                {/* {item?.comments.length !== 0 && (
                  <Grid
                    item
                    className="fullwidth"
                    spacing={3}
                    lg={item?.size?.width}
                  >
                    <CommentView comments={item.comments} block={item} />
                  </Grid>
                )} */}
                {item?.type === "comic" && (
                  <Grid item className="fullwidth" spacing={3} md={item?.size?.width}>
                    <ComicView
                      text={item.text}
                      image={item.image}
                      block={item}
                      courseDetails={courseDetails.data || courseOne}
                    />
                  </Grid>
                )}
                {item?.type === "container" && (
                  <Grid item className="fullwidth" md={item?.size?.width}>
                    <ContainerView
                      container={item}
                      completedModulesData={completedModulesData}
                      courseDetails={courseDetails.data || courseOne}
                      block={item}
                      ishighlighted={highlightedContainers.includes(item?._id)}
                      highlightParentContainers={highlightParentContainers}
                    />
                    {/* {item?.comments?.length !== 0 && (
                      <CommentView comments={item?.comments} />
                    )} */}
                  </Grid>
                )}

                {item?.type === "carousel_container" && (
                  <Grid item className="fullwidth" lg={item?.size?.width}>
                    <CarosalBlockView
                      carosalContainer={item}
                      courseDetails={courseDetails.data || courseOne}
                      block={item}
                      isHighlight={isHighlight}
                      setisHighlight={setisHighlight}
                    />
                  </Grid>
                )}

                {item?.type === "discussion" && (
                  <Grid item className="fullwidth" md={item?.size?.width}>
                    <DiscussionView
                      discussion={item}
                      courseDetails={courseDetails.data || courseOne}
                      block={item}
                    />
                    {/* {item?.comments?.length !== 0 && (
                      <CommentView comments={item?.comments} />
                    )} */}
                  </Grid>
                )}
              </React.Fragment>
            ))}
          </Grid>
        </>
      ) : (
        <NothingHere />
      )}
      <CreateBlockModal
        modalOpen={blockModalOpen}
        setblockModOpen={setBlockModalOpen}
        courseDetails={courseDetails}
        cid={cid}
      />
    </div>
  );
};

export default CourseContainerViewV2;
