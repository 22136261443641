import React, { useImperativeHandle, useState , forwardRef} from "react";
import { Snackbar } from "@mui/material";
import { Stack } from "@mui/system";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const ToastNotify = forwardRef((props, ref) => {
    const [showNotify, setShowNotify] = useState(false);
    const [showText, setShowText] = useState("");
    const [type, setType] = useState("");

    useImperativeHandle(ref, () => ({
      showToastMessage(message, type) {
        setShowNotify(true);
        setShowText(message);
        setType(type);
      },
    }));
  
    return (
      <>
        {showNotify && (
          <Stack spacing={2} sx={{ width: "100%" }}>
            <Snackbar
              open={showNotify}
              autoHideDuration={props.duration}
              onClose={() => {
                setShowNotify(false);
              }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Alert
                onClose={() => {
                  setShowNotify(false);
                }}
                severity={type}
                sx={{
                  width: "100%",
                }}
              >
                {showText}
              </Alert>
            </Snackbar>
          </Stack>
        )}
      </>
    );
  });
