export const SAVE_COURSE_DETAIL_SUCCESS = "SAVE_COURSE_DETAIL_SUCCESS";
export const SAVE_COURSE_DETAIL_FAIL = "SAVE_COURSE_DETAIL_FAIL";
export const SAVE_COURSE_DETAIL_RESET = "SAVE_COURSE_DETAIL_RESET";

export const SAVE_MODULE_DETAIL_SUCCESS = "SAVE_MODULE_DETAIL_SUCCESS";
export const SAVE_MODULE_DETAIL_FAIL = "SAVE_MODULE_DETAIL_FAIL";
export const SAVE_MODULE_DETAIL_RESET = "SAVE_MODULE_DETAIL_RESET";

export const RENDER_BLOCKS = "RENDER_BLOCKS";

export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

export const NEW_USER_CLEANUP = "NEW_USER_CLEANUP";

export const PUSH_BREADCRUMB = "PUSH_BREADCRUMB";
export const POP_ONE_BREADCRUMB = "POP_ONE_BREADCRUMB";
export const POP_BREADCRUMB = "POP_BREADCRUMB";
export const GET_BREADCRUMB = "GET_BREADCRUMB";
export const RESET_BREADCRUMB = "RESET_BREADCRUMB";
