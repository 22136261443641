import { Box } from "@mui/system";
import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { Constants, themes } from "../../../config/Constants";
import ButtonMain from "../../ButtonMain";
import "./EditBlock.scss";
import { POST } from "../../../config/Api";
import { useQueryParams } from "../../../utils";
import { useSelector } from "react-redux";
import { FullScreenLoader } from "../../Loader/Loader";
import { FormControlLabel, Grid, Radio, TextField } from "@mui/material";
import FullScreenModal from "../CourseModals/FullScreenModal";
import ColorPallete from "./ColorPallete";
import { useOutletContext } from "react-router-dom";
import UploadImageBox from "../../Image/UploadImageBox";

const EditTheme = ({ themeModalOpen, setThemeModalOpen, block, setCourseBlocks }) => {
  // const [selectedFile, setSelectedFile] = useState(null);
  const [open, setOpen] = useState(false);

  const { cid, mid } = useQueryParams();

  const [formData, setFormData] = useState({
    width: block?.size?.width || 100,
    // margin: block?.size?.margin || 0,
    height: block?.size?.height || 1084,
    themeType: block?.theme_type || "theme",
    selectedTheme: block?.theme || "",
    selectedColor: block?.color || "",
    urlPath: block?.art || {},
  });
  const { setCourseOne } = useOutletContext();

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      width: block?.size?.width,
      // margin: block?.size?.margin,
      height: block?.size?.height,
      themeType: block?.theme_type || "theme",
      selectedTheme: block?.theme,
      selectedColor: block?.color,
      urlPath: block?.art,
    }));
  }, [block]);

  let UserData = useSelector((state) => state.userLogin);

  const handleFileChange = async (event) => {
    setOpen(true);
    const formData = new FormData();
    formData.append("image", event.target.files[0]);
    let header = "multipart/form-data";
    try {
      //upload image
      const response = await POST(`${Constants.addBlock}/uploadImage`, formData, header);
      setOpen(false);
      setFormData((prev) => ({ ...prev, urlPath: response?.data?.image }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectTheme = (theme) => {
    setFormData({ ...formData, selectedTheme: theme });
  };
  const handleSaveTheme = async () => {
    setOpen(true);
    let payload = {
      user_id: UserData?.data?.user?.id,
      course: cid,
      container: mid,
      image: formData.urlPath,
      width: formData.width,
      height: formData.height,
      //margin: formData.margin,
      theme_type: formData.themeType,
      theme: formData.selectedTheme,
      color: formData.selectedColor,
    };
    //edit theme on course blocks
    POST(`${Constants.editTheme}/${block?._id}`, payload)
      .then((response) => {
        setCourseOne((prev) => ({ ...prev, published: false }));
        setCourseBlocks &&
          setCourseBlocks((prev) => {
            const elIndex = prev.findIndex((el) => el._id === block?._id);
            prev.splice(elIndex, 1, response?.data?.data);
            prev.sort((a, b) => a?.order - b?.order);
            return prev;
          });
        setThemeModalOpen((prev) => ({ ...prev, themeModalOpen: false }));
        setOpen(false);
      })
      .catch((error) => console.log(error));
  };
  const handleRemoveArt = () => {
    setFormData({ ...formData, urlPath: {} });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, themeType: e.target.value });
  };

  return (
    <FullScreenModal
      open={themeModalOpen}
      setOpen={() => {
        setThemeModalOpen(false);
      }}
      dialogTitle={"Edit Appearance"}
    >
      <Grid container spacing={5} p={5}>
        <Grid item xs={12} md={6} sm={12}>
          {/* Left box content */}
          <Box className="leftBox1">
            <UploadImageBox
              title={"Add Illustration"}
              initialImage={formData?.urlPath?.m}
              className={"fileUpload"}
              accept="image/*"
              onChange={handleFileChange}
            />

            {/* <CustomInputFile
              title={selectedFile ? selectedFile.name.substring(0, 13) : "Add Illustration"}
              className="fileUpload"
              onChange={handleFileChange}
              accept="image/*"
            /> */}
            {block?.art?.m && (
              <ButtonMain className="buttonGreen" onClick={handleRemoveArt}>
                Remove Art
              </ButtonMain>
            )}
            <p>
              Note : Accepted file format JPEG, JPG, PNG, or GIF & file size must be equal or less
              than 500KB.
            </p>
            <br />
            <TextField
              label="Block width in percentage (default is 100)"
              variant="outlined"
              fullWidth
              autoComplete={false}
              value={formData.width}
              onChange={(e) => setFormData({ ...formData, width: e.target.value })}
            />
            <br />

            <br />

            {(block?.type === "embed" || block?.type === "native-embed") && (
              <>
                <TextField
                  label="Block height in pixels (default is 500px)"
                  variant="outlined"
                  fullWidth
                  autoComplete={false}
                  value={formData?.height}
                  onChange={(e) => {
                    setFormData({ ...formData, height: e.target.value });
                  }}
                  type="number"
                  inputRef={{ min: 1 }}
                />
              </>
            )}
            <br />

            <br />

            <ButtonMain className="buttonGreen" onClick={handleSaveTheme}>
              Save Theme
            </ButtonMain>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sm={12}>
          {/* Right box content */}
          <Box className="rightBoxContent">
            {formData.themeType === "color" && (
              <ColorPallete formData={formData} setFormData={setFormData} />
            )}
            {formData.themeType === "theme" && (
              <>
                {themes?.map((theme) => (
                  <Fragment key={theme}>
                    <div className={`themes ${theme}`} onClick={() => handleSelectTheme(theme)}>
                      {formData.selectedTheme === theme ? (
                        <div className="tickmark">&#10004;</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </Fragment>
                ))}
              </>
            )}
          </Box>
          <Grid container spacing={2}>
            <Grid item>
              <FormControlLabel
                control={
                  <Radio
                    checked={formData.themeType === "theme"}
                    onChange={handleChange}
                    value="theme"
                  />
                }
                label="Gradient colors"
                labelPlacement="end"
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Radio
                    checked={formData.themeType === "color"}
                    onChange={handleChange}
                    value="color"
                  />
                }
                label="Solid colors"
                labelPlacement="end"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <FullScreenLoader open={open} />
    </FullScreenModal>
  );
};
export default EditTheme;
