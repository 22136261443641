import {
  SAVE_COURSE_DETAIL_FAIL,
  SAVE_COURSE_DETAIL_RESET,
  SAVE_COURSE_DETAIL_SUCCESS,
  SAVE_MODULE_DETAIL_SUCCESS,
  SAVE_MODULE_DETAIL_FAIL,
  SAVE_MODULE_DETAIL_RESET,
  USER_LOGIN_FAIL,
  USER_LOGIN_SUCCESS,
  NEW_USER_CLEANUP,
  USER_LOGOUT,
  RENDER_BLOCKS,
  PUSH_BREADCRUMB,
  POP_BREADCRUMB,
  RESET_BREADCRUMB,
  GET_BREADCRUMB,
  POP_ONE_BREADCRUMB,
} from "./constants";

export const saveCourseDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case SAVE_COURSE_DETAIL_SUCCESS:
      return { data: action.payload };

    case SAVE_COURSE_DETAIL_FAIL:
      return { error: action.payload };

    case SAVE_COURSE_DETAIL_RESET:
      return {};

    default:
      return state;
  }
};
export const saveModuleDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case SAVE_MODULE_DETAIL_SUCCESS:
      return { data: action.payload };

    case SAVE_MODULE_DETAIL_FAIL:
      return { error: action.payload };

    case SAVE_MODULE_DETAIL_RESET:
      return {};
    default:
      return state;
  }
};
export const rerenderBlocksReducer = (state = { reRender: false }, action) => {
  switch (action.type) {
    case RENDER_BLOCKS:
      return {
        ...state,
        reRender: !action.payload,
      };

    default:
      return state;
  }
};

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_SUCCESS:
      return { data: action.payload };

    case USER_LOGIN_FAIL:
      return { error: action.payload };

    case NEW_USER_CLEANUP:
      return { data: action.payload };

    case USER_LOGOUT:
      return {
        newUser: false,
      };

    default:
      return state;
  }
};
const initialBreadCrumb = [{ title: "Home", navigate: "/" }];
export const breadcrumbReducer = (state = initialBreadCrumb, action) => {
  switch (action.type) {
    case PUSH_BREADCRUMB:
      const newState = [...state, action.payload];
      localStorage.setItem("breadcrumb", JSON.stringify(newState));
      return newState;
    case POP_ONE_BREADCRUMB:
      // Remove the last item from the array
      if (state.length > 1) {
        const updatedState = [...state.slice(0, -1)];
        localStorage.setItem("breadcrumb", JSON.stringify(updatedState));
        return updatedState;
      } else {
        localStorage.setItem("breadcrumb", JSON.stringify(state));
        return state;
      }
    case POP_BREADCRUMB:
      // Find the index of the clicked item
      const index = state.findIndex((item) => item.title === action.payload.title);
      if (index === -1) {
        // if item is not found, return the original state
        return state;
      } else {
        // Remove all items after the clicked item
        const updatedState = state.slice(0, index + 1);
        localStorage.setItem("breadcrumb", JSON.stringify(updatedState));
        return updatedState;
      }
    case GET_BREADCRUMB:
      let updateState = JSON.parse(localStorage.getItem("breadcrumb"));
      if (updateState?.length > 0) {
        return updateState;
      } else {
        return state;
      }
    case RESET_BREADCRUMB:
      return initialBreadCrumb;

    default:
      return state;
  }
};
