import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { cloudfrontUrl } from "../../../../config/BaseUrl";
import BlockActions from "../BlockActions/BlockActions";
import "./GridView.scss";
import ArtView from "../ArtView/ArtView";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "right",
  justifyContent: "right",
  color: theme.palette.common.white,
}));

const GridView = ({
  grid,
  courseDetails,
  courseDetail,
  courseOne,
  setmodalOpen,
  setblockModOpen,
  block,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [hidden, setHidden] = useState(block?.is_hidden);
  let { isLoggedIn, user } = useSelector((state) => state.userLogin?.data) || {};
  let isModerator =
    courseDetails?.creator === user?.id ||
    courseDetails?.members?.findIndex((item) => item?.user === user?.id);

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  return (
    <>
      {(isModerator === -1 || isModerator === undefined) && block?.is_hidden ? (
        ""
      ) : (
        <div
          className={` container-block grid-block 
      ${isLoggedIn ? `${grid?.theme} postBgColor` : `${grid?.theme} defaultStyle`}
    `}
          style={{
            background: `${grid?.theme_type === "color" ? grid?.color : ""}`,
          }}
          onMouseOver={handleHover}
          onMouseLeave={handleMouseLeave}
        >
          {isModerator !== -1 && isLoggedIn && isHovering && (
            <BlockActions
              blockId={grid?._id}
              block={grid}
              setmodalOpen={setmodalOpen}
              setblockModOpen={setblockModOpen}
              hidden={hidden}
              setHidden={setHidden}
            />
          )}

          {isModerator !== -1 && block?.carousel_container && (
            <Image>
              <Tooltip title="This Block Tagged to Carousel Block">
                <LocalOfferRoundedIcon fontSize="large" color="primary" />
              </Tooltip>
            </Image>
          )}
          <ArtView art={grid?.art} />
          <p className="block-title">{grid?.title}</p>
          {grid?.text && (
            <div className="block-text" dangerouslySetInnerHTML={{ __html: grid?.text }}></div>
          )}
          {grid?.items && (
            <div className="block-options">
              {grid?.items?.map((item, index) => (
                <React.Fragment key={index}>
                  {item?.image?.m && (
                    <div
                      className="block-image"
                      style={{
                        backgroundImage: `url(${
                          item?.image?.m?.startsWith(cloudfrontUrl)
                            ? item?.image?.m
                            : cloudfrontUrl + item?.image?.m
                        })`,
                        height: "150px",
                        width: "150px",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    ></div>
                  )}
                  {item?.text && (
                    <div className="block-details">
                      <div
                        className="block-text"
                        dangerouslySetInnerHTML={{ __html: item?.text }}
                      ></div>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default GridView;
