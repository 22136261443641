import React, { useState } from "react";
import { Button, Grid, Modal, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { InputBox } from "../InputBox/InputBox";
import { HeaderText, LabeledText, ParagraphText } from "../Typography";

import "./VerifyCertificate.scss";
import { POST } from "../../config/Api";
import { Constants } from "../../config/Constants";
import { FullScreenLoader } from "../Loader/Loader";

const VerifyCertificateModal = ({ verifyCertOpen, verifyClose }) => {
  const [verifyData, setVerifyData] = useState({
    name: "",
    verificationCode: "",
  });
  const [verifyDataError, setVerifyDataError] = useState({
    name: "",
    verificationCode: "",
  });
  const [verifiedData, setVerifiedData] = useState({
    courseName: "",
    date: "",
    isVerified: false,
  });
  const [openLoader, setOpen] = useState(false);

  const englishAsciiRegex = /^[A-Za-z\s]*$/;

  const handleVerification = () => {
    setVerifyDataError({ name: "", verificationCode: "" });
    if (verifyData.name.trim() === "") {
      setVerifyDataError((prev) => ({ ...prev, name: "Please enter Name." }));
    } else if (verifyData.verificationCode.trim() === "") {
      setVerifyDataError((prev) => ({
        ...prev,
        verificationCode: "Please enter Verification Code.",
      }));
    } else {
      setVerifyDataError({
        ...verifyDataError,
        verificationCode: "",
      });
      setOpen(true);
      let body = {
        name: verifyData.name.trim(),
        verificationCode: verifyData.verificationCode.trim(),
      };
      //verify certificate
      POST(Constants.verifyCertificate, body)
        .then((res) => {
          setOpen(false);
          if (!res.data.isVerified) {
            setVerifyDataError({
              ...verifyDataError,
              verificationCode: "No records found!",
            });
          } else if (res.data.isVerified) {
            setVerifiedData({
              ...verifiedData,
              courseName: res.data.courseName,
              date: res.data.generatedOn,
              isVerified: res.data.isVerified,
            });
          }
        })
        .catch((err) => {
          setVerifyDataError({
            name: "",
            verificationCode: "No records found!",
          });
          setOpen(false);
        });
    }
  };
  return (
    <Modal
      open={verifyCertOpen}
      onClose={() => {
        setVerifyData({
          name: "",
          verificationCode: "",
          isVerified: false,
        });
        verifyClose(true);
      }}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      className="verifyModalContainer"
    >
      <Grid
        container
        className="verifyModalMain"
        sx={{
          backgroundColor: "#F2F4F8",
        }}
      >
        <div className="verifyIconContainer">
          <CloseIcon
            onClick={() => {
              setVerifyData({
                name: "",
                verificationCode: "",
              });
              setVerifiedData({
                courseName: "",
                date: "",
                isVerified: false,
              });
              setVerifyDataError({
                name: "",
                verificationCode: "",
              });
              verifyClose(true);
            }}
          />
        </div>
        <div className="verifyCertificate">
          <div>
            <HeaderText className="loginInputHeader">Certificate verification</HeaderText>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="text"
                  label="Name on Certificate (Use English / Latin Script)"
                  placeholder="Name on Certificate (Use English / Latin Script)"
                  value={verifyData.name}
                  onChange={(e) => {
                    setVerifyData({
                      ...verifyData,
                      name: englishAsciiRegex.test(e.target.value)
                        ? e.target.value
                        : e.target.value.replace(/[^A-Za-z\s]/g, ""),
                    });
                  }}
                  error={Boolean(verifyDataError.name)}
                  helperText={verifyDataError.name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="text"
                  label="Verification Code"
                  placeholder="Verification Code"
                  value={verifyData.verificationCode}
                  onChange={(e) => {
                    setVerifyData({
                      ...verifyData,
                      verificationCode: e.target.value,
                    });
                  }}
                  error={Boolean(verifyDataError.verificationCode)}
                  helperText={verifyDataError.verificationCode}
                />
              </Grid>
            </Grid>
          </div>
          <FullScreenLoader open={openLoader} />
          {verifiedData.isVerified ? (
            <div>
              <div className="verifiedDataContainer">
                <div className="courseData">
                  <LabeledText>Course Name:</LabeledText>
                  <InputBox
                    type="text"
                    placeholder="Name on Certificate"
                    value={verifiedData.courseName}
                    disabled
                  />
                </div>
                <div className="courseData">
                  <LabeledText>Date of generation of certificate:</LabeledText>
                  <InputBox
                    type="text"
                    placeholder="Name on Certificate"
                    value={verifiedData.date}
                    disabled
                  />
                </div>
              </div>
              <ParagraphText className="verifyContentText">
                Note: The certificate with the provided verification code and the name exists.
                However, please check if the date of generation of the certificate printed on the
                certificate are exactly the same as mentioned above. Also, check if the course name
                printed on the certificate starts with the course name printed above. If they do not
                match, the certificate is not authentic.
              </ParagraphText>
            </div>
          ) : null}
          {!verifiedData.isVerified ? (
            <Button
              fullWidth
              variant="contained"
              sx={{ textTransform: "none", marginTop: "10px" }}
              onClick={handleVerification}
            >
              Verify
            </Button>
          ) : null}
        </div>
      </Grid>
    </Modal>
  );
};

export default VerifyCertificateModal;
