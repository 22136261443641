import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ButtonMain from "../../ButtonMain";
import FullScreenModal from "../../courses/CourseModals/FullScreenModal";
import "./CohortModal.scss";
import { useSelector } from "react-redux";
import { GET, POST } from "../../../config/Api";
import { Constants } from "../../../config/Constants";
import { InputBox } from "../../InputBox/InputBox";
import defaultCertBg from "../../../assets/DefaultCertificate.png";
import CertificateTemplate from "../../CertificateConfig/CertificatePreview/CertificateTemplate";
import QuillEditor from "../../QuillEditor/QuillEditor";
const NewCohortModal = ({
  modalOpen,
  cohortData,
  setCohortData,
  handleCohortSubmit,
  handleChange,
  setModalOpen,
  loading,
  modalClose,
  cohortDataError,
  initialValue,
  disable,
  courseData,
}) => {
  const [certificates, setCertificates] = useState([]);
  const [signatories, setSignatories] = useState([]);
  const [certmodalOpen, setcertModalOpen] = useState(false);
  const [certData, setCertData] = useState({});
  const [signData, setSignData] = useState({});

  let navigate = useNavigate();

  let UserData = useSelector((state) => state.userLogin.data);
  // const checkModerator = courseData?.members?.find(
  //   (item) => item?.permit_val === "moderator" && item?.user === UserData?.user?.id,
  // );

  useEffect(() => {
    document.addEventListener("focusin", (e) => {
      if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
        e.stopImmediatePropagation();
      }
    });
  }, []);

  useEffect(() => {
    if (modalOpen) {
      (async () => {
        //get all certificate configurations
        await GET(`${Constants.getAllCertificate}`)
          .then((res) => {
            setCertificates(res?.data?.certificates);
            setSignatories(res?.data?.signatories);
          })
          .catch((err) => console.log(err));
        //get cohort price by cohort id
        GET(`${Constants.course}/cohort/pricing/${cohortData?._id}`)
          .then((res) => {
            setCohortData((prev) => ({
              ...prev,
              price: res?.data?.priceInfo?.price,
            }));
          })
          .catch((err) => console.log(err));
      })();
    }
  }, [modalOpen]);

  const fetchInitialConfiguration = async () => {
    try {
      if (cohortData?.certificateConfig) {
        const certConfigData = await getConfiguration(cohortData.certificateConfig);
        setCertData(certConfigData);
      }
      if (cohortData?.signatoryConfig) {
        const signConfigData = await getConfiguration(cohortData.signatoryConfig);
        setSignData(signConfigData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchInitialConfiguration();
  }, [cohortData?.certificateConfig, cohortData?.signatoryConfig]);

  const getConfiguration = async (configId) => {
    try {
      //get getCertificateConfig by config id
      let response = await POST(`${Constants.getCertificateConfig}/${configId}`);
      return response?.data?.configuration;
    } catch (error) {
      navigate("/404");
    }
  };

  const handleCertificationChange = async (event) => {
    setCohortData({ ...cohortData, certificateConfig: event.target.value });
    const data = await getConfiguration(event.target.value);
    setCertData(data);
  };
  const handleSignatoryChange = async (event) => {
    if (event.target.value === "none") {
      setCohortData({ ...cohortData, signatoryConfig: "" });
      setSignData({});
    } else {
      setCohortData({ ...cohortData, signatoryConfig: event.target.value });
      const data = await getConfiguration(event.target.value);
      setSignData(data);
    }
  };

  return (
    <div className="containerBlock">
      <FullScreenModal
        open={modalOpen}
        setOpen={() => {
          modalClose(false);
        }}
        dialogTitle={"Cohort"}
      >
        {/* <FMModal
        modalOpen={modalOpen}
        modalClose={() => {
          modalClose(true);
        }}
        modalHeader={"Cohort"}
        className="cohort_modal"
        headerTextColor={"header_text_black"}
        backgroundImage={{
          backgroundImage: "linear-gradient(90deg, rgb(227, 238, 255), rgb(243, 231, 233))",
        }}
      > */}
        <form onSubmit={handleCohortSubmit} className="cohort_form">
          <Grid container spacing={2} p={3}>
            <Grid item className="fullwidth" lg={12}>
              <TextField
                sx={{ mt: 1 }}
                name="title"
                fullWidth
                autoComplete={"false"}
                value={cohortData.title}
                placeholder="Cohort Title*"
                onChange={handleChange}
                label="Cohort Title*"
                disabled={cohortData.type === "default" ? true : false}
              />
              <label className="errorMsg">{cohortDataError.title}</label>
            </Grid>
            <Grid item lg={6} className="fullwidth">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Start Date"
                  inputFormat="ll"
                  disablePast
                  disabled={cohortData.type === "default" ? true : false}
                  value={cohortData.startDate}
                  onChange={(newValue) =>
                    setCohortData({
                      ...cohortData,
                      startDate: newValue,
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      color="info"
                      InputProps={{
                        className: "",
                      }}
                      required
                      fullWidth
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
              <label className="errorMsg">{cohortDataError.startDate}</label>
            </Grid>
            <Grid item lg={6} className="fullwidth">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="End Date"
                  value={cohortData.endDate}
                  inputFormat="ll"
                  disablePast
                  disabled={cohortData.type === "default" ? true : false}
                  onChange={(newValue) =>
                    setCohortData({
                      ...cohortData,
                      endDate: newValue,
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      color="info"
                      InputProps={{
                        className: "date_input",
                      }}
                      required
                      fullWidth
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
              <label className="errorMsg">{cohortDataError.endDate}</label>
            </Grid>
            <Grid item lg={12} className="fullwidth">
              <TextField
                sx={{ mt: 1 }}
                placeholder={"Maximun numbers of learners allowed"}
                name="learnerLimit"
                type="number"
                value={cohortData.learnerLimit}
                onChange={handleChange}
                fullWidth
                autoComplete={"false"}
                label="Learner Limit*"
              />
              <label className="errorMsg">{cohortDataError.learnerLimit}</label>
            </Grid>
            <Grid item lg={12} className="fullwidth">
              <TextField
                sx={{ mt: 1 }}
                placeholder={"Custom Invite Code"}
                name="joiningCode"
                type="text"
                value={cohortData.joiningCode}
                onChange={(e) => {
                  /^[a-zA-Z0-9]*$/.test(e.target.value) && handleChange(e);
                }}
                fullWidth
                autoComplete={"false"}
                label="Custom Invite Code*"
                disabled={disable}
              />
              <label className="errorMsg">{cohortDataError.joiningCode}</label>
              {!disable && (
                <Typography variant="body2" sx={{ margin: "0.7rem 0rem", color: "#ef4444" }}>
                  Note : Please use alphabets and numbers only. Once saved can't be updated.
                </Typography>
              )}
            </Grid>
            <Grid item lg={12} className="fullwidth">
              <QuillEditor
                value={cohortData.customText}
                placeholder={initialValue}
                disabled={cohortData.type === "default" ? true : false}
                handleChange={(event) =>
                  handleChange({
                    target: { name: "customText", value: event.target.value },
                  })
                }
              />{" "}
              <br />
              {/* <Editor
                apiKey="372n02d49wbdou8ee7fkwdna3rd3cyrzwfh6cubvlm4yl9d4"
                initialValue={initialValue}
                value={cohortData.customText}
                disabled={cohortData.type === "default" ? true : false}
                onEditorChange={(newValue) => {
                  handleChange({
                    target: { name: "customText", value: newValue },
                  });
                }}
                disableMaskedInput
                init={{
                  selector: "textarea#open-source-plugins",
                  placeholder: "Custom announcement text for the cohort goes here...",
                  plugins:
                    "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                  imagetools_cors_hosts: ["picsum.photos"],
                  menubar: "file edit view insert format tools table help",
                  toolbar:
                    "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl",
                  toolbar_sticky: true,
                  autosave_ask_before_unload: true,
                  autosave_interval: "30s",
                  autosave_prefix: "{path}{query}-{id}-",
                  autosave_restore_when_empty: false,
                  autosave_retention: "2m",
                  image_advtab: true,
                  importcss_append: true,
                  height: 220,
                  image_caption: true,
                  quickbars_selection_toolbar:
                    "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
                  noneditable_noneditable_class: "mceNonEditable",
                  toolbar_mode: "sliding",
                  contextmenu: "link image imagetools table",
                  skin: "oxide",
                  content_css: "default",
                  link_assume_external_targets: true,
                  content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
              /> */}
              {cohortDataError.customText && (
                <Typography variant="body2" sx={{ color: "red", padding: "0.5rem" }}>
                  {cohortDataError.customText}
                </Typography>
              )}
            </Grid>
            <Grid item mt={2} lg={12} className="autoApprove">
              <FormControlLabel
                control={<Checkbox checked={cohortData.automaticApproval} />}
                label="Automatic Approval"
                onChange={() =>
                  setCohortData({
                    ...cohortData,
                    automaticApproval: !cohortData.automaticApproval,
                  })
                }
              />
            </Grid>
            {/* for moderator */}
            {UserData?.user?.type === "admin" && (
              <>
                <Grid item lg={12}>
                  <FormControlLabel
                    disabled={cohortData.type === "default" ? true : false}
                    control={<Checkbox checked={cohortData.isPaid} />}
                    label="Make this a paid Cohort"
                    onChange={() =>
                      setCohortData({
                        ...cohortData,
                        isPaid: !cohortData.isPaid,
                        price: "",
                      })
                    }
                  />
                </Grid>
                {cohortData.isPaid && (
                  <Grid item lg={12} position={"relative"}>
                    <InputBox
                      name="price"
                      type="text"
                      placeholder="Price in USD"
                      value={cohortData.price}
                      sideLabel={"Price in USD"}
                      onChange={(e) => {
                        const isNumber = /^(?:0|\d+(\.\d+)?)?$/.test(e.target.value);
                        if (isNumber) {
                          handleChange(e);
                        }
                      }}
                      errorMsg={cohortDataError.price}
                    />
                  </Grid>
                )}
                <Grid item lg={12} className="fullwidth">
                  <TextField
                    disabled={cohortData.type === "default" ? true : false}
                    sx={{ mt: 1 }}
                    placeholder={"Suffix of the course"}
                    name="certificateSuffix"
                    type="text"
                    value={cohortData.certificateSuffix || ""}
                    onChange={handleChange}
                    fullWidth
                    autoComplete={"false"}
                    label="Suffix for course"
                  />
                </Grid>
              </>
            )}
            {/* cert config for admin */}
            {UserData?.user?.type === "admin" && (
              <Grid item lg={12} className="fullwidth">
                <Box>
                  <FormControlLabel
                    label="Primary Certification Configuration"
                    disabled={cohortData.type === "default" ? true : false}
                    control={
                      <Checkbox
                        checked={cohortData.useCourseCertificateConfig}
                        onChange={() =>
                          setCohortData({
                            ...cohortData,
                            useCourseCertificateConfig: !cohortData.useCourseCertificateConfig,
                          })
                        }
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                  />
                </Box>
                <Box>
                  <FormControlLabel
                    label="Additional Signatory Configuration"
                    control={
                      <Checkbox
                        checked={cohortData.useCourseSignatoryConfig}
                        disabled={
                          !cohortData.useCourseCertificateConfig || !cohortData.certificateConfig
                        }
                        onChange={() =>
                          setCohortData({
                            ...cohortData,
                            useCourseSignatoryConfig: !cohortData.useCourseSignatoryConfig,
                          })
                        }
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                  />
                </Box>
                <Box>
                  {cohortData.useCourseCertificateConfig && (
                    <FormControl sx={{ mt: 2, mb: 2, minWidth: 120 }} fullWidth>
                      <InputLabel id="demo-simple-select-helper-label">
                        Primary Certification Configuration
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={cohortData.certificateConfig || ""}
                        label="Primary Certification Configuration"
                        onChange={handleCertificationChange}
                      >
                        {certificates?.map((item) => (
                          <MenuItem value={item._id} key={item?._id}>
                            {item?.configuration_title}
                          </MenuItem>
                        ))}
                      </Select>
                      <label className="errorMsg">{cohortDataError.certificateConfig}</label>
                    </FormControl>
                  )}
                  {cohortData.useCourseSignatoryConfig && (
                    <FormControl sx={{ mt: 1, mb: 2, minWidth: 120 }} fullWidth>
                      <InputLabel id="demo-simple-select-helper-label">
                        Additional Signatory Configuration
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={cohortData.signatoryConfig || "none"}
                        label="Additional Signatory Configuration"
                        onChange={handleSignatoryChange}
                        disabled={
                          !cohortData.useCourseCertificateConfig || !cohortData.certificateConfig
                        }
                      >
                        <MenuItem value="none" selected>
                          None
                        </MenuItem>
                        {signatories?.map((item) => (
                          <MenuItem value={item._id} key={item?._id}>
                            {item?.configuration_title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Box>
              </Grid>
            )}

            {cohortData.useCourseCertificateConfig && (
              <>
                <Typography
                  onClick={() => {
                    setcertModalOpen(true);
                  }}
                  style={{
                    cursor: "pointer",
                    color: "blue",
                    textDecoration: "underline",
                  }}
                >
                  Preview Certificate
                </Typography>
                <FullScreenModal
                  open={certmodalOpen}
                  setOpen={() => {
                    setcertModalOpen(false);
                  }}
                  dialogTitle={"Certificate Preview "}
                >
                  <Grid
                    item
                    className="fullwidth"
                    xs={12}
                    justifyContent={"center"}
                    display="flex"
                    mt={4}
                  >
                    {Object.keys(certData).length ? (
                      <CertificateTemplate certData={certData} signData={signData} />
                    ) : (
                      <img src={defaultCertBg} alt="certBg" width="100%" />
                    )}
                  </Grid>
                </FullScreenModal>
              </>
            )}

            <Grid item lg={12} display="flex" flexDirection="column">
              Note:
              <Typography variant="body2">
                1. Cannot add / enroll users after the given end date
              </Typography>
              <Typography variant="body2">
                2. Cannot generate certificate after 30 days of the specified end date
              </Typography>
            </Grid>
          </Grid>
          <ButtonMain className="buttonGreen" disabled={loading}>
            Save Cohort
          </ButtonMain>
        </form>
      </FullScreenModal>
    </div>
  );
};

export default NewCohortModal;
