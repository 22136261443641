import React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { Box } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryParams } from "../../../utils";

const NavigateItem = ({ data, setOpen, userDetails }) => {
  const { slug } = useParams();
  const { cid, mid } = useQueryParams();
  let navigate = useNavigate();
  const handleModuleOpen = (item) => {
    navigate(`/course/${slug}/${item?.slug}?cid=${cid}&mid=${item?._id}`, {
      state: {
        userData: userDetails,
      },
    });
  };

  return (
    <Box pl={data.container ? 2 : 0}>
      <ListItemButton
        dense
        // sx={{
        //   backgroundColor: mid === data?._id ? "#dbeafe" : "#f0f9ff",
        //   border: mid === data?._id ? "1px solid #bfdbfe" : "",
        //   borderRadius: 1,
        //   my: "2px",
        // }}
        sx={{
          bgcolor: mid === data?._id ? "#4f6a9e" : "#365082",
          borderRadius: 1,
          color: "white",
          borderLeft: "6px solid transparent",
          "& >div>.MuiListItemText-secondary": {
            color: "#ffffffa1",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },

          "&:hover": {
            bgcolor: "#4f6a9e",
            color: "white",
            cursor: "pointer",
            "& >div>.MuiListItemText-secondary": { color: "#ffffffa1" },
          },
          "&.Mui-selected": {
            color: "black",
            "& >div>.MuiListItemText-secondary": {
              color: "#333333",
            },
            bgcolor: "#ffffff",
            borderTop: "1px solid #365082 ",
            borderLeft: "6px solid #01CE3E",
          },
        }}
        onClick={() => handleModuleOpen(data)}
      >
        <ListItemIcon sx={{ minWidth: "auto", mr: 1, color: "white" }}>
          <DescriptionOutlinedIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={data?.title} />
      </ListItemButton>
      {data?.children?.map((child) => (
        <NavigateItem
          key={child?._id}
          data={child}
          setOpen={setOpen}
          handleModuleOpen={handleModuleOpen}
          userDetails={userDetails}
        />
      ))}
    </Box>
  );
};

export default NavigateItem;
