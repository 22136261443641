import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import moment from "moment";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import { Chip, Grid, Tooltip, Zoom } from "@mui/material";
import { Box } from "@mui/system";
import { IconButton } from "@mui/material";
import "./CohortDataTable.scss";
import { useQueryParams } from "../../../utils";
const CohortDataTable = ({
  cohortList,
  handleEditCohort,
  handleAddMembers,
  handleLearners,
  handleInsightModal,
  handleCohortPreviewModal,
}) => {
  const { cid } = useQueryParams();
  const [joiningCodeCopy, setJoiningCodeCopy] = useState(false);

  const handleAddCohortData = (value, tableMeta, updateValue) => {
    handleEditCohort(...tableMeta.rowData);
  };
  const handleAddMembersData = (value, tableMeta, updateValue) => {
    handleAddMembers(...tableMeta.rowData);
  };

  const handlePreviewCohort = (value, tableMeta, updateValue) => {
    handleCohortPreviewModal(...tableMeta.rowData);
  };
  const handleLearnersData = (value, tableMeta, updateValue) => {
    handleLearners(...tableMeta.rowData);
  };

  const handleNavigate = (value, tableMeta, updateValue) => {
    window.open(`/create-discussion-forum?cid=${cid}&coid=${tableMeta.rowData[0]}`, "_blank");
  };

  const handleCopyJoiningCode = (value, tableMeta, updateValue) => {
    const joiningCode = tableMeta.rowData[10];
    navigator.clipboard.writeText(joiningCode);
    setJoiningCodeCopy(true);
    setTimeout(() => {
      setJoiningCodeCopy(false);
    }, 500);
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableHead: {
          styleOverrides: {
            root: {
              fontWeight: 600,
              // backgroundColor: "#FF0000",
              // border: "1px solid black",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              // backgroundColor: "#FF0000",
              // border: "1px solid black",
            },
          },
        },
      },
    });
  const columns = [
    {
      name: "_id",
      options: {
        display: false,
      },
    },
    {
      name: "Title",
      options: {
        customHeadRender: (columnMeta, updateDirection) => (
          <th key={1} onClick={() => updateDirection(1)} className="table_head">
            {columnMeta.name}
          </th>
        ),
      },
    },
    {
      name: "Start Date",
      options: {
        customHeadRender: (columnMeta, updateDirection) => (
          <th key={2} onClick={() => updateDirection(2)} className="table_head">
            {columnMeta.name}
          </th>
        ),
      },
    },
    {
      name: "End Date",
      options: {
        customHeadRender: (columnMeta, updateDirection) => (
          <th key={3} onClick={() => updateDirection(3)} className="table_head">
            {columnMeta.name}
          </th>
        ),
      },
    },
    {
      name: "learnerLimit",
      options: {
        display: false,
      },
    },
    {
      name: "customText",
      options: {
        display: false,
      },
    },
    {
      name: "Automatic Approval",
      options: {
        customHeadRender: (columnMeta, updateDirection) => (
          <th key={6} onClick={() => updateDirection(6)} className="table_head">
            {columnMeta.name}
          </th>
        ),
        customBodyRender: (value) => (
          <Box display={"flex"} justifyContent="center" className="">
            {value ? (
              <Chip
                label="Yes"
                size="small"
                className="moderator_badge"
                color="info"
                variant="contained"
              />
            ) : (
              <Chip
                label="No"
                size="small"
                className="facilitator_badge"
                color="info"
                variant="contained"
              />
            )}
          </Box>
        ),
      },
    },
    {
      name: "user_id",
      options: {
        display: false,
      },
    },
    {
      name: "courseId",
      options: {
        display: false,
      },
    },
    {
      name: "joiningLink",
      options: {
        display: false,
      },
    },
    {
      name: "joiningCode",
      options: {
        display: false,
      },
    },
    {
      name: "certificateSuffix",
      options: {
        display: false,
      },
    },
    {
      name: "certificateConfig",
      options: {
        display: false,
      },
    },
    {
      name: "signatoryConfig",
      options: {
        display: false,
      },
    },
    {
      name: "useCourseCertificateConfig",
      options: {
        display: false,
      },
    },
    {
      name: "useCourseSignatoryConfig",
      options: {
        display: false,
      },
    },
    {
      name: "is Paid",
      options: {
        customHeadRender: (columnMeta, updateDirection) => (
          <th key={16} onClick={() => updateDirection(16)} className="table_head">
            {columnMeta.name}
          </th>
        ),
        customBodyRender: (value) => (
          <Box display={"flex"} justifyContent="center" className="">
            {value ? (
              <Chip
                label="Yes"
                size="small"
                className="moderator_badge"
                color="info"
                variant="contained"
              />
            ) : (
              <Chip
                label="No"
                size="small"
                className="facilitator_badge"
                color="info"
                variant="contained"
              />
            )}
          </Box>
        ),
      },
    },
    {
      name: "type",
      options: {
        display: false,
      },
    },
    {
      name: "Actions",
      options: {
        customHeadRender: (columnMeta, updateDirection) => (
          <th key={11} onClick={() => updateDirection(11)} className="table_head_actions">
            {columnMeta.name}
          </th>
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid container gap={3} alignItems="center" justifyContent={"flex-end"}>
              <Tooltip TransitionComponent={Zoom} title="Edit cohort basics">
                <IconButton
                  onClick={() => {
                    handleAddCohortData(value, tableMeta, updateValue);
                  }}
                  color="default"
                >
                  <ModeEditOutlineOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip TransitionComponent={Zoom} title="Add facilitator">
                <IconButton
                  onClick={() => {
                    handleAddMembersData(value, tableMeta, updateValue);
                  }}
                  color="default"
                >
                  <GroupAddOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip TransitionComponent={Zoom} title="Add Discussion">
                <IconButton
                  onClick={() => {
                    handleNavigate(value, tableMeta, updateValue);
                  }}
                  color="default"
                >
                  <MessageOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                TransitionComponent={Zoom}
                title={`${joiningCodeCopy ? "Invite Code Copied" : "Copy invite Code"}`}
              >
                <IconButton
                  color="default"
                  onClick={() => {
                    handleCopyJoiningCode(value, tableMeta, updateValue);
                  }}
                >
                  <ContentCopyOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip TransitionComponent={Zoom} title="Preview cohort snapshot">
                <IconButton
                  color="default"
                  onClick={() => {
                    handlePreviewCohort(value, tableMeta, updateValue);
                  }}
                >
                  <VisibilityOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip TransitionComponent={Zoom} title="Approve learner request">
                <IconButton
                  color="default"
                  onClick={() => {
                    handleLearnersData(value, tableMeta, updateValue);
                  }}
                >
                  <HowToRegOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip TransitionComponent={Zoom} title="Cohort Insights">
                <IconButton
                  color="default"
                  onClick={() => {
                    handleInsightModal(...tableMeta.rowData);
                  }}
                >
                  <QueryStatsOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          );
        },
      },
    },
  ];

  const options = {
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 15, 20],
    filterType: "dropdown",
    responsive: "vertical",
    selectableRowsHideCheckboxes: true,
    elevation: 5,
  };

  const data = cohortList?.map((el) => {
    const startDate = moment(el?.startDate).format("DD MMM, YYYY");
    const endDate = moment(el?.endDate).format("DD MMM, YYYY");
    return [
      el?._id,
      `${el?.title}${el.type === "default" ? " - self directed" : ""}`,
      startDate,
      endDate,
      el?.learnerLimit,
      el?.customText,
      el?.autoAccessApproval,
      el?.creator,
      el?.courseId,
      el?.joiningLink,
      el?.joinCode,
      el?.certificateSuffix,
      el?.certificate_config,
      el?.signatory_config,
      el?.useCourseCertificateConfig,
      el?.useCourseSignatoryConfig,
      el?.isPaid,
      el?.type,
    ];
  });

  return (
    <>
      <Box mt={5}>
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable data={data} columns={columns} options={options} />
        </ThemeProvider>
      </Box>
    </>
  );
};

export default CohortDataTable;
