import React, { useState } from "react";
import FMModal from "../CourseModals/FMModal";
import ButtonMain from "../../ButtonMain";
import { Grid, Typography } from "@mui/material";

import "./insightsModal.scss";
import { GET } from "../../../config/Api";
import { Constants } from "../../../config/Constants";
import { useCallback } from "react";
import { FullScreenLoader } from "../../Loader/Loader";

const InsightModal = ({ cohortData, insightOpen, insightModalClose, insightHeader }) => {
  const [insightData, setInsightData] = React.useState({
    enrollment: "",
    certification: "",
  });
  const [isLoading, setIsLoading] = useState(true);

  console.log("Cohrot data", cohortData);

  React.useEffect(() => {
    setIsLoading(true);
    getCohortInsights("enrollment", cohortData._id);
    getCohortInsights("certification", cohortData._id);
  }, []);

  const getCohortInsights = (type, cohort_id) => {
    //get cohort insights data
    GET(`${Constants.insights}/cohort/${type}/${cohort_id}`)
      .then((res) => {
        saveInsightsToState(type, res.data.data);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const saveInsightsToState = useCallback((type, data) => {
    setInsightData((prevState) => ({
      ...prevState,
      [type]: data,
    }));
  }, []);

  const handleDownloadCSV = () => {
    //download csv
    GET(`${Constants.insights}/cohort/downloadCSV/${cohortData._id}`)
      .then((res) => {
        if (res.data.success) {
          window.open(res.data.downloadLink);
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <FMModal
      modalOpen={insightOpen}
      modalClose={insightModalClose}
      modalHeader={insightHeader}
      className="insights_modal"
      headerTextColor={"header_text_black"}
      backgroundImage={{
        backgroundImage: "linear-gradient(90deg, #E3EEFF, #F3E7E9)",
      }}
    >
      <FullScreenLoader open={isLoading} />
      {!isLoading && (
        <Grid container className="insightMain">
          <Grid container className="insightData">
            <Grid>
              <Typography
                textAlign={"left"}
                fontWeight={600}
                variant="h6"
                // ml={2}
                // color="rgb(1, 67, 97)"
              >
                Total Learners
              </Typography>
              <Typography
                textAlign={"center"}
                fontWeight={600}
                mt={2}
                // color="rgb(1, 67, 97)"
                variant="h6"
              >
                {insightData.enrollment}
              </Typography>
            </Grid>
            <Grid>
              <Typography
                textAlign={"left"}
                fontWeight={600}
                variant="h6"
                // ml={2}
                // color="rgb(1, 67, 97)"
              >
                Total Certificates Generated
              </Typography>
              <Typography
                textAlign={"center"}
                fontWeight={600}
                mt={2}
                variant="h6"
                // color="rgb(1, 67, 97)"
              >
                {insightData.certification}
              </Typography>
            </Grid>
          </Grid>
          <Grid item className="csvButtonContainer">
            <ButtonMain
              onClick={handleDownloadCSV}
              style={{ width: "100%", minHeight: "2rem" }}
              variant="primary-blue"
            >
              Download CSV
            </ButtonMain>
          </Grid>
        </Grid>
      )}
    </FMModal>
  );
};

export default InsightModal;
