import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  breadcrumbReducer,
  rerenderBlocksReducer,
  saveCourseDetailReducer,
  saveModuleDetailReducer,
  userLoginReducer,
} from "./reducers";

const reducer = combineReducers({
  savedCourseDetail: saveCourseDetailReducer,
  savedModuleDetail: saveModuleDetailReducer,
  userLogin: userLoginReducer,
  rerenderBlocks: rerenderBlocksReducer,
  breadcrumb: breadcrumbReducer,
});

const initialState = {
  userLogin: {
    newUser: false,
    data: JSON.parse(localStorage.getItem("userData"))
      ? JSON.parse(localStorage.getItem("userData"))
      : null,
  },
  reRender: false,
  breadcrumb: [{ title: "Home", navigate: "/" }],
};
const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware)),
);

export default store;
