import * as React from "react";
import Box from "@mui/material/Box";
import { IconButton, Tooltip, Zoom } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CleaningServicesOutlinedIcon from "@mui/icons-material/CleaningServicesOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import "./BlockActions.scss";
import { useState } from "react";
import swal from "sweetalert";
import { GET, POST } from "../../../../config/Api";
import { Constants } from "../../../../config/Constants";
import { useDispatch, useSelector } from "react-redux";
import { rerenderBlocksAction } from "../../../../redux/action";
import { useLocation, useOutletContext } from "react-router-dom";
import { FullScreenLoader } from "../../../Loader/Loader";
import { findModule, flattenArrayWithId, useQueryParams } from "../../../../utils";
import ConfirmModal from "../../../ConfirmModal/ConfirmModal";
import { OpenInFullOutlined } from "@mui/icons-material";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import ContainerSelectModal from "../../../ConfirmModal/ContainerSelectModal";
import { MoveDownSharp } from "@mui/icons-material";
import CarosalConfirmModal from "../../../ConfirmModal/CarosalConfirmModal";
import CloseFullscreenOutlinedIcon from "@mui/icons-material/CloseFullscreenOutlined";

const style = document.createElement("style");
style.innerHTML = `

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; 
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 3s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
`;
document.head.appendChild(style);

const BlockActions = ({
  block,
  setmodalOpen,
  setblockModOpen,
  hidden,
  setHidden,
  style,
  collapsed,
  handleCollapseToggle,
  childContainers,
}) => {
  const [open, setOpen] = useState(false);
  const [confLoading, setConfLoading] = useState(false);
  const [confModal, setConfModal] = useState(false);
  const [carosalModal, setCarosalModel] = useState(false);
  const [unHideAll, setUnHideAll] = useState(false);
  const [parentModule, setParentModules] = useState(false);
  let UserData = useSelector((state) => state.userLogin.data);
  const { cid } = useQueryParams();
  const { setCourseOne } = useOutletContext();

  let dispatch = useDispatch();
  let RenderBlocks = useSelector((state) => state.rerenderBlocks);

  const location = useLocation();
  const createblockroute = location.pathname.startsWith("/course/createblock");

  const loaderContent = document.createElement("div");
  loaderContent.innerHTML = `
   <div class="spinner-container">
    <div class="spinner"></div>
  </div>
  `;

  const handleDeleteBlock = async (type) => {
    if (childContainers?.length > 0) {
      // Display warning if there are child containers
      let warningText =
        "This module is set as a Predecessor for other modules. It will be necessary to remove it from those modules before delete this module. Below are the modules that are successors of this module are: ";

      // Add each child title to the warningText
      warningText += childContainers
        .map((child, index) => `${child.title}${index < childContainers.length - 1 ? ", " : ""}`)
        .join("");

      swal({
        title: "Warning",
        text: warningText,
        icon: "warning",
        cancelButtonText: "Cancel",
        dangerMode: true,
      });
    } else {
      // Show confirmation dialog if no child containers
      let confirmText =
        type === "container"
          ? "Note: Deleting a module will delete all the blocks and sub-modules inside them."
          : "Once deleted, you will not be able to recover this item!";

      swal({
        title: "Confirm Delete?",
        text: confirmText,
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          try {
            // Show loader during delete operation
            swal({
              title: "Deleting...",
              content: loaderContent,
              buttons: false,
              closeOnClickOutside: false,
              closeOnEsc: false,
            });

            // Construct payload for deletion
            let payload = {
              course_id: cid,
              type: type,
            };
            //soft delete of block
            await POST(`${Constants.deleteBlock}/${block?._id}`, payload);
            setCourseOne((prev) => ({ ...prev, published: false }));
            dispatch(rerenderBlocksAction(RenderBlocks));

            // Close the loader and show success message
            swal.close();
            swal("Deleted Successfully", {
              icon: "success",
            });
          } catch (error) {
            console.error("Error deleting block:", error);
            swal("Error", "Failed to delete the block.", "error");
          }
        }
      });
    }
  };

  const manageDependentModules = async (selectedList) => {
    setConfLoading(true);
    let body = {
      user_id: UserData?.user?.id,
      course: cid,
      parentContainers: selectedList,
      container: block?.container ? block?.container : null,
      _id: block?._id,
      type: "edit",
    };
    const response = await POST(`${Constants.addBlock}/${block?.type}?_id=${block?._id}`, body);
    if (response?.data) {
      setConfLoading(false);
      setCourseOne((prev) => ({ ...prev, published: false }));
      dispatch(rerenderBlocksAction(RenderBlocks));
    }
    swal("Success", "Predecessor Components updated Successfully", "success");
  };

  const handlehideBlock = async (e, blockType, containersIds) => {
    setConfLoading(true);
    setOpen(true);
    let payload = {
      user_id: UserData.user.id,
      is_hidden: !hidden,
      blockType: blockType,
      course_id: cid,
      containersIds: containersIds,
    };

    if (!unHideAll && blockType === "container" && hidden) {
      const newIds = containersIds.filter((el) => el === block._id);
      payload.containersIds = newIds;
    }
    //hideBlock from learner
    let response = await POST(`${Constants.hideBlock}/${block?._id}`, payload);
    if (response?.data) {
      setConfLoading(false);
      setHidden(response?.data?.is_hidden);
      setCourseOne((prev) => ({ ...prev, published: false }));
      setOpen(false);
    }
    swal(
      "Success",
      `${hidden ? "Un-hide from Learner" : "Hide from Learner"} Successfully`,
      "success",
    );
  };

  const ManageCarosalBlock = async (selectedCarosal) => {
    setConfLoading(true);
    let body = {
      user_id: UserData?.user?.id,
      course: cid,
      container: block?.container ? block?.container : null,
      carousel_container: selectedCarosal ? selectedCarosal : null,
      _id: block?._id,
      type: block?.type,
    };
    const response = await POST(`${Constants.addBlock}/${block?.type}?_id=${block?._id}`, body);
    if (response?.data) {
      setConfLoading(false);
      setCourseOne((prev) => ({ ...prev, published: false }));
      dispatch(rerenderBlocksAction(RenderBlocks));
    }
    swal(
      "Success",
      `${
        !selectedCarosal ? "Removed From Block Carousel " : "Moved to Block Carousel "
      } Successfully`,
      "success",
    );
  };

  const hideContainers = async () => {
    try {
      setConfLoading(true);
      //hide course container blocks
      const { data } = await GET(
        `${Constants.getCourseContainerBlocks}/${block.course}?moderator=true`,
      );
      const foundBlock = findModule(block._id, data.blocks);
      const containersIds = flattenArrayWithId([foundBlock]);
      setConfLoading(false);
      handlehideBlock(null, "container", containersIds);
      setCourseOne((prev) => ({ ...prev, published: false }));
    } catch (error) {
      console.log(error);
    }
  };

  const openCofirmModal = () => {
    setConfModal(true);
  };

  const openCarosalModal = () => {
    setCarosalModel(true);
  };

  const getWarningText = () => {
    let warningText = "";
    if (childContainers?.length > 0) {
      warningText =
        "This module is set as a Predecessor for other modules. It will be necessary to remove it from those modules before hiding this module. Below are the modules that are successors of this module are: ";
      warningText += childContainers
        .map((child, index) => `${child.title}${index < childContainers.length - 1 ? ", " : ""}`)
        .join("");
    } else {
      warningText = hidden
        ? "If you want to unhide the inner containers, please check the checkbox below"
        : "Hiding this container will also hide its inner containers.";
    }
    return warningText;
  };

  return (
    <Box
      className={`blockActions ${createblockroute ? "" : "hideActionIcons"}`}
      sx={{ background: "white" }}
      style={style}
    >
      {/* {isCarosal && (
        <> */}
      <Tooltip TransitionComponent={Zoom} title="Edit Block" sx={{ margin: "0rem 0.5rem" }}>
        <IconButton
          color="default"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setmodalOpen &&
              setmodalOpen((prev) => ({
                ...prev,
                block: block,
                modalOpen: true,
              }));
          }}
          className="actionIcons"
        >
          <EditOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip TransitionComponent={Zoom} title="Edit Appearance" sx={{ margin: "0rem 0.5rem" }}>
        <IconButton
          color="default"
          onClick={() => {
            setmodalOpen &&
              setmodalOpen((prev) => ({
                ...prev,
                block: block,
                themeModalOpen: true,
              }));
          }}
          className="actionIcons"
        >
          <CleaningServicesOutlinedIcon />
        </IconButton>
      </Tooltip>

      <Tooltip
        TransitionComponent={Zoom}
        title={`${hidden ? "Un-hide from Learner" : "Hide from Learner"}`}
        sx={{ margin: "0rem 0.5rem" }}
      >
        <IconButton
          color="default"
          onClick={block?.type === "container" ? openCofirmModal : handlehideBlock}
          className="actionIcons"
        >
          {hidden ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
        </IconButton>
      </Tooltip>
      <Tooltip TransitionComponent={Zoom} title="Delete Block" sx={{ margin: "0rem 0.5rem" }}>
        <IconButton
          color="default"
          onClick={() => handleDeleteBlock(block?.type)}
          className="actionIcons"
        >
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip TransitionComponent={Zoom} title="Add a block below" sx={{ margin: "0rem 0.5rem" }}>
        <IconButton
          color="default"
          onClick={() => {
            setblockModOpen((prev) => {
              return {
                ...prev,
                modalOpen: true,
                addBelow: true,
                currentBlockId: block?._id,
                currentBlockOrder: block?.order,
              };
            });
          }}
          className="actionIcons"
        >
          <AddOutlinedIcon />
        </IconButton>
      </Tooltip>

      {block.type === "container" && (
        <Tooltip
          TransitionComponent={Zoom}
          title="Make this module conditionally enabled"
          sx={{ margin: "0rem 0.5rem" }}
        >
          <IconButton
            color="default"
            onClick={() => {
              setParentModules(true);
            }}
            className="actionIcons"
          >
            <LibraryAddIcon />
          </IconButton>
        </Tooltip>
      )}

      {/* </>
      )} */}

      {block?.type === "carousel_container" && (
        <Tooltip
          TransitionComponent={Zoom}
          title={`${collapsed ? "Expand Associated Blocks" : "Collapse Associated Blocks"}`}
          sx={{ margin: "0rem 0.5rem" }}
        >
          <IconButton
            color="default"
            onClick={() => {
              handleCollapseToggle(block._id);
            }}
            className="actionIcons"
          >
            {collapsed ? <OpenInFullOutlined /> : <CloseFullscreenOutlinedIcon />}
          </IconButton>
        </Tooltip>
      )}

      {!(block?.type === "container" || block?.type === "carousel_container") && (
        <Tooltip
          TransitionComponent={Zoom}
          title={`${
            block?.carousel_container
              ? "Remove From Block Carousel"
              : "Assign /Tag it to Block Carousel"
          }`}
          sx={{ margin: "0rem 0.5rem" }}
        >
          <IconButton color="default" onClick={openCarosalModal} className="actionIcons">
            <MoveDownSharp />
          </IconButton>
        </Tooltip>
      )}
      <FullScreenLoader open={open} />

      {block?.type === "container" && (
        <ConfirmModal
          headerText={childContainers?.length === 0 ? "Note" : "Warning"}
          confirmText={getWarningText()}
          showConfirmButton={childContainers?.length === 0}
          customComp={
            hidden && (
              <Box display={"flex"} my={2}>
                <input
                  type="checkbox"
                  id="unhideAll"
                  style={{
                    height: "20px",
                    width: "20px",
                    marginRight: "10px",
                  }}
                  value={unHideAll}
                  checked={unHideAll}
                  onChange={() => setUnHideAll(!unHideAll)}
                />
                <label htmlFor="unhideAll">unhide all the inner containers</label>
              </Box>
            )
          }
          handleConfirm={hideContainers}
          handleClose={() => {
            setConfModal(false);
          }}
          confLoading={confLoading}
          confModal={confModal}
        />
      )}

      {block?.type === "container" && (
        <ContainerSelectModal
          open={parentModule}
          handleClose={() => setParentModules(false)}
          handleConfirm={manageDependentModules}
          cid={cid}
          headerText={"Select Block Carousel"}
          container={block?.container ? block?.container : null}
          currentBlock={block}
        />
      )}

      {block?.type !== "container" && (
        <CarosalConfirmModal
          open={carosalModal}
          handleClose={() => setCarosalModel(false)}
          handleConfirm={ManageCarosalBlock}
          cid={cid}
          headerText={"Select Block Carousel"}
          carosalContainer={block?.carousel_container}
          container={block?.container ? block?.container : null}
        />
      )}
    </Box>
  );
};
export default BlockActions;
