import React, { useRef, useState } from "react";
import FMModal from "../../ModalMain/CourseModals/FMModal";
import { Box } from "@mui/material";
import ButtonMain from "../../ButtonMain";
import { InputBox } from "../../InputBox/InputBox";
import { POST } from "../../../config/Api";
import { useOutletContext } from "react-router-dom";
import { Constants } from "../../../config/Constants";

import "./CourseModal.scss";
import { useSelector } from "react-redux";
import { ToastNotify } from "../../SnackBar/ToastNotify";
import { isValidEmail } from "../../../utils";

const SendEmailModal = ({ openEmailModal, setOpenEmailModal, name }) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loading, setLoading] = useState(false);

  const { courseOne, completedModulesData } = useOutletContext();
  const toastRef = useRef();
  const { user } = useSelector((state) => state.userLogin.data);

  const handleSendEmail = async () => {
    if (!email.trim()) {
      setEmailError("Please enter email");
    } else if (!isValidEmail(email)) {
      setEmailError("Please enter a valid email");
    } else {
      setLoading(true);
      let payload = {
        course_id: courseOne?._id,
        cohort_id: completedModulesData?.cohort_id,
        email: email,
        courseName: courseOne?.title,
        name: name,
      };
      try {
        //send certificate email
        await POST(`${Constants.sendCertificateEmail}`, payload);
        setLoading(false);
        setOpenEmailModal(false);
        toastRef.current.showToastMessage("Email Sent Successfully", "success");
        //save user last activity
        await POST(`${Constants.userActivity}?activity=sendCertificateMail`, {
          user: user,
        });
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };

  return (
    <>
      <FMModal
        modalOpen={openEmailModal}
        modalClose={() => {
          setEmail("");
          setOpenEmailModal(false);
          setEmailError("");
        }}
        modalHeader={"Send Email"}
        className="confirm_modal"
        headerTextColor={"header_text_black"}
        backgroundImage={{
          backgroundImage: "linear-gradient(#a1c4fd, #c2e9fb)",
        }}
      >
        <InputBox
          placeholder={"Enter Email*"}
          value={email}
          type="email"
          onChange={(e) => {
            setEmail(e.target.value);
            setEmailError("");
          }}
          errorMsg={emailError}
        />
        <Box className="regenerate-button-container">
          <ButtonMain className="regenerate-button" onClick={handleSendEmail} disabled={loading}>
            Send
          </ButtonMain>
          <ButtonMain
            className="cancel-button"
            onClick={() => {
              setEmail("");
              setEmailError("");
              setOpenEmailModal(false);
            }}
          >
            Cancel
          </ButtonMain>
        </Box>
      </FMModal>
      <ToastNotify ref={toastRef} duration={4000} />
    </>
  );
};

export default SendEmailModal;
