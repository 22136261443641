import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import BlockActions from "../BlockActions/BlockActions";
import "./GifView.scss";
import ArtView from "../ArtView/ArtView";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "right",
  justifyContent: "right",
  color: theme.palette.common.white,
}));

const GifView = ({
  gif,
  courseDetails,
  block,
  courseDetail,
  courseOne,
  setmodalOpen,
  setblockModOpen,
  preview,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [hidden, setHidden] = useState(block?.is_hidden);

  let { isLoggedIn, user } = useSelector((state) => state.userLogin?.data) || {};
  let isModerator =
    courseDetails?.creator === user?.id ||
    courseDetails?.members?.findIndex((item) => item?.user === user?.id);

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  return (
    <>
      {(isModerator === -1 || isModerator === undefined) && block?.is_hidden ? (
        ""
      ) : (
        <div className="gif-view">
          <div
            className={` container-block gif-block 
        ${isLoggedIn ? `${block?.theme} postBgColor` : `${block?.theme} defaultStyle`}
      `}
            style={{
              background: `${block?.theme_type === "color" ? block?.color : ""}`,
            }}
            onMouseOver={handleHover}
            onMouseLeave={handleMouseLeave}
          >
            {isModerator !== -1 && !preview && isLoggedIn && isHovering && (
              <BlockActions
                blockId={gif?._id}
                block={block}
                setmodalOpen={setmodalOpen}
                setblockModOpen={setblockModOpen}
                hidden={hidden}
                setHidden={setHidden}
              />
            )}
            {isModerator !== -1 && gif?.carousel_container && (
              <Image>
                <Tooltip title="This Block Tagged to Carousel Block">
                  <LocalOfferRoundedIcon fontSize="large" color="primary" />
                </Tooltip>
              </Image>
            )}
            <video
              autoPlay
              loop
              muted
              width={gif?.width}
              height={gif?.height}
              src={gif?.embed}
            ></video>
            <ArtView art={(gif || block)?.art} />
          </div>
        </div>
      )}
    </>
  );
};

export default GifView;
