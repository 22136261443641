import "./ButtonView.scss";
import React from "react";
import ButtonMain from "../../../ButtonMain";
import { useState } from "react";
import { useSelector } from "react-redux";
import BlockActions from "../BlockActions/BlockActions";
import ArtView from "../ArtView/ArtView";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "right",
  justifyContent: "right",
  color: theme.palette.common.white,
}));

const ButtonView = ({
  button,
  courseDetails,
  courseDetail,
  courseOne,
  setmodalOpen,
  setblockModOpen,
  block,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [hidden, setHidden] = useState(block?.is_hidden);
  let { isLoggedIn, user } = useSelector((state) => state.userLogin?.data) || {};
  let isModerator =
    courseDetails?.creator === user?.id ||
    courseDetails?.members?.findIndex((item) => item?.user === user?.id);

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <>
      {(isModerator === -1 || isModerator === undefined) && block?.is_hidden ? (
        ""
      ) : (
        <div className="button-view" onMouseOver={handleHover} onMouseLeave={handleMouseLeave}>
          {isModerator !== -1 && isLoggedIn && isHovering && (
            <BlockActions
              blockId={button?._id}
              block={button}
              setmodalOpen={setmodalOpen}
              setblockModOpen={setblockModOpen}
              hidden={hidden}
              setHidden={setHidden}
            />
          )}
          {/* {isModerator !== -1 && block?.carousel_container && (
            <div>
              <LocalOfferRoundedIcon fontSize="large" color="primary" />
            </div>
          )} */}

          {button.button.url ? (
            <>
              {button.button.is_new_tab ? (
                <ButtonMain
                  className={` main-button-one full-width-btn 
                          ${
                            isLoggedIn
                              ? `${button?.theme} postBgColor`
                              : `${button?.theme} defaultStyle`
                          }
                        `}
                  style={{
                    background: `${button?.theme_type === "color" ? button?.color : ""}`,
                    textAlign: button?.button?.alignment,
                  }}
                  onClick={() => window.open(`${button.button.url}`, "_blank")}
                >
                  {button.text}
                </ButtonMain>
              ) : (
                <ButtonMain
                  className={`full-width-btn 
                ${
                  button?.theme === "" || button?.theme === undefined
                    ? isLoggedIn
                      ? "postBgColor"
                      : "defaultStyle"
                    : button?.theme
                }`}
                  onClick={() => window.open(`${button.button.url}`, "_blank")}
                  style={{ textAlign: button?.button?.alignment }}
                >
                  {button.text}
                </ButtonMain>
              )}

              {isModerator !== -1 && block?.carousel_container && (
                <Image>
                  <Tooltip title="This Block Tagged to Carousel Block">
                    <LocalOfferRoundedIcon fontSize="large" color="primary" />
                  </Tooltip>
                </Image>
              )}
            </>
          ) : (
            <>
              {button.button.block && (
                <ButtonMain
                  variant="contained"
                  className={
                    button?.theme === "" || button?.theme === undefined
                      ? isLoggedIn
                        ? "postBgColor"
                        : "defaultStyle"
                      : button?.theme
                  }
                >
                  {button.text}
                </ButtonMain>
              )}
            </>
          )}
          {button?.art?.m && <ArtView art={(block || button)?.art} />}
        </div>
      )}
    </>
  );
};

export default ButtonView;
