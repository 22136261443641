import { authUrl, baseurl, cloudfrontUrl } from "./BaseUrl";

export const Constants = {
  //PreLoginToken
  getPreLoginToken: `${baseurl}/isAuth`,
  //Public APIs
  getPublicCourses: `${baseurl}/public/courses`,
  getCourseBySearch: `${baseurl}/public/search`,
  getS3PublicCourses: "getPublicCourses",
  getPublicCourseDetail: `${baseurl}/public/course`,
  getPublicCourseBlock: `${baseurl}/blocks`,
  getPublicCourseContainer: `${baseurl}/blocks/container`,
  getPublicCourseCarosalContainer: `${baseurl}/blocks/carosalContainer`,
  // course APIs
  course: `${baseurl}/course`,
  courseManage: `${baseurl}/course/manage`,
  createCourse: `${baseurl}/course/new`,
  saveAndUpdateUserActivities: `${baseurl}/blocks`,
  // Cohort Management APIs
  cohort: `${baseurl}/course/cohort`,
  // Cohort Learner Requests
  approveLearnerRequests: `${baseurl}/course/cohort/learners/approveLearnerRequest`,
  getLearnerRequests: `${baseurl}/course/cohort/learners`,
  //Terms
  terms: `${baseurl}/course/terms`,
  // LOGIN-SIGNUP
  userSignup: `${baseurl}/user/signup`,
  userLogin: `${baseurl}/user/login`,
  userLogout: `${authUrl}/v1/auth/logout`,
  // FORGOT AND RESET PASSWORD
  forgotPassword: `${baseurl}/user/forgot`,
  resetPassword: `${baseurl}/user/reset`,
  verifyCertificate: `${baseurl}/verifyCertificate`,
  // Images
  imgPath: `${cloudfrontUrl}/fs-static/images/site`,
  // checkCourseHasCohort: `${baseurl}/blocks/course/CheckCoursehasCohort`,
  enrollIntoCohort: `${baseurl}/blocks/cohort/enroll`,
  //Search API's
  searchAPI: `${baseurl}/search`,
  //Add BLock API's
  addBlock: `${baseurl}/blocks/add_blocks`,
  updateBlock: `${baseurl}/blocks/update_blocks`,
  //search API's
  userSearch: `${baseurl}/user/search?search=`,
  // Assessment
  assessment: `${baseurl}/assessment`,
  //Delete Block
  deleteBlock: `${baseurl}/blocks/delete_block`,
  //hide block
  hideBlock: `${baseurl}/blocks/hide_block`,
  editTheme: `${baseurl}/blocks/edit_block_theme`,
  getCarosalBlocksFromCourseID: `${baseurl}/blocks/courseCarosalContainers`,
  getCourseContainerBlocks: `${baseurl}/blocks/courseContainerBlocks`,
  getModuleContainerByCourseOrContainer: `${baseurl}/blocks/courseModuleContainers`,
  markCompleted: `${baseurl}/blocks/markCompleted`,
  // Certificate
  checkCertificate: `${baseurl}/assessment/checkCertificate`,
  generateCertificate: `${baseurl}/certificate/generateCertificate`,
  updateUserCourseActivity: `${baseurl}/certificate/updateUserCourseActivity`,
  // update user profile
  updateProfile: `${baseurl}/user/userProfile`,
  checkEmailAvailability: `${baseurl}/user/checkEmailAvailability`,
  //check user enrollment into cohort
  userEnrollment: `${baseurl}/course/cohortenrollment`,
  //send cert email
  sendCertificateEmail: `${baseurl}/course/sendCertificateEmail`,
  //addMembers
  addMultipleMembers: `${baseurl}/user/addMembers`,
  //get course id by slug
  getCourseBySlug: `${baseurl}/course/getCourseBySlug`,
  //create certificate
  createCertificate: `${baseurl}/certificate/create`,
  getAllCertificate: `${baseurl}/certificate/all`,
  getCertificateConfig: `${baseurl}/certificate/configuration`,
  deleteCertificateConfig: `${baseurl}/certificate/delete`,
  //insights
  insights: `${baseurl}/insights`,
  // Payments
  getPaymentDetails: `${baseurl}/payments`,
  cancelPayment: `${baseurl}/payments/cancel-payment`,
  capturePayalOrder: `${baseurl}/payments/capture-paypal-order`,
  createOrder: `${baseurl}/payments/order`,
  //FSComms APIs
  fsComms: `${baseurl}/fsComms`,
  fsCommsUserActivity: `${baseurl}/fsCommsUserActivity`,
  //create topic
  discussionTopic: `${baseurl}/discussion`,
  comment: `${baseurl}/comment`,
  //review
  review: `${baseurl}/reviewer`,
  //add user activity
  userActivity: `${baseurl}/user/activity`,
  //get latest config from userCourseActivity,
  getLatestConfiguration: `${baseurl}/certificate/getLatestConfigFromUserCourseActivity`,
  compareNameOnCertificate: `${baseurl}/certificate/compareNameOnCertificate`,
};

export const creditPoints = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const scoreLists = ["attempt", "score", "none"];
export const themes = [
  "theme-a",
  "theme-b",
  "theme-c",
  "theme-d",
  "theme-e",
  "theme-f",
  "theme-g",
  "theme-h",
];
export const audioFileExtensions = ["mp3", "wav", "aiff", "flac", "ogg", "m4a"];
export const videoFileExtensions = ["mp4", "mov", "wmv", "avi", "mkv", "flv"];
export const fileExtensions = [
  "txt",
  "pdf",
  "doc",
  "docx",
  "xls",
  "xlsx",
  "ppt",
  "pptx",
  "zip",
  "rar",
];

export const blockTypes = {
  TEXT: "text",
  BUTTON: "button",
  DIVIDER: "divider",
  TOGGLE_LIST: "toggle_list",
  IMAGE: "image",
  AUDIO: "audio",
  VIDEO: "video",
  FILE: "file",
  LINK: "link",
  GIF: "gif",
  MCQ: "mcq",
  FILL: "fill",
  MATCH: "match",
  GRID: "grid",
  RESPONSE: "response",
  LIST: "list",
  EMBED: "embed",
  NATIVE_EMBED: "native-embed",
  COMIC: "comic",
  CONTAINER: "container",
  DISCUSSION: "discussion",
};

export const userRoles = {
  LEARNER: "learner",
  REVIEWER: "reviewer",
  MODERATOR: "moderator",
};

export const s3Constants = {
  S3_API_KEY: "api_response_json",
};
export const LoginMessage = "To fully engage with and complete the course, please log in.";
