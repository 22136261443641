import React, { useState } from "react";
import { HeaderText, LabeledText, ParagraphText } from "../../Typography";
import { InputBox } from "../../InputBox/InputBox";
import { Button, Grid, TextField } from "@mui/material";
import { FullScreenLoader } from "../../Loader/Loader";
import { POST } from "../../../config/Api";
import { Constants } from "../../../config/Constants";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import HeaderContainer from "../../HeaderContainer/HeaderContainer";
import { useQueryParams } from "../../../utils";

const VerifyCertificate = () => {
  let { vcode, name } = useQueryParams();
  const [verifyData, setVerifyData] = useState({
    name: "" || name,
    verificationCode: "" || vcode,
  });
  const [verifyDataError, setVerifyDataError] = useState({
    name: "",
    verificationCode: "",
  });
  const [verifiedData, setVerifiedData] = useState({
    courseName: "",
    date: "",
    isVerified: false,
  });
  const [openLoader, setOpen] = useState(false);

  let navigate = useNavigate();

  const englishAsciiRegex = /^[A-Za-z\s]*$/;

  const handleVerification = () => {
    setVerifyDataError((prev) => ({ ...prev, name: "", verificationCode: "" }));
    if (verifyData.name.trim() === "") {
      setVerifyDataError((prev) => ({ ...prev, name: "Please enter Name." }));
    } else if (verifyData.verificationCode.trim() === "") {
      setVerifyDataError((prev) => ({
        ...prev,
        verificationCode: "Please enter Verification Code.",
      }));
    } else {
      setVerifyDataError({
        ...verifyDataError,
        verificationCode: "",
      });
      setOpen(true);
      let body = {
        name: verifyData.name.trim(),
        verificationCode: verifyData.verificationCode.trim(),
      };
      //verify certificate
      POST(Constants.verifyCertificate, body)
        .then((res) => {
          setOpen(false);
          if (!res.data.isVerified) {
            setVerifyDataError({
              ...verifyDataError,
              verificationCode: "No records found!",
            });
          } else if (res.data.isVerified) {
            setVerifiedData({
              ...verifiedData,
              courseName: res.data.courseName,
              date: res.data.generatedOn,
              isVerified: res.data.isVerified,
            });
          }
        })
        .catch((err) => {
          setVerifyDataError((prev) => ({
            ...prev,
            name: "",
            verificationCode: "No records found!",
          }));
          setOpen(false);
        });
    }
  };
  return (
    <div>
      <HeaderContainer>
        <Grid
          container
          className="verifyModalMain"
          sx={{
            backgroundImage: "linear-gradient(#BDC2E8, #E6DEE9)",
          }}
        >
          <div className="verifyIconContainer">
            <CloseIcon
              onClick={() => {
                setVerifyData({
                  name: "",
                  verificationCode: "",
                });
                setVerifyDataError({
                  verificationCode: "",
                });
                navigate("/");
              }}
            />
          </div>
          <div className="verifyCertificate">
            <div>
              <HeaderText className="loginInputHeader">Certificate verification</HeaderText>
              {/* <InputBox
                type="text"
                placeholder="Name on Certificate (Use English / Latin Script)"
                value={verifyData.name}
                onChange={(e) => {
                  // setVerifyData({ ...verifyData, name: e.target.value });
                  setVerifyData({
                    ...verifyData,
                    name: englishAsciiRegex.test(e.target.value)
                      ? e.target.value
                      : e.target.value.replace(/[^A-Za-z\s]/g, ""),
                  });
                }}
                errorMsg={verifyDataError.name}
                className="verifyCert-Input"
              />
              <InputBox
                type="text"
                placeholder="Verification Code"
                value={verifyData.verificationCode}
                onChange={(e) => {
                  setVerifyData({
                    ...verifyData,
                    verificationCode: e.target.value,
                  });
                }}
                errorMsg={verifyDataError.verificationCode}
              /> */}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="text"
                    label="Name on Certificate (Use English / Latin Script)"
                    placeholder="Name on Certificate (Use English / Latin Script)"
                    value={verifyData.name}
                    onChange={(e) => {
                      setVerifyData({
                        ...verifyData,
                        name: englishAsciiRegex.test(e.target.value)
                          ? e.target.value
                          : e.target.value.replace(/[^A-Za-z\s]/g, ""),
                      });
                    }}
                    // error={Boolean(verifyDataError.name)}
                    helperText={verifyDataError.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="text"
                    label="Verification Code"
                    placeholder="Verification Code"
                    value={verifyData.verificationCode}
                    onChange={(e) => {
                      setVerifyData({
                        ...verifyData,
                        verificationCode: e.target.value,
                      });
                    }}
                    // error={Boolean(verifyDataError.verificationCode)}
                    helperText={verifyDataError.verificationCode}
                  />
                </Grid>
              </Grid>
            </div>
            <FullScreenLoader open={openLoader} />
            {verifiedData.isVerified ? (
              <div>
                <div className="verifiedDataContainer">
                  <div className="courseData">
                    <LabeledText>Course Name:</LabeledText>
                    <InputBox
                      type="text"
                      placeholder="Name on Certificate"
                      value={verifiedData.courseName}
                      disabled
                    />
                  </div>
                  <div className="courseData">
                    <LabeledText>Date of generation of certificate:</LabeledText>
                    <InputBox
                      type="text"
                      placeholder="Name on Certificate"
                      value={verifiedData.date}
                      disabled
                    />
                  </div>
                </div>
                <ParagraphText className="verifyContentText">
                  Note: The certificate with the provided verification code and the name exists.
                  However, please check if the course name and the date of generation of the
                  certificate printed on the certificate are exactly the same as mentioned above. If
                  they do not match, the certificate is not authentic.
                </ParagraphText>
              </div>
            ) : null}
            {!verifiedData.isVerified ? (
              <Button
                fullWidth
                variant="contained"
                sx={{ textTransform: "none", marginTop: "10px" }}
                onClick={handleVerification}
              >
                Verify
              </Button>
            ) : null}
          </div>
        </Grid>
      </HeaderContainer>
    </div>
  );
};

export default VerifyCertificate;
