import React, { useEffect, useRef, useState } from "react";
import { useLocation, useOutletContext } from "react-router-dom";
import swal from "sweetalert";
import { CircularProgress, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import ButtonMain from "../../../ButtonMain";
import "./FileView.scss";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import { POST } from "../../../../config/Api";
import { Constants, userRoles } from "../../../../config/Constants";
import { useSelector } from "react-redux";
import { useQueryParams } from "../../../../utils";
import BlockActions from "../BlockActions/BlockActions";
import { cloudfrontUrl } from "../../../../config/BaseUrl";
import { ToastNotify } from "../../../SnackBar/ToastNotify";
import ArtView from "../ArtView/ArtView";
import { CustomInputFile } from "../../../InputBox/InputBox";
import LearnerProperties from "../../LearnerProperties";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "right",
  justifyContent: "right",
  color: theme.palette.common.white,
}));

const FileView = ({
  file,
  data,
  courseDetails,
  block,
  courseDetail,
  courseOne,
  setmodalOpen,
  setblockModOpen,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [hidden, setHidden] = useState(block?.is_hidden);
  let { isLoggedIn, user } = useSelector((state) => state.userLogin?.data) || {};
  let isModerator =
    courseDetails?.creator === user?.id ||
    courseDetails?.members?.findIndex((item) => item?.user === user?.id);

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  return (
    <>
      {(isModerator === -1 || isModerator === undefined) && block?.is_hidden ? (
        ""
      ) : (
        <div>
          <div
            className={` file-block block-table 
          ${
            isLoggedIn
              ? `${(data || block)?.theme} postBgColor`
              : `${(data || block)?.theme} defaultStyle`
          }
        `}
            style={{
              background: `${
                (data || block)?.theme_type === "color" ? (data || block)?.color : ""
              }`,
            }}
            onMouseOver={handleHover}
            onMouseLeave={handleMouseLeave}
          >
            {isModerator !== -1 && isLoggedIn && isHovering && (
              <BlockActions
                blockId={file?._id}
                block={data || block}
                setmodalOpen={setmodalOpen}
                setblockModOpen={setblockModOpen}
                hidden={hidden}
                setHidden={setHidden}
              />
            )}
            {isModerator !== -1 && block?.carousel_container && (
              <Image>
                <Tooltip title="This Block Tagged to Carousel Block">
                  <LocalOfferRoundedIcon fontSize="large" color="primary" />
                </Tooltip>
              </Image>
            )}
            <ArtView art={(block || data)?.art} />
            <div className="container-block block-details">
              <p className="block-title">{block?.title}</p>
              <Box>
                <a
                  // href={`${cloudfrontUrl}${block?.provider?.url}`}
                  href={
                    block?.provider?.url?.includes(cloudfrontUrl)
                      ? block?.provider?.url
                      : `${cloudfrontUrl}${block?.provider?.url}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box height={"150px"} position={"relative"}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1}
                      stroke="currentColor"
                      className="w-6 h-6"
                      style={{ height: "150px" }}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                      />
                    </svg>
                    <Typography
                      variant="h5"
                      fontWeight={600}
                      position={"absolute"}
                      top={"50px"}
                      left={"45px"}
                    >
                      {block?.provider?.url
                        ?.match(/\.[0-9a-z]+$/i)[0]
                        ?.toUpperCase()
                        .split(".")}
                    </Typography>
                  </Box>
                  <ButtonMain style={{ marginLeft: "25px" }}>View File</ButtonMain>
                </a>
              </Box>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const UploadFile = ({ block, blockUserActivities, disableBlock }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [file, setFile] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleBlockProperties = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const { cid } = useQueryParams();
  // const { setAuthStates } = useContext(AuthContext);

  let { isLoggedIn, user } = useSelector((state) => state.userLogin?.data) || {};

  let toastRef = useRef();

  let location = useLocation();
  const { userDetails } = useOutletContext();

  useEffect(() => {
    if (location.pathname.startsWith("/course/createblock")) {
      setDisabled(true);
    }
  }, [location]);

  useEffect(() => {
    const file = blockUserActivities?.data?.find((el) => el.block_id === block._id);
    setFile(file);
  }, [blockUserActivities, block]);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const allowedExtensions = ["docx", "doc", "pdf"];
    const extension = file.name.split(".").pop().toLowerCase();
    if (allowedExtensions.includes(extension)) {
      setSelectedFile(file);
    } else {
      toastRef.current.showToastMessage("Please select a valid file (doc,docx, and pdf)", "error");
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isLoggedIn) {
      if (selectedFile === null) {
        toastRef.current.showToastMessage("Please select the file.", "error");
      } else {
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("user_id", user?.id);
        formData.append("course_id", cid);
        setDisabled(true);
        setLoading(true);
        let header = "multipart/form-data";
        try {
          //post api to upload file
          const res = await POST(
            `${Constants.getPublicCourseBlock}/upload/file?block_id=${block._id}`,
            formData,
            header,
          );
          setFile({
            attachments: [{ url: `${res?.data?.url}` }],
          });
          toastRef.current.showToastMessage("File Uploaded Successfully.", "success");
          setDisabled(false);
          setLoading(false);
          //save user last activity
          await POST(
            `${Constants.userActivity}?activity=${
              file ? "blockActivityUpdate" : "blockActivitySave"
            }`,
            {
              user: user,
            },
          );
        } catch (error) {
          swal("Failed", "Something went wrong.", "error");
        }
      }
    } else {
      window.location.replace(
        `${process.env.REACT_APP_AUTH_URL_FE}?referrer=${window.location.href}`,
      );
      // setAuthStates((prev) => ({ ...prev, loginOpen: true, modalOpen: true }));
    }
  };
  return (
    <>
      {!file ? (
        <form onSubmit={handleSubmit}>
          <div className="file-save-button">
            <CustomInputFile
              title={selectedFile ? selectedFile.name.substring(0, 20) : "Choose File to upload"}
              icon={!selectedFile && true}
              className="fileUpload fileview"
              onChange={handleFileChange}
              accept=".pdf,.docx,.doc"
            />
            <ButtonMain
              type="submit"
              disabled={disabled || disableBlock || !isLoggedIn}
              style={{ padding: `${loading ? "5px 25px" : ""}` }}
            >
              {loading ? <CircularProgress size={17} /> : "Save"}
            </ButtonMain>
            {userDetails.role === userRoles.REVIEWER && (
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleBlockProperties}
                sx={{ float: "right" }}
              >
                <MoreVertIcon />
              </IconButton>
            )}
          </div>
          <ToastNotify ref={toastRef} duration={3000} />
        </form>
      ) : (
        <>
          {file?.attachments?.map((el) => (
            <Box>
              <a
                // href={`${cloudfrontUrl}${el.url}`}
                href={el.url?.includes(cloudfrontUrl) ? el.url : `${cloudfrontUrl}${el.url}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Box height={"150px"} position={"relative"}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1}
                    stroke="currentColor"
                    className="w-6 h-6"
                    style={{ height: "150px" }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                    />
                  </svg>
                  <Typography
                    variant="h5"
                    fontWeight={600}
                    position={"absolute"}
                    top={"50px"}
                    left={"45px"}
                  >
                    {el?.url
                      ?.match(/\.[0-9a-z]+$/i)[0]
                      ?.toUpperCase()
                      .split(".")}
                  </Typography>
                </Box>
                <ButtonMain style={{ marginLeft: "25px" }}>View File</ButtonMain>
              </a>
              <ButtonMain
                style={{ marginLeft: "25px" }}
                disabled={!isLoggedIn}
                onClick={() => {
                  setFile(false);
                  setSelectedFile(null);
                }}
              >
                Change File
              </ButtonMain>
              {userDetails.role === userRoles.REVIEWER && (
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleBlockProperties}
                  sx={{ float: "right" }}
                >
                  <MoreVertIcon />
                </IconButton>
              )}
            </Box>
          ))}
        </>
      )}
      {userDetails.role === userRoles.REVIEWER && (
        <LearnerProperties
          anchorEl={anchorEl}
          open={open}
          handleClose={() => setAnchorEl(null)}
          obtainedMark={blockUserActivities?.credit}
          attempts={blockUserActivities?.NoOfAttempts}
          maxMark={block?.score || 0}
          blockType={block?.type}
          scoreType={block?.typeOfBlock}
        />
      )}
    </>
  );
};

export { FileView, UploadFile };
