import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import BlockActions from "../BlockActions/BlockActions";
import "./ListView.scss";
import { cloudfrontUrl } from "../../../../config/BaseUrl";
import ArtView from "../ArtView/ArtView";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "right",
  justifyContent: "right",
  color: theme.palette.common.white,
}));

const ListView = ({
  list,
  courseDetails,
  courseDetail,
  courseOne,
  setmodalOpen,
  setblockModOpen,
  block,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [hidden, setHidden] = useState(list?.is_hidden);
  let { isLoggedIn, user } = useSelector((state) => state.userLogin?.data) || {};
  let isModerator =
    courseDetails?.creator === user?.id ||
    courseDetails?.members?.findIndex((item) => item?.user === user?.id);

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  return (
    <>
      {(isModerator === -1 || isModerator === undefined) && list?.is_hidden ? (
        ""
      ) : (
        <div className="list-view">
          <div
            className={` container-block list-block 
        ${isLoggedIn ? `${list?.theme} postBgColor` : `${list?.theme} defaultStyle`}
      `}
            style={{
              background: `${list?.theme_type === "color" ? list?.color : ""}`,
            }}
            onMouseOver={handleHover}
            onMouseLeave={handleMouseLeave}
          >
            {isModerator !== -1 && isLoggedIn && isHovering && (
              <BlockActions
                blockId={list?._id}
                block={list}
                setmodalOpen={setmodalOpen}
                setblockModOpen={setblockModOpen}
                hidden={hidden}
                setHidden={setHidden}
              />
            )}

            {isModerator !== -1 && block?.carousel_container && (
              <Image>
                <Tooltip title="This Block Tagged to Carousel Block">
                  <LocalOfferRoundedIcon fontSize="large" color="primary" />
                </Tooltip>
              </Image>
            )}
            <ArtView art={(block || list)?.art} />
            <p className="block-title">{list.title}</p>
            {list.text && (
              <div className="block-text" dangerouslySetInnerHTML={{ __html: list.text }}></div>
            )}
            {list?.items?.length !== 0 && (
              <div className="list-items">
                {list?.items?.map((i, index) => (
                  <div className="one-list-item" key={index}>
                    {i?.is_right === true ? (
                      <div className="one-list-content is-right">
                        {i?.image && (
                          <img
                            src={
                              i?.image?.m?.startsWith(cloudfrontUrl)
                                ? i?.image?.m
                                : cloudfrontUrl + i?.image?.m
                            }
                            alt=""
                            className="art-image-right"
                          />
                        )}
                        {i?.text ? (
                          <div
                            className="list-text"
                            dangerouslySetInnerHTML={{ __html: i?.text }}
                          ></div>
                        ) : (
                          <input
                            placeholder="..."
                            type="text"
                            autoComplete="off"
                            className="list-input entity-title"
                          />
                        )}
                      </div>
                    ) : (
                      <div className="one-list-content">
                        {i?.image && (
                          <img
                            src={
                              i?.image?.m?.startsWith(cloudfrontUrl)
                                ? i?.image?.m
                                : cloudfrontUrl + i?.image?.m
                            }
                            alt=""
                            className="art-image-left"
                          />
                        )}
                        {i.text ? (
                          <div
                            className="list-text"
                            dangerouslySetInnerHTML={{ __html: i.text }}
                          ></div>
                        ) : (
                          <input
                            placeholder="..."
                            type="text"
                            autocomplete="off"
                            className="list-input entity-title"
                          />
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ListView;
