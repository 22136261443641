import React, { useState } from "react";
import { useSelector } from "react-redux";
import { POST } from "../../../../../config/Api";
import { Constants, creditPoints, scoreLists } from "../../../../../config/Constants";
import { useQueryParams } from "../../../../../utils";
import ButtonMain from "../../../../ButtonMain";
import { CheckboxInput, CustomInputFile, Dropdown, InputBox } from "../../../../InputBox/InputBox";
import CustomizedSnackbars from "../../../../SnackBar/CustomizedSnackbars";
import { cloudfrontUrl } from "../../../../../config/BaseUrl";
import CustomTextArea from "../../../../TextArea/CustomTextArea";
import { Box, Divider, FormControl, Grid, MenuItem } from "@mui/material";
// import "./AddScormBlock.scss";
import CustomAccordion from "../../../../Accrodion/Accordion";
import SourceTextSwitcher from "../../../../SourceTextSwitcher/SourceTextSwitcher";
import { useOutletContext } from "react-router-dom";

const AddScormBlock = ({
  assessmentSettings,
  type,
  block,
  updateOrder,
  setblockModOpen,
  setmodalOpen,
  setCourseBlocks,
  blockModalOpen,
}) => {
  const [assessment, setAssessment] = useState("");
  const [urlPath, seturlPath] = useState(block?.image || null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [formData, setFormData] = useState({
    title: block?.title || "",
    url: block?.scorm?.url || "",
    width: block?.scorm?.width || null,
    height: block?.scorm?.height || null,
    score: block?.typeOfBlock || "attempt",
    credit: block?.score || 5,
    is_required: block?.is_required || false,
  });

  const [errText, setErrText] = useState("");
  const [open, setOpen] = useState(false);
  const [disable, setDisable] = useState(false);
  const [checked, setChecked] = useState(false);
  const { setCourseOne } = useOutletContext();

  let UserData = useSelector((state) => state.userLogin);

  const { cid, mid } = useQueryParams();

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleFileChange = async (event) => {
    setSelectedFile(event.target.files[0]);
    const formData = new FormData();
    formData.append("image", event.target.files[0]);
    let header = "multipart/form-data";
    try {
      setDisable(true);
      //upload image
      const response = await POST(`${Constants.addBlock}/uploadImage`, formData, header);
      seturlPath(response?.data?.image);
      setDisable(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = async () => {
    if (formData.url === "") {
      setOpen(true);
      setErrText("Please enter valid url of launch file of your scorm package.");
    } else if (formData.title === "") {
      setOpen(true);
      setErrText("Please enter valid title");
    } else if (formData.text === "") {
      setOpen(true);
      setErrText("Please enter valid description.");
    } else {
      setDisable(true);
      let body = {
        user_id: UserData?.data?.user?.id,
        course: cid,
        container: mid,
        url: formData.url,
        title: formData.title,
        text: formData.description,
        order: blockModalOpen?.addBelow ? blockModalOpen?.currentBlockOrder + 1 : updateOrder || 1,
        type: type,
        typeOfBlock: formData.score,
        score: formData.credit,
        is_required: formData.is_required,
        assessmentType: assessment,
        image: urlPath,
      };
      //create scorm block
      POST(`${Constants.addBlock}/scorm?_id=${block?._id}`, body)
        .then((response) => {
          setDisable(false);
          setCourseOne((prev) => ({ ...prev, published: false }));
          if (type === "edit") {
            setCourseBlocks &&
              setCourseBlocks((prev) => {
                const elIndex = prev.findIndex((el) => el._id === block?._id);
                const updatedBlocks = prev.map((el) => {
                  if (el.order >= response?.data?.order) {
                    el.order += 1;
                  }
                  return el;
                });
                updatedBlocks.splice(elIndex, 1, response?.data);
                updatedBlocks.sort((a, b) => a?.order - b?.order);
                return updatedBlocks;
              });
          } else {
            if (blockModalOpen?.addBelow) {
              setCourseBlocks &&
                setCourseBlocks((prev) => {
                  const updatedBlocks = prev.map((el) => {
                    if (el.order >= response?.data?.order) {
                      el.order += 1;
                    }
                    return el;
                  });
                  const elIndex = updatedBlocks.findIndex(
                    (el) => el._id === blockModalOpen?.currentBlockId,
                  );
                  updatedBlocks.splice(elIndex + 1, 0, response?.data);
                  return updatedBlocks;
                });
            } else {
              setCourseBlocks &&
                setCourseBlocks((prev) => {
                  prev.forEach((el) => {
                    el.order = el.order + 1;
                  });
                  return [response?.data, ...prev];
                });
            }
          }
          setmodalOpen && setmodalOpen((prev) => ({ ...prev, modalOpen: false }));
          setblockModOpen(false);
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <div>
      <SourceTextSwitcher
        checked={checked}
        handleChange={(event) => setChecked(event.target.checked)}
        srcText={block?.srcText}
        srcTitleText={block?.srcTitleText}
      />
      <FormControl
        disabled={!assessmentSettings?.isAdvanceAssessment}
        sx={{ paddingBottom: "10px", width: "100%" }}
        variant="standard"
      >
        <Dropdown
          onChange={(event) => setAssessment(event.target.value)}
          value={assessment}
          renderValue={(selected) => {
            if (selected?.length === 0) {
              return <span className="dropdownLabel">Select a Domain</span>;
            } else {
              let selectedDomain = assessmentSettings?.assessmentDomain?.find(
                (domain) => domain?._id === selected,
              );
              return <span>{selectedDomain?.title}</span>;
            }
          }}
        >
          {assessmentSettings?.assessmentDomain?.length > 0 &&
            assessmentSettings?.assessmentDomain?.map((item, i) => {
              return (
                <MenuItem value={item?._id} key={i}>
                  {item?.title}
                </MenuItem>
              );
            })}
        </Dropdown>
      </FormControl>
      <Grid container spacing={3}>
        <Grid item md={3}>
          {!urlPath?.m ? (
            <CustomInputFile
              title={
                selectedFile
                  ? selectedFile.name
                  : "Choose cover image or drop them here (800px X 800px or a square image)"
              }
              icon={!selectedFile && true}
              className="uploadFile"
              type="file"
              onChange={handleFileChange}
            />
          ) : (
            <>
              <Box>
                <img
                  className="dp"
                  src={
                    urlPath?.m.startsWith(cloudfrontUrl)
                      ? `${urlPath?.m}`
                      : `${cloudfrontUrl}${urlPath?.m}`
                  }
                  alt=""
                />
              </Box>
              <ButtonMain
                className={"change_profile_photo"}
                onClick={() => {
                  seturlPath({});
                }}
              >
                Change Container Image
              </ButtonMain>
            </>
          )}
        </Grid>
        <Grid item md={9}>
          <InputBox
            placeholder="Title"
            name="title"
            value={formData.title}
            onChange={handleChange}
          />
          {checked && (
            <Box my={2}>
              <strong>Source Text:</strong> {block?.srcTitleText}
            </Box>
          )}
          <br />
          <CustomTextArea
            name="description"
            type="text"
            label="SCORM Description*"
            placeholder="SCORM Description*"
            value={formData.description}
            onChange={handleChange}
          />
          {checked && (
            <Box my={2}>
              <strong>Source Text:</strong> {block?.srcText}
            </Box>
          )}
          <br />
          <InputBox
            placeholder="Enter launch page url of your scorm package."
            name="url"
            value={formData.url}
            onChange={handleChange}
          />
          <br />
          <CustomAccordion
            first={
              <Dropdown
                onChange={(event) => setFormData({ ...formData, score: event.target.value })}
                value={formData.score}
              >
                {scoreLists?.map((item, index) => (
                  <MenuItem value={item} key={index}>
                    {item}
                  </MenuItem>
                ))}
              </Dropdown>
            }
            second={
              <Dropdown
                onChange={(event) => setFormData({ ...formData, credit: event.target.value })}
                value={formData.credit}
              >
                {creditPoints?.map((item, index) => (
                  <MenuItem value={item} key={index}>
                    {item}
                  </MenuItem>
                ))}
              </Dropdown>
            }
            third={
              <CheckboxInput
                label="Highlight if responded incorrectlyinfo"
                onChange={(event) => setFormData({ ...formData, checked: event.target.checked })}
                value={!formData.checked}
              />
            }
            fourth={
              <CheckboxInput
                label="Mark As Required"
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    is_required: event.target.checked,
                  })
                }
                value={!formData.is_required}
              />
            }
          />
          <Divider sx={{ borderColor: "black", margin: "10px 0px" }} />
          <br />
          <ButtonMain className="buttonGreen" disabled={disable} onClick={handleSave}>
            Save Block
          </ButtonMain>{" "}
          <br />
          <CustomizedSnackbars
            AlertText={errText}
            AlertType="error"
            open={open}
            onClose={() => setOpen(false)}
          />
        </Grid>
      </Grid>
      {/* <b>Embed size:</b> <br />
      <InputBox
        placeholder="Width (in pixels}"
        name="width"
        type="number"
        value={formData.width}
        onChange={handleChange}
      />
      <br /> */}
      {/* <InputBox
        placeholder="Height (in pixels}"
        name="height"
        type="number"
        value={formData.height}
        onChange={handleChange}
      />
      <br /> */}
    </div>
  );
};

export default AddScormBlock;
