import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Select, MenuItem, CircularProgress } from "@mui/material";
import { GET } from "../../config/Api";
import { Constants } from "../../config/Constants"; // Make sure this import path is correct
import FMModal from "../ModalMain/CourseModals/FMModal";

const CarosalConfirmModal = ({
  open,
  handleClose,
  handleConfirm,
  cid,
  headerText,
  carosalContainer,
  container,
}) => {
  const [carosalBlocks, setCarosalBlocks] = useState([]);
  const [selectedCarosal, setSelectedCarosal] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (open) {
      fetchCarosalBlocks();
    }
  }, [cid, open]);

  const fetchCarosalBlocks = async () => {
    try {
      setLoading(true);
      const response = await GET(`${Constants.getCarosalBlocksFromCourseID}/${cid}/${container}`); // Update the endpoint as needed
      setCarosalBlocks(response.data.blocks);
    } catch (error) {
      console.error("Failed to fetch carosal blocks", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    handleConfirm(selectedCarosal);
  };

  return (
    <FMModal
      modalOpen={open}
      modalClose={handleClose}
      modalHeader={carosalContainer ? "" : "Select Carousel Block"}
      className="confirm_modal"
      headerTextColor={"header_text_black"}
    >
      <Box>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {carosalContainer ? (
              <>
                <Typography variant="h6" sx={{ mt: 4 }}>
                  Are You Sure You Want to Remove This Block from the Block Carousel?
                </Typography>
                <Box display="flex" justifyContent="space-between" mt={4}>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Confirm
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <Select
                  value={selectedCarosal}
                  onChange={(e) => setSelectedCarosal(e.target.value)}
                  fullWidth
                  sx={{ my: 2 }}
                >
                  {carosalBlocks?.length > 0 ? (
                    carosalBlocks?.map((block) => (
                      <MenuItem key={block._id} value={block._id}>
                        {block.title}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>Nothing to Choose from</MenuItem>
                  )}
                </Select>
                <Box display="flex" justifyContent="space-between" mt={2}>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={!selectedCarosal}
                  >
                    Submit
                  </Button>
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </FMModal>
  );
};

export default CarosalConfirmModal;
