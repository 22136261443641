import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ButtonMain from "../../ButtonMain";
import FMModal from "../../ModalMain/CourseModals/FMModal";
import "./JoinCohort.scss";
import { Alert, AlertTitle, Paper, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { Box } from "@mui/system";
import { InputBox } from "../../InputBox/InputBox";
import { Constants } from "../../../config/Constants";
import { POST } from "../../../config/Api";
import { ToastNotify } from "../../SnackBar/ToastNotify";
import PaymentsModal from "../Payments/PaymentsModal";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const JoinCohort = ({
  modalOpen,
  modalClose,
  setshow,
  getCourseBlocks,
  getUserCourseActivity,
  modalTitle,
  switchCohortData,
  note,
  btnText,
  courseOne,
  onEnrolled,
  setCompletedModulesData,
}) => {
  const [joiningCode, setJoiningCode] = useState("");
  // const [showSD, setShowSD] = useState(true);
  const [disableFC, setDisableFC] = useState(false);
  const [disableSD, setDisableSD] = useState(false);
  const [paymentsData, setPaymentsData] = useState({
    modalOpen: false,
    type: "self-directed",
  });
  let navigate = useNavigate();
  let UserData = useSelector((state) => state.userLogin);

  let isLoggedIn = useSelector((state) => state.userLogin?.data?.isLoggedIn);
  let savedCourseDetail = useSelector((state) => state.savedCourseDetail.data);
  const toastRef = useRef();

  const validateFCCohort = async () => {
    if (joiningCode === "") {
      toastRef.current.showToastMessage("Please Enter Invite Code", "error");
    } else {
      const body = {
        joinCode: joiningCode,
        courseID: savedCourseDetail?._id || courseOne?._id,
      };
      try {
        //Validate Facilitated cohort
        const response = await POST(`${Constants.course}/cohort/validateFCCohort`, body);
        if (response?.data?.cohort?.isPaid) {
          setPaymentsData((prev) => ({
            ...prev,
            type: "facilitated",
            modalOpen: true,
            cohortID: response?.data?.cohort?._id,
          }));
        } else {
          handleFacilitated({});
        }
      } catch (error) {
        toastRef.current.showToastMessage(error?.response?.data?.message, "error");
      }
    }
  };

  const handleFacilitated = async ({ paymentID }) => {
    if (joiningCode === "") {
      toastRef.current.showToastMessage("Please Enter Invite Code", "error");
    } else {
      setDisableFC(true);
      try {
        let body = {
          user_id: UserData?.data?.user?.id,
          type: "facilitated",
          joinCode: joiningCode,
          payment_id: paymentID,
        };
        if (switchCohortData) {
          body.course_id = switchCohortData?.course_id;
        } else {
          body.course_id = courseOne?._id;
        }
        //enroll into cohort
        const response = await POST(`${Constants.enrollIntoCohort}`, body);
        setDisableFC(false);
        // let data = response?.data?.data;
        // toastRef.current.showToastMessage(response?.data?.message, "success");
        // let joiningLink = data?.joiningLink;
        // if (joiningLink) {
        //   if (joiningLink?.startsWith("/")) {
        //     if (!switchCohortData) {
        //       navigate(`${data?.joiningLink}?cid=${savedCourseDetail?._id}`);
        //     }
        //   } else {
        //     if (!switchCohortData) {
        //       navigate(
        //         `/${data?.joiningLink}/${savedCourseDetail?.slug}?cid=${savedCourseDetail?._id}&coid=${data?._id}`
        //       );
        //     }
        //   }

        //   setTimeout(() => {
        //     window.location.reload();
        //   }, 2000);
        //   modalClose(true);
        //   setshow(false);
        //   // modalClose(false);
        //   // sethideEnroll(false);
        // } else {
        //   toastRef.current.showToastMessage(response?.data?.message, "error");
        //   setDisableFC(false);
        // }
        if (response?.data?.success) {
          toastRef.current.showToastMessage(response?.data?.message, "success");
          //save user last activity
          await POST(
            `${Constants.userActivity}?activity=${
              switchCohortData ? "switchCohort" : "enrollment"
            }`,
            { user: UserData?.data?.user },
          );
          if (switchCohortData) {
            setTimeout(() => {
              navigate(
                `/course/${switchCohortData?.course_slug}?cid=${switchCohortData?.course_id}`,
              );
            }, 2000);
          } else {
            setshow && setshow(false);
            setCompletedModulesData && setCompletedModulesData(response?.data?.userCourseActivity);
            getCourseBlocks && getCourseBlocks();
            modalClose(true);
          }
        } else {
          toastRef.current.showToastMessage(response?.data?.message, "error");
        }
      } catch (error) {
        setDisableFC(false);
        toastRef.current.showToastMessage(error?.response?.data?.message, "error");
      }
    }
  };
  const handleSelfDirected = async ({ paymentID }) => {
    try {
      setDisableSD(true);
      let body = {
        user_id: UserData?.data?.user?.id,
        type: "self-directed",
        privacy: courseOne?.privacy,
        payment_id: paymentID,
      };
      if (switchCohortData) {
        body.course_id = switchCohortData?.course_id;
        // isPaid = switchCohortData?.isPaid
      } else {
        body.course_id = courseOne._id;
        // isPaid = savedCourseDetail?.isPaid;
      }
      //enroll into cohort
      POST(Constants.enrollIntoCohort, body)
        .then(async (res) => {
          toastRef.current.showToastMessage(res?.data?.message, "success");
          if (res?.data?.success) {
            // !switchCohortData && setshow(false);
            // !switchCohortData && getUserCourseActivity();
            switchCohortData &&
              setTimeout(() => {
                navigate(
                  `/course/${switchCohortData?.course_slug}?cid=${switchCohortData?.course_id}`,
                );
              }, 2000);
            setshow && setshow(false);
            setCompletedModulesData && setCompletedModulesData(res?.data?.data);
            getCourseBlocks && getCourseBlocks();
            setDisableSD(false);
            modalClose(true);
            //save user last activity
            await POST(
              `${Constants.userActivity}?activity=${
                switchCohortData ? "switchCohort" : "enrollment"
              }`,
              { user: UserData?.data?.user },
            );
          } else {
            setshow && setshow(false);
            setCompletedModulesData && setCompletedModulesData(res?.data?.data);
            // !switchCohortData && getUserCourseActivity();
            modalClose(true);
            setDisableSD(false);
          }
        })
        .catch((err) => {
          toastRef.current.showToastMessage(err?.response?.data?.message, "error");
          setDisableSD(false);
        });
      // await onEnrolled();
    } catch (error) {
      console.log(error);
    }
  };

  const closePaymentsModal = () => {
    setPaymentsData((prev) => ({
      ...prev,
      type: "self-directed",
      modalOpen: false,
    }));
  };

  return (
    <div>
      {isLoggedIn && (
        <FMModal
          modalOpen={modalOpen}
          modalClose={() => {
            modalClose(true);
            setJoiningCode("");
          }}
          modalHeader={modalTitle}
          className="joinCohortModal"
          backgroundImage={{
            backgroundImage: "linear-gradient(#a1c4fd, #c2e9fb)",
          }}
        >
          {note && (
            <Box mb={2}>
              <Alert severity="error">
                <AlertTitle>
                  <strong>Alert:</strong> {note}
                </AlertTitle>
              </Alert>
            </Box>
          )}
          <div className="join-cohort-content">
            {/* if course is private & allowed members count is '0' ,hide SD cohort popup */}
            {(courseOne?.cohortVisibility === "selfDirected" ||
              courseOne?.cohortVisibility === "both") && (
              <div className="cohort-card">
                <Item className="cohort-card-content">
                  <Typography variant="h6" align="left" sx={{ fontWeight: "bold" }}>
                    Self-Directed Cohort
                  </Typography>
                  <Box sx={{ textAlign: "left" }}>
                    Note: Please opt for self-directed learning only if you are not part of any
                    facilitated learning group.
                  </Box>

                  <ButtonMain
                    className="join-cohort-buttons sd-button"
                    onClick={
                      // () => {
                      //   console.log(!courseOne?.isPaid, courseOne);
                      // }
                      !courseOne?.isPaid
                        ? handleSelfDirected
                        : () => {
                            setPaymentsData((prev) => ({
                              ...prev,
                              type: "self-directed",
                              modalOpen: true,
                            }));
                          }
                    }
                    disabled={disableSD}
                  >
                    {btnText ? btnText : "Enroll"}
                  </ButtonMain>
                </Item>
              </div>
            )}

            {(courseOne?.cohortVisibility === "facilitated" ||
              courseOne?.cohortVisibility === "both") && (
              <div className="cohort-card">
                <Item className="cohort-card-content">
                  <Typography variant="h6" align="left" sx={{ fontWeight: "bold" }}>
                    Facilitated Cohort
                  </Typography>
                  <Box sx={{ textAlign: "left" }}>
                    Note: Please join a facilitated cohort using the code that your facilitator has
                    provided via email or other communication channel.
                  </Box>
                  <Box sx={{ textAlign: "left" }}>
                    <InputBox
                      className="join_cohort_input_box"
                      value={joiningCode}
                      onChange={(e) => setJoiningCode(e.target.value)}
                      placeholder="Enter Invite Code"
                      autofocus
                    />
                  </Box>
                  <ButtonMain
                    className="join-cohort-buttons"
                    onClick={
                      validateFCCohort
                      // !savedCourseDetail?.isPaid
                      //   ? handleFacilitated
                      //   : () => {
                      //       setPaymentsData((prev) => ({
                      //         ...prev,
                      //         type: "facilitated",
                      //         modalOpen: true,
                      //       }));
                      //     }
                    }
                    // onClick={handleFacilitated}
                    disabled={disableFC}
                  >
                    {btnText ? btnText : "Enroll"}
                  </ButtonMain>
                </Item>
              </div>
            )}
          </div>
        </FMModal>
      )}
      <ToastNotify ref={toastRef} duration={4000} />
      <PaymentsModal
        handleSelfDirected={handleSelfDirected}
        handleFacilitated={handleFacilitated}
        paymentsData={paymentsData}
        closePaymentsModal={closePaymentsModal}
        userID={UserData?.data?.user?.id}
        courseID={switchCohortData?.course_id || savedCourseDetail?._id}
      />
    </div>
  );
};

export default JoinCohort;
